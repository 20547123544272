<div class="pickup col d-flex flex-column align-items-center justify-content-center">
    <div class="pickup-item col-md-11">
        <label class="pickup-item__label col-md-2">
            {{ 'general.pickUpPoints.labels.pickup-point-id' | translate }}
        </label>
        <label class="pickup-item__label col-md-4">
            {{ 'general.pickUpPoints.labels.name' | translate }}
        </label>
        <label class="pickup-item__label col-md-3">
            {{ 'general.pickUpPoints.labels.address' | translate }}
        </label>
    </div>

    <ng-container *ngIf="pickupPoints && pickupPoints.length">
        <div class="pickup-info d-flex align-items-center" *ngFor="let pP of pickupPoints">
            <p class="pickup-info__txt col-md-2">
                {{ pP.code | truncate: [14] }}
            </p>
            <p class="pickup-info__txt col-md-4">
                {{ pP.name | truncate: [65] }}
            </p>
            <p class="pickup-info__txt col-md-3">
                {{ pP.address.city.value | truncate: [20] }}
            </p>

            <div class="col-3 col-arrow-button d-flex align-items-start justify-content-end">
                <a class="btn btn-outline-info d-flex align-items-center" [routerLink]="['pickup-points/add/', pP.id]">
                    <i class="fas fa-edit mr-1"></i>
                    <span>
                        {{'admin.buttons.edit' | translate}}
                    </span>
                </a>
                <button class="btn btn-outline-info" (click)="deactivateSp(pP)">
                    <i *ngIf="pP.isDeleted" class="fas fa-lock mr-2"></i>
                    <i *ngIf="!pP.isDeleted" class="fas fa-lock-open mr-2"></i>
                    <span *ngIf="!pP.isDeleted">{{'admin.buttons.disable' | translate | truncate: [8] }}</span>
                    <span *ngIf="pP.isDeleted">{{'admin.buttons.enable' | translate | truncate: [8] }}</span>
                </button>
            </div>

        </div>
    </ng-container>

    <div *ngIf="(!pickupPoints || pickupPoints?.length == 0) && !loading">
        <div class="row mt-3">
            <p class="text-muted">{{ 'admin.sp.noContentText' | translate }}</p>
        </div>
    </div>

    <div class="pickup-add row-buttons d-flex align-self-end">
        <a class="btn btn-circle" type="button" routerLink="pickup-points/add">
            <i class="bi bi-plus-circle"></i>
        </a>
    </div>

    <app-pagination *ngIf="paged?.totalCount! > 3" [paged]="paged" [(inputQuery)]="query" (pageClicked)="getPickUpPointData()">
    </app-pagination>
</div>
