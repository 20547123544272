import {Component, OnInit, ViewChild} from '@angular/core';
import {ServiceService} from '../service.service';
import {PdfJsViewerComponent} from 'ng2-pdfjs-viewer';
import * as FileSaver from 'file-saver';

export const buildFileName = (prefix: string, extension: string): string => {
   const currentDatetime = new Date();
   const formattedDate =
      currentDatetime.getFullYear().toString() +
      '_' +
      (currentDatetime.getMonth() + 1).toString() +
      '_' +
      currentDatetime.getDate().toString() +
      '_' +
      currentDatetime.getHours().toString() +
      '_' +
      currentDatetime.getMinutes().toString() +
      '_' +
      currentDatetime.getSeconds().toString();
   return prefix + '_' + formattedDate + '.' + extension;
};

@Component({
   selector: 'app-print',
   templateUrl: './print.component.html',
   styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
   private isExport: boolean;

   constructor(public service: ServiceService) {}
   @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad!: PdfJsViewerComponent;

   arr = ['0210603184164574'];

   ngOnInit(): void {
      // this.getPdfUrl();
   }
   // getPdfUrl(): void {
   //    this.service.getPdfForPrint(this.arr).subscribe(
   //       (res) => {
   //
   //          this.pdfViewerAutoLoad.pdfSrc = res;
   //          this.pdfViewerAutoLoad.refresh();
   //       },
   //       (err) => {
   //          this.pdfViewerAutoLoad.pdfSrc = err.error.text;
   //          this.pdfViewerAutoLoad.refresh();
   //
   //       }
   //    );
   // }

   exportData(): void {
      this.isExport = true;
      this.service.getPdfForPrint(this.arr).subscribe(
         (file) => {
            this.isExport = false;
            FileSaver.saveAs(file, buildFileName(this.arr[0], 'pdf'));
         },
         () => {
            this.isExport = false;
         }
      );
   }
}
