import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressModel } from '../../models/address.model';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})
export class AddressBlockComponent implements OnInit {
  @Input() address: AddressModel;
  constructor(private translateService: TranslateService) { }
  translatePath = "public.address-block.";
  ngOnInit(): void {
  }

}
