import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      case "ro": return "Română";
      case "ru": return "Русский";
      case "en": return "English";
      default: return value;
    }
  }

}
