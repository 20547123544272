<div class="pickup col d-flex flex-column align-items-center justify-content-center">
  <div class="pickup-item col-md-11">

    <label class="pickup-item__label col-md-3">
      {{ 'general.pickUpPoints.labels.pickup-point-id' | translate }}
    </label>

    <label class="pickup-item__label col-md-4">
      {{ 'general.pickUpPoints.labels.name' | translate }}
    </label>

  </div>

  <ng-container *ngIf="products && products.length">
    <div  class="pickup-info d-flex align-items-center"   *ngFor="let pP of products">

      <p class="pickup-info__txt col-md-3">
        {{ pP.code | truncate: [20] }}
      </p>


      <p class="pickup-info__txt col-md-5">
        {{ pP.name | truncate: [100] }}
      </p>

      <div class="col-md-4 col-arrow-button d-flex align-items-start justify-content-end">
        <a class="btn btn-outline-info d-flex align-items-center" [routerLink]="['product/add/', pP.id]">
          <i class="fas fa-edit mr-1"></i>
          <span>
                      {{'admin.buttons.edit' | translate}}
          </span>
        </a>
        <button class="btn btn-outline-info" (click)="deactivateSp(pP)">
          <i *ngIf="pP.isDeleted" class="fas fa-lock mr-2"></i>
          <i *ngIf="!pP.isDeleted" class="fas fa-lock-open mr-2"></i>
          <span *ngIf="pP.isDeleted">{{'admin.buttons.enable' | translate | truncate: [8]}}</span>
          <span *ngIf="!pP.isDeleted">{{'admin.buttons.disable' | translate | truncate: [8]}}</span>
        </button>
      </div>

    </div>
  </ng-container>

  <div *ngIf="(!products || products?.length == 0) && !loading">
    <div class="row mt-3">
      <p class="text-muted">{{ 'admin.sp.noContentText' | translate }}</p>
    </div>
  </div>


  <div class="pickup-add row-buttons d-flex align-self-end">
    <a class="btn btn-circle" type="button" routerLink="product/add">
      <i class="bi bi-plus-circle"></i>
    </a>
  </div>


  <app-pagination *ngIf="paged?.totalCount! > 3" [paged]="paged" [(inputQuery)]="query" (pageClicked)="getPickUpPointData()">
  </app-pagination>
</div>






































<!--<div class="content-holder bg-white mb-4">-->
<!--  <i *ngIf="loading" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>-->
<!--  <div *ngIf="products && products.length">-->
<!--    <div class="data-row" *ngFor="let prod of products">-->
<!--      <div class="row no-gutters">-->
<!--        <div class="col-md-4 form-group">-->
<!--          <label>-->
<!--            {{'general.products.labels.productCode' | translate }}-->
<!--          </label>-->
<!--          <div class="label-value font-weight-bold">-->
<!--            {{prod.code | truncate:[14] }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-4 form-group">-->
<!--          <label>-->
<!--            {{'general.products.labels.name' | translate }}-->
<!--          </label>-->
<!--          <div class="label-value font-weight-bold">-->
<!--            {{prod.name | truncate:[20]}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col col-arrow-button d-flex align-items-center justify-content-end">-->
<!--          <a class="btn btn-light" [routerLink]="['product/add/', prod.id]">-->
<!--            <i class="fas fa-edit text-muted"></i>-->
<!--          </a>-->
<!--          <button class="btn btn-light mw-100" (click)="deactivateSp(prod)">-->
<!--            <i *ngIf="prod.isDeleted" class="fas fa-lock text-muted"></i>-->
<!--            <i *ngIf="!prod.isDeleted" class="fas fa-lock-open text-muted"></i>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="(!products || products?.length == 0) && !loading">-->
<!--    <div class="row mt-3">-->
<!--      <p class="text-muted">{{'admin.sp.noContentText' | translate }}</p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <app-pagination *ngIf="paged" [paged]="paged" [(inputQuery)]="query" (pageClicked)="getPickUpPointData()">-->
<!--  </app-pagination>-->
<!--  <div class="row-buttons d-flex justify-content-end py-2 px-0">-->
<!--    <a class="btn btn-circle btn-primary" type="button" routerLink="product/add">-->
<!--      <i class="fas fa-plus"></i>-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->
