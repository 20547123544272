import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import { ReportStatus } from 'src/app/home/admin/reports/report.model';

@Pipe({
  name: 'reportStatus',
})
export class ReportStatusPipe implements PipeTransform {

  constructor(private translateService:TranslateService) {
  }

  public transform(value: number): any {
    
    return ReportStatus[value];
  }

}
