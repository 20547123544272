import { Component, OnInit, TemplateRef } from '@angular/core';
import { FeedbackType } from '../../enums';
import { ToastService } from '../../services/toaster.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  host: { '[class.ngb-toasts]': 'true' }

})
export class ToasterComponent implements OnInit {
  feedbackTypeEnum = FeedbackType;
  constructor(
    public toastService: ToastService

  ) { }

  ngOnInit(): void {
  }
  isTemplate(toast: any) { return toast.textOrTpl instanceof TemplateRef; }
}
