import { Component, OnInit, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './shared/services';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public appConfigService: AppConfigService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.translateService.langs = this.appConfigService.appConfig.langs;
    this.translateService.setDefaultLang(this.appConfigService.appConfig.defaultLang);
  }
}
