import {Component, OnInit} from '@angular/core';
import {ShippingService} from '../../../public-module/shipping/shipping.service';

@Component({
  selector: 'app-pop-up-alert',
  templateUrl: './pop-up-alert.component.html',
  styleUrls: ['./pop-up-alert.component.scss']
})

export class PopUpAlertComponent implements OnInit{

  scrolled = false;
  addressStatus = false;
  contactStatus = false;

  constructor(private shippingService: ShippingService) {
    this.scrollListner();
  }

  ngOnInit(): void {
    this.handleFormStatus();
  }

  scrollListner(): void {
    window.addEventListener('scroll', this.scroll, true);
  }

  scroll = (event: any): void => {
    const scrollPx = event.srcElement.scrollingElement.scrollTop;
    scrollPx >= 150 ? this.scrolled = true : this.scrolled = false;
  }


  handleFormStatus(): void {

    this.shippingService.addressFormStatus.subscribe((status) => {
      this.addressStatus = status;
      this.setSummaryHeight();
    });

    this.shippingService.contactFormStatus.subscribe((status) => {
      this.contactStatus = status;
      this.setSummaryHeight();
    });

  }

  setSummaryHeight(): void {
    if (this.addressStatus && this.scrolled || this.contactStatus && this.scrolled) {
      this.shippingService.setSummaryHeight(false);
    } else if (this.addressStatus === true && !this.scrolled || this.contactStatus === true && !this.scrolled) {
      this.shippingService.setSummaryHeight(true);
    } else {
      this.shippingService.setSummaryHeight(false);
    }
  }

}
