import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-general-view-data',
  templateUrl: './general-view-data.component.html',
  styleUrls: ['./../../../home/service-provider/view-details/view-details.component.scss']
})
export class GeneralViewDataComponent {
  @Input() name: string | undefined;
  @Input() data: string | undefined | null;
}
