import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-list',
  templateUrl: 'navigation-list.component.html',
  styles: [`
    .btn-sm {
      text-transform: capitalize ;
      width: 80px;
      height: 30px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #404040;
    }

    .btn-sm:hover {
      background: none;
    }

    .btn-sm:active {
      background: none !important;
      color: #404040 !important;
      outline: none;
      border: 1px solid rgba(29, 104, 139, 1) !important;
    }

    i {
        font-weight: bold;
    }

    .item::before {
      display: block;
      width: 14px;
      font-size: 19px;
      height: 19px;
      content: '|';
      margin-bottom: 8px;
      margin-right: 9px;
      margin-left: 3px;
      color: #CED1D6;
      opacity: 0.78;
    }

    .item > a {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #3A7991 !important;
    }

    .btn-outline-info {
      border: 1px solid rgba(29, 104, 139, 1);

    }

    @media only screen and (max-width: 486px) {
      .btn-outline-info {
        width: 68px;
        font-size: 11px;
        padding-bottom: 3px !important;
      }
    }



    @media only screen and (max-width: 486px) {
      .bi-arrow-left {
            display: none;
      }
    }

     a {
       text-transform: none !important;
     }

    @media only screen and (max-width: 586px) {
      .item > a {
            font-size: 14px !important;

      }

      @media only screen and (max-width: 486px) {
        .item > a {
          font-size: 11px !important;

        }
    }


      @media only screen and (max-width: 486px) {
        .breadcrumb {
          padding: 0;
          width: 100%;
        }
      }

  `]

})
export class NavigationListComponent {
  @Input() navigationList: { url: string, translatedText: string, link?: string }[];
}
