import { ProductEnum } from "../enums";
import { AddressModel } from "./address.model"
import { Parcel } from "./parcel.model";

export class CarrierOptionsRequest {
  deliveryAddressDirection: any;
  priority: any;
  type: ProductEnum;
  date: Date;
  recipient: any;
  serviceProviderIdno: any;
  sender: any;
  recipientAddress: AddressModel;
  senderAddress: AddressModel;
  weight: any;
  items: Parcel[];
  isLocal: boolean;
  declaredAmount: any;
  productType: any;
}
