<h6 class="col-12">
  <span class="col-12">{{translatePath + 'contact-info'| translate }} </span>
</h6>

<div class="col-12">
  <div class="col-12 pb-1">
    <hr>
  </div>
</div>

<div [formGroup]="contactForm" class="d-flex flex-column flex-sm-row col-12 align-items-center justify-content-center">
  <!-- Email -->
  <div class="col-12 col-sm-6 form-group d-flex flex-column col-md-6" style="margin-top: 0.6rem;">
    <label>{{translatePath + 'labels.email'| translate }}</label>
    <input [readOnly]="true" [ngClass]="{'is-invalid': contactForm.get('email')?.invalid && contactForm.get('email')?.touched,
            'is-valid':contactForm.get('email')?.valid}" class="form-control" formControlName="email" id="email"
           placeholder="{{translatePath+'placeholders.enter-email'| translate }}" type="text">
    <div *ngIf="contactForm.get('email')?.hasError('email')" class="invalid-feedback">
      {{'validation-errors.invalid-email' |  translate}}
    </div>

    <div *ngIf="contactForm.get('email')?.hasError('validationErrors')" class="invalid-feedback">
      <div *ngFor="let item of contactForm.get('email')?.getError('validationErrors')">
        {{'validation-errors.' + item | translate}}
      </div>
    </div>
  </div>
  <!-- Email End -->
  <!-- Phone -->

  <div class="col-12 col-sm-6 form-group d-flex flex-column col-md-6">
    <label>{{translatePath + 'labels.phone'| translate }}</label>
    <div class="input-group phone">
      <ngx-intl-tel-input [cssClass]="cssPhoneValidator()" [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="false"
                          [maxLength]="'15'"
                          [numberFormat]="PhoneNumberFormat.National"
                          [phoneValidation]="true"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                          [searchCountryFlag]="true"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.Moldova"
                          [separateDialCode]="separateDialCode"
                          class="valid-phone"
                          formControlName="phone" name="phone" style="width: 100%;">
      </ngx-intl-tel-input>
    </div>
    <!-- Phone end -->
  </div>
</div>

<div class="contact-info mr-auto ml-auto">
  <h6 class="col-12">
    <span class="col-12">{{translatePath + 'shipping-address'| translate }}</span>
  </h6>
  <div class="col-12">
    <div class="col-12 pt-1 pb-2">
      <hr>
    </div>
  </div>
</div>
