import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ServiceModel } from '../models/service.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { Pagination } from '../models';
import { IServices } from './iterfaces/services.interface';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  backendUrl: string;
  paginatorGenerator = new PaginationGeneratorService();

  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient
  ) {
    this.backendUrl = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/adm`;
  }

  getServicesBySp(query: Pagination, spId: string): Observable<{ items: ServiceModel[], paged: any }> {
    const params = this.paginatorGenerator.generatePagination(query);
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/${spId}/service`, { params });
  }

  getServiceById(servId: string, spId: string): Observable<ServiceModel> {
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/${spId}/service/${servId}`);
  }

  createOrUpdateService(data: any, spId: string): Observable<ServiceModel> {
   
    if (!data.id) {
      return this.createService(data, spId);
    }
    return this.updateService(data.mPassId, data, spId);
  }

  createService(data: any, spId: string): Observable<ServiceModel> {
    return this.http.post<any>(`${this.backendUrl}/serviceprovider/${spId}/service`, data);
  }

  updateService(servId: string, data: any, spId: string): Observable<ServiceModel> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/${spId}/service/${servId}`, data);
  }

  disableEnableService(spId: string, pp: ServiceModel): Observable<any> {
    if (!pp.isDeleted) {
      return this.disableService(spId, pp);
    } else {
      return this.activateService(spId, pp);
    }
  }


  disableService(spId: string, serv: ServiceModel): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/serviceprovider/${spId}/service/${serv.id}`);
  }

  activateService(spId: string, serv: ServiceModel): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/${spId}/service/${serv.id}/activate`, { id: serv.id });
  }


}
