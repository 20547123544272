import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PickupPointsModel } from '../../../models/pickupPoints.model';
import { PickupPointsService } from '../../../services/pickup-points.service';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator, specialApartmentCaracterValidator, specialBlockCaracterValidator, specialStreetCaracterValidator } from '../../../validators/email.validator';
import { FormErrorsChecker } from '../../../utils/formErrorsChecker';
import { finalize } from 'rxjs/operators';
import { AddressModel } from 'src/app/shared/models/address.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FeedbackService } from 'src/app/shared/services';
import { phoneValidator } from '../../../validators/phone.validator';

@Component({
  selector: 'app-create-update-pickup-point',
  templateUrl: './pickup-points-edit.component.html',
  styleUrls: ['./pickup-points-edit.component.scss']
})
export class PickupPointsEditComponent implements OnInit {
  navList: any;
  pPId: string;
  spId: string;
  inRequest = false;

  pickUpPointsFormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    address: new FormGroup({
      country: new FormControl('', [Validators.required]),
      region: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      street: new FormControl('', [specialStreetCaracterValidator]),
      block: new FormControl('', [Validators.required, specialBlockCaracterValidator]),
      apartment: new FormControl('', [specialApartmentCaracterValidator])
    }),
    contactPerson: new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, emailValidator]),
      phone: new FormControl('', [Validators.required, phoneValidator])
    }),
  });

  errorCheckerUtil = new FormErrorsChecker();

  currentPickupPoint: PickupPointsModel;
  loading: boolean;

  constructor(
    private pPService: PickupPointsService,
    private route: ActivatedRoute,
    private router: Router,
    private feedbackService: FeedbackService

  ) {
  }

  get address(): FormGroup {
    return this.pickUpPointsFormGroup.get('address') as FormGroup;
  }

  get contactPerson(): FormGroup {
    return this.pickUpPointsFormGroup.get('contactPerson') as FormGroup;
  }

  ngOnInit(): void {
    this.pPId = this.route.snapshot.params['pPId'];
    this.spId = this.route.snapshot.params['id'];
    if (this.pPId && this.spId) {
      this.loading = true;
      this.pPService.getPickupPointsById(this.pPId, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {
          this.currentPickupPoint = res;
          this.integrateData();
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
    }

    this.navList = [
      { url: '.', translatedText: 'general.pickUpPoints.edit', link: `/admin/sp/details/${this.spId}` },
    ];
  }

  integrateData(): void {
    this.pickUpPointsFormGroup.patchValue({
      name: this.currentPickupPoint.name,
      code: this.currentPickupPoint.code,
      address: {
        country: this.currentPickupPoint.address?.country,
        region: this.currentPickupPoint.address?.region,
        zipCode: this.currentPickupPoint.address?.zipCode,
        city: this.currentPickupPoint.address?.city,
        street: this.currentPickupPoint.address?.street,
        block: this.currentPickupPoint.address?.block,
        apartment: this.currentPickupPoint.address?.apartment,
      },
      contactPerson: {
        firstName: this.currentPickupPoint.contactPerson?.firstName,
        lastName: this.currentPickupPoint.contactPerson?.lastName,
        email: this.currentPickupPoint.contactPerson?.email,
        phone: this.currentPickupPoint.contactPerson?.phone,
      },
    });
    this.pickUpPointsFormGroup.markAllAsTouched();
    this.pickUpPointsFormGroup.updateValueAndValidity();
  }

  submit(): void {
    this.pickUpPointsFormGroup.markAllAsTouched();
    this.pickUpPointsFormGroup.updateValueAndValidity();
    if (!this.pickUpPointsFormGroup.invalid) {
      const spFinal = { ...this.currentPickupPoint, ...this.pickUpPointsFormGroup.value };
      this.inRequest = true;
      this.pPService.createOrUpdatePickUpPoint(spFinal, this.spId)
        .pipe(
          finalize(
            () => { this.inRequest = false; }
          )
        )
        .subscribe((res: any) => {
          this.feedbackService.handleSuccess('save-pickup-point-data-success');
          this.router.navigate(['/admin/sp/details', this.spId]);
        }, (err: HttpErrorResponse) => {
          if (err.error.validationError.includes("PICKUP POINT CODE NOT UNIQUE")) {
            this.feedbackService.handleInfo("pickUp-point-error")
          }
          else {
            this.feedbackService.handleError(err, this.pickUpPointsFormGroup);
          }
        });
    }
  }

  setAddressVal(data: AddressModel): void {
    this.pickUpPointsFormGroup.get('address')?.patchValue({
      country: data?.country,
      region: data?.region,
      zipCode: data?.zipCode,
      city: data?.city,
      street: data?.street,
      block: data?.block,
      apartment: data?.apartment,
    });
  }
}
