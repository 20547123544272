import { HttpClient } from '@angular/common/http';
import { AfterContentInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbDropdownConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProfilesEnum } from '../enums';
import { AuthModel, ContextModel } from '../models';
import { SelectContextModalComponent } from '../select-context-modal/select-context-modal.component';
import { AccountLinkGeneratorService } from '../services/account-link-generator.service';
import { UserService } from '../services/user.service';
import { AppConfigService } from './../../shared/services/app-config.service';
import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig],
})
export class HeaderComponent implements OnInit {
  public loginUrl!: string;
  public logoutUrl!: string;
  showBtnListService = false;
  showBtnListAdmin = false;
  baseUrl = this.appConfigService.appConfig.APP_BASE_URL;

  public isAuthenticated!: boolean;
  private authModel!: AuthModel;
  private readonly contextKey = 'selectedContext';

  isCollapsed = true;
  translatePath = 'header.';
  showBtn: boolean;
  langs: string[] = [];
  currentLang!: string;
  activeContext!: ContextModel;
  lang: string = '';
  existsAlternativeContexts: any;
  isAdmin!: boolean;
  delivery = false;
  active = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public http: HttpClient,
    public activatedRoute: ActivatedRoute,
    public translateService: TranslateService,
    public accountLinkGenerator: AccountLinkGeneratorService,
    public userService: UserService,
    private modalService: NgbModal,
    public router: Router,
    public usService: UserService,
    config: NgbDropdownConfig,
    protected appConfigService: AppConfigService
  ) {
    config.placement = 'bottom-right';
    config.autoClose = 'inside';
  }

  isOnOrderPage() {
    return location.href.includes('public/shipping?orderId');
  }

  ngOnInit(): void {
    let lang = localStorage.getItem('lang')
    lang ? lang : lang = 'ro';
    this.getProfile();
    this.userService.getCurrentContext();
    this.langs = this.translateService.getLangs();
    this.switchLang(lang);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });

    // if (!localStorage.getItem(this.contextKey)){
    this.userService.isAutenticated$.subscribe((response) => {
      this.assignAuthModel(response);
      if (response && response.isAuthenticated) {
        const currentContext = JSON.parse(localStorage.getItem(this.contextKey)!) as ContextModel;
        if (currentContext) {
          this.isAdmin = currentContext.properties.profile == 'MDelivery' && response.isAdmin;
          this.activeContext = currentContext;
        }
        if (!this.activeContext) {
          this.triggerSelectContext();
        }
        this.existsAlternativeContexts = response.user.boards && response.user.boards.length > 1;
      }
    });
    // }

    this.userService.contextIsSelected$.subscribe((context) => {
      if (context) {
        this.activeContext = context;
        const currentUser = this.userService.getCurrentUser() as AuthModel;
        this.isAdmin = context.properties.role == ProfilesEnum.ADMINISTRATOR && currentUser.isAdmin;
        if (!this.isOnOrderPage()) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            switch (context.properties.role) {
              case 'Administrator':
                this.checkContext('admin', context.properties.role);
                break;
              case 'Carrier':
                this.checkContext('carrier', context.properties.role);
                break;
              case 'PickupOperator':
                this.checkContext('pick-up-point', context.properties.role);
                break;
              case 'ServiceProvider':
                this.checkContext('service-provider', context.properties.role);
                break;
              case 'User':
                this.checkContext('receiver', context.properties.role);
                break;
            }
          });
          this.getProfile();
        }
      }
    });
  }
  checkContext(rout: any, context: any) {
    if (!location.href.includes("searchByOrderId")) {
      this.router.navigate([rout], { replaceUrl: true });
    }
    if (location.href.includes("searchByOrderId") && context === "PickupOperator") {
      this.router.navigateByUrl(`pick-up-point${location.search}`);
    }
    if (location.href.includes("searchByOrderId") && context !== "PickupOperator") {
      this.triggerSelectContext()
    }
  }
  getProfile(): void {
    this.userService.getUser().subscribe((res) => {
      const context = localStorage.getItem('contextProfile');
      // @ts-ignore
      if (context != null && context.includes('ServiceProvider')) {
        this.showBtnListService = true;
      } else {
        this.showBtnListService = false;
      }
    });
  }

  switchLang(item: string): void {
    this.currentLang = item;
    this.translateService.use(item);
    localStorage.setItem('lang', this.currentLang)
  }

  logout(): void {
    this.userService.sessionLogout(null as unknown as AuthModel);
    this.isOnOrderPage()
      ? location.replace(this.accountLinkGenerator.getLogoutUrl(location.href))
      : location.replace(this.accountLinkGenerator.getLogoutUrl());
  }

  assignAuthModel(authModel?: AuthModel): void {
    this.authModel = authModel ? authModel : this.userService.getCurrentUser();
    this.isAuthenticated = authModel ? authModel.isAuthenticated : this.authModel && this.authModel.isAuthenticated;
  }

  userLogin() {
    /* location.replace(this.accountLinkGenerator.getUrl(`${this.baseUrl}/receiver/add-delivery`));*/
    this.accountLinkGenerator.getLoginUrl(location.href).subscribe((res) => {
      this.document.body.innerHTML = res;
      this.document.forms[0].submit();
    });
  }

  login() {
    this.accountLinkGenerator.getLoginUrl(location.href).subscribe((res) => {
      this.document.body.innerHTML = res;
      this.document.forms[0].submit();
    });
  }

  triggerSelectContext(): void {
    const selectedContext = localStorage.getItem(this.contextKey);
    if (selectedContext == null) {
      const auth = this.userService.getCurrentUser();
      const contexts = auth.user.boards as ContextModel[];
      if (contexts) {
        if (contexts.length === 1) {
          this.userService.setContext(contexts[0]).subscribe((res) => {
            if (!res.success) {
            }
          });
        } else {
          const modalRef = this.modalService.open(SelectContextModalComponent, {
            keyboard: false,
            backdrop: 'static',
          } as NgbModalOptions);
          modalRef.componentInstance.canClose = false;
        }
      }
    } else {
      const modalRef = this.modalService.open(SelectContextModalComponent, {
        keyboard: false,
        backdrop: 'static',
      } as NgbModalOptions);
      modalRef.componentInstance.canClose = false;
    }
  }

  navigate() {
    this.router.navigate([this.isOnAdminPage() ? 'receiver' : 'admin'], { replaceUrl: true }); // TODO remove this and use something else
  }

  isOnAdminPage(): boolean {
    return this.router.url.includes('admin'); // TODO Remove this and use something else
  }

  addDelivery(): void {
    debugger
    if (this.activeContext) {
      this.router.navigate(['/add-delivery']);
    }
    else { location.href = `${location.origin}/add-delivery`; }
  }

  mobileActive(): void {
    this.active = !this.active;
  }
}
