<div class="page-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 py-3">
       <div class="ml-3">
         <app-navigation-list [navigationList]="navList"></app-navigation-list>
       </div>
        <div class="parent bg-white mb-4 col-12 mr-auto ml-auto">
          <i *ngIf="loading" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <form class="text-left" [formGroup]="pickUpPointsFormGroup">
            <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center">
              <div class="name form-group col-sm-6 col-12  col-md-5 align-self-center pl-xl-0">
                <label for="name"
                       [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(pickUpPointsFormGroup.get('name')) }">{{'general.pickUpPoints.labels.name' | translate}}
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="name"
                       [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(pickUpPointsFormGroup.get('name')) , 'is-invalid': pickUpPointsFormGroup.get('name')?.invalid && pickUpPointsFormGroup.get('name')?.touched,
               'is-valid': pickUpPointsFormGroup.get('name')?.valid && pickUpPointsFormGroup.get('name')?.touched}"
                       formControlName="name" aria-describedby="name" placeholder="{{'general.pickUpPoints.labels.custom-name' | translate}}">
                <small class="text-danger"
                       *ngIf="errorCheckerUtil.hasSpecificError(pickUpPointsFormGroup.get('name'), 'required')">{{'general.errorTexts.required' | translate}}</small>
                <small class="text-danger"
                       *ngIf="errorCheckerUtil.hasSpecificError(pickUpPointsFormGroup.get('name'), 'validationErrors')">
                  <div *ngFor="let item of pickUpPointsFormGroup.get('name')?.getError('validationErrors')">
                    {{'validation-errors.'+item | translate}}
                  </div>
                </small>
              </div>
              <div class="name form-group col-12 col-sm-6 col-md-5 align-self-center pr-xl-0">
                <label for="code"
                       [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(pickUpPointsFormGroup.get('code')) }">{{'general.pickUpPoints.labels.pickup-point-id' | translate}}
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="code"
                       [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(pickUpPointsFormGroup.get('code')) , 'is-invalid': pickUpPointsFormGroup.get('code')?.invalid && pickUpPointsFormGroup.get('code')?.touched,
               'is-valid': pickUpPointsFormGroup.get('code')?.valid && pickUpPointsFormGroup.get('code')?.touched}"
                       formControlName="code" aria-describedby="code" placeholder="{{'general.pickUpPoints.labels.custom-code' | translate}}">
                <small class="text-danger"
                       *ngIf="errorCheckerUtil.hasSpecificError(pickUpPointsFormGroup.get('code'), 'required')">{{'general.errorTexts.required' | translate}}</small>
                <small class="text-danger"
                       *ngIf="errorCheckerUtil.hasSpecificError(pickUpPointsFormGroup.get('code'), 'validationErrors')">
                  <div *ngFor="let item of pickUpPointsFormGroup.get('code')?.getError('validationErrors')">
                    {{'validation-errors.'+item | translate}}
                  </div>
                </small>
              </div>
            </div>
            <app-address-form [addressForm]="address" [checking]="false"></app-address-form>
            <app-contact-person-form-group [contactPerson]="contactPerson"
              (outputForm)="pickUpPointsFormGroup.get('contactPerson')?.setValue($event)">
            </app-contact-person-form-group>

            <hr>


            <div class="buttons-group col-12 d-flex justify-content-xl-end justify-content-center mr-auto ml-auto">
              <button type="button" class="btn btn-outline-info mr-2"
                [routerLink]="['/admin/sp/details', spId]">{{'admin.buttons.cancelButton' | translate}}</button>
              <button type="submit" class="btn btn-outline-info"
                (click)="submit()" [disabled]="inRequest">{{'admin.buttons.saveButton' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
