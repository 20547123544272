<div [ngClass]="{'itms': name === 'admin.sp.labels.idno' || name === 'admin.sp.labels.type' || name === 'general.address.labels.region' ||
 name === 'general.contactPerson.labels.lastName' || name === 'general.contactPerson.labels.phone'}"
class="row no-gutters item" *ngIf="name && data">

    <div class="pr-3 name-data d-flex flex-column justify-content-around">
     <span>{{name | translate}}</span>
    </div>
  <div class="d-flex flex-column justify-content-around">
    <span class="data" [ngStyle]="{'font-size.px': data.length > 14}">
      {{data}}
    </span>
  </div>
</div>
