import { ClassProvider, Inject, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { UserService } from '../services/user.service';
import { catchError, finalize } from 'rxjs/operators';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService
  ) { }
  private readonly selectedContextKey = 'selectedContext';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentContext = JSON.parse(localStorage.getItem(this.selectedContextKey)!);
    const profile = currentContext && String(currentContext.properties.profile)?.replace('ă', 'a').replace('ă', 'a') || '';
    req = req.clone({
      withCredentials: true,
      setHeaders: {
        'selected-context': currentContext && String(currentContext.properties.role) || '',
        'identifier': currentContext && String(currentContext.properties.idn) || '',
        'profile': currentContext && profile || ''
      },
    });

    return next.handle(req).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {

          if (((err.status === 401 || err.status === 0) && !(window.location.href.includes('public'))
            && window.location.href !== (this.userService.appConfigService.appConfig.APP_BASE_URL + '/'))) {
            this.userService.getLoginUrl().subscribe((res) => {
              this.document.body.innerHTML = res;
              this.document.forms[0].submit();
            });
          }

          // return the error back to the caller
          return throwError(err);
        }
        else {
          return next.handle(req)
        }
      }),
      finalize(() => {
        return next.handle(req);
      })
    );
  }
}

export const AUTH_INTERCEPTOR_PROVIDER: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
};
