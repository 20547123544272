import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paged} from '../../models/paged.model';
import {Pagination} from '../../models';

@Component({
   selector: 'app-pagination',
   templateUrl: './pagination.component.html',
   styles: [
      `
         .active {
            border: 1px solid #1D688B !important;
         }
         .page-link {
            background-color: #f5f6f7 !important;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
         }


         .arrow-text {
           font-family: Roboto;
           font-size: 12px;
           line-height: 14px;
         }

       i {
         font-size: 12px;
       }

      `,
   ],
})
export class PaginationComponent implements OnInit {
   @Output() inputQueryChange = new EventEmitter<any>();
   @Output() pageClicked = new EventEmitter<any>();
   @Input() inputQuery: Pagination;
   @Input() paged: Paged;
   totalPages: any[];

   ngOnInit(): void {
      this.totalPages = [];
      for (let i = 1; i <= this.paged?.totalPages; i++) {
         this.totalPages.push(i);
      }

      this.createPagesList();
   }

   createPagesList(): void {
      if (this.paged.totalPages >= 5) {
         switch (this.paged.currentPage) {
            case 1:
            case 2: {
               this.populatePagesList(1, 5);
               break;
            }
            case this.paged.totalPages:
            case this.paged.totalPages - 1: {
               this.populatePagesList(this.paged.totalPages - 4, this.paged.totalPages);
               break;
            }
            default: {
               this.populatePagesList(this.paged.currentPage - 2, this.paged.currentPage + 2);
            }
         }
      } else {
         this.populatePagesList(1, this.paged.totalPages);
      }
   }

   populatePagesList(start: number, end: number): void {
      this.totalPages = [];
      for (let index = start; index <= end; index++) {
         if (index <= this.paged.totalPages) {
            this.totalPages.push(index);
         }
      }
   }

   onPageClicked(page: number): void {
      this.paged.hasNext || page <= this.paged.totalPages ? (this.paged.hasNext = true) : (this.paged.hasNext = false);
      // @ts-ignore
      this.paged.hasNext
         ? ((this.inputQuery.page = page), this.inputQueryChange.emit(this.inputQuery), this.pageClicked.emit())
         : null;
   }

  // tslint:disable-next-line:typedef
   onPageClickedMinus(page: number) {
      this.inputQuery.page = page;
      this.inputQueryChange.emit(this.inputQuery);
      this.pageClicked.emit();
   }
}
