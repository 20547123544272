import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  DoCheck,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SessionStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { FeedbackService } from 'src/app/shared/services';
import { StatusData } from '../../../shared/models/status-data.model';
import { buildFileName } from '../../service-provider/orders-list/print/print.component';
import { ServiceService } from '../../service-provider/orders-list/service.service';
import { SortableDirective, SortEvent } from '../../service-provider/orders-list/sortable.directive';
import { OrdersService } from '../services/orders.service';
import { IOrder, ISavedFilter } from './orders.model';
import { DeliveryDetailsService } from 'src/app/shared/services/delivery-details.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit, OnDestroy, DoCheck {
  constructor(
    public service: OrdersService,
    public route: Router,
    public datePipe: DatePipe,
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public serviceAdmin: ServiceService,
    public feedbackService: FeedbackService,
    public breakPoint: BreakpointObserver,
    private sessionStorage: SessionStorageService,
    private deliveryDetailsService: DeliveryDetailsService,
  ) {
  }

  pathTranslate = 'admin.buttons.';
  private readonly contextKey = 'contextProfile';
  valueBar = 50;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  public stateFilter: any[];
  private stateValue: string;
  private ind: number;
  public checked = false;
  idDeliveryArr: string[] = [];
  processingDeliveriesIdArr: string[] = [];
  processingDeliveriesStatusArr: string[] = [];
  public spList: any;
  public pickUpPointItems: any;
  public destinationItems: any;
  public productItems: any;
  public spIdforFilter = '';
  public pickupPointCode = '';
  public destinationRegionCode = '';
  public productCode = '';
  public deliveryAdres: any = [];
  dropdownSettings: IDropdownSettings;
  data: string[];
  arrFilter: number[] = [];
  show = false;
  showMenu = false;
  filterSp = false;
  statusFilter = false;
  pickUpPointFilter = false;
  destinationFiltre = false;
  productFilter = false;
  carrierFilter = false;
  isExport = true;
  loading = false;
  innerWidth: number;
  context: string;
  @ViewChild('container') container: { nativeElement: { contains: (arg0: any) => any; }; };
  sorter: boolean;
  changeStatus: boolean;
  isChakedChangeStatus: boolean = false;

  order: string;
  from: FormControl = new FormControl();
  to: FormControl = new FormControl();

  orderStatus?: IOrder;
  sender?: IOrder;
  pickUpPoint?: IOrder;
  destinationLocality?: IOrder;
  filterProductCode?: IOrder;
  filterCourier?: IOrder;
  sortBy?: string;

  isSorted = false;
  isStatus = false;
  isSender = false;
  isCarrier = false;
  isStyled = false;
  routeSubscriber!: Subscription;

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  styles = {
    padding: '4px 8px',
    color: 'white',
    'border-radius': '4px',
    'font-weight': 700,
    'font-family': 'Nunito Sans',
    'font-size': '11px',
  };

  syncronizingOrders: boolean;
  active = false;
  totalCount: number;
  activeSender = true;
  showBtnList: boolean;
  selected: any;
  checkbox = 'checkbox';
  items: Array<any> = [];
  arrItems: Array<object> = [];
  date: DatePipe;
  products: Array<object> = [];
  state = [];
  expeditor = [];
  locationExpeditor = [];
  locationDelivery = [];
  cariers = [];
  color = '';
  boldIndex: boolean;
  states = this.service.states;
  serviceArr = [];
  carriersList: any = [];
  carrierId = '';

  visibility: any = [
    { id: 0, visible: true },
    { id: 1, visible: true },
    { id: 2, visible: true },
    { id: 3, visible: true },
    { id: 4, visible: true },
    { id: 5, visible: true },
    { id: 6, visible: true },
    { id: 7, visible: true },
    { id: 8, visible: true },
    { id: 9, visible: true },
    { id: 10, visible: true },
    { id: 11, visible: true },
    { id: 12, visible: true },
  ];
  // @ts-ignore
  columns: any = [
    {
      id: 0,
      type: 'admin.table.idMdelivery',
      types: 'admin.table.idMdelivery-2',
      visible: true,
      style: false,
      str: true
    },
    {
      id: 1,
      type: 'admin.table.state',
      types: 'admin.table.state-2',
      visible: true,
      appSortable: 'state',
      style: false,
      str: false
    },
    {
      id: 2,
      type: 'admin.table.idOrder',
      types: 'admin.table.idOrder-2',
      visible: true,
      appSortable: 'IdOrder',
      style: false,
      str: true
    },
    {
      id: 3,
      type: 'admin.table.idWaybill',
      types: 'admin.table.idWaybill-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: false
    },
    { id: 4, type: 'admin.table.exp', types: '', visible: true, appSortable: 'IdWaybill', style: false, str: true },
    { id: 5, type: 'admin.table.dest', types: '', visible: true, appSortable: 'IdWaybill', style: false, str: false },
    {
      id: 6,
      type: 'admin.table.locExp',
      types: 'admin.table.locExp-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: true
    },
    {
      id: 7,
      type: 'admin.table.locDest',
      types: 'admin.table.locDest-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: false
    },
    {
      id: 8,
      type: 'admin.table.product',
      types: 'admin.table.product-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: true
    },
    {
      id: 9,
      type: 'admin.table.payDate',
      types: 'admin.table.payDate-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: true
    },
    {
      id: 10,
      type: 'admin.table.pickDate',
      types: 'admin.table.pickDate-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: false
    },
    {
      id: 11,
      type: 'admin.table.deliveredDate',
      types: 'admin.table.deliveredDate-2',
      visible: true,
      appSortable: 'IdWaybill',
      style: false,
      str: true
    },
    { id: 12, type: 'admin.table.carrier', types: '', visible: true, appSortable: 'IdWaybill', style: false, str: false },
  ];
  page = 1;
  searchField: any;
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;

  currentPage = 1;
  itemsPerPage = 25;
  pageSize: number;
  sizePage: Array<number> = [25, 15, 10, 5];
  formGroup: FormGroup;
  index: boolean;
  cars: any;
  searchLocationValue: string;
  valueChecking = false;
  pdfBtn = false;
  csvBtn = false;
  sorting: string;

  @HostListener('document:click', ['$event', '$event.target'])

  onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (targetElement.tagName == 'IMG') {
      return;
    } else {
      this.filterSp = false;
      this.statusFilter = false;
      this.carrierFilter = false;
      this.pickUpPointFilter = false;
      this.destinationFiltre = false;
      this.productFilter = false;
    }
  }

  ngOnInit(): void {
    this.subscribeEvent();

    const obj: ISavedFilter = this.sessionStorage.retrieve('savedSearch');
    if (obj !== null) {
      this.retrieveFilter(obj);
    }

    this.getCarriers();
    this.activatedRoute.snapshot.queryParams.status
      ? (this.stateValue = this.activatedRoute.snapshot.queryParams.status)
      : (this.stateValue = '');

    if (obj?.pageNumber === undefined || obj.pageNumber === null) {
      this.activatedRoute.snapshot.queryParams.page
        ? (this.currentPage = this.activatedRoute.snapshot.queryParams.page)
        : (this.currentPage = 1);
    }

    this.context = localStorage.getItem(this.contextKey) ?? '';

    this.pickupPointCode = '';

    if (obj !== null && obj.searchBy === undefined || obj !== null && obj.searchBy === null || obj !== null && obj.searchBy!.length === 0 || obj === null) {
      this.getForm();
    }

    if (obj === null) {
      this.pushParamsUrl();
      this.getRegistredOrders();
    }

    this.getChecked();
    this.onOrderList();
    this.getSPList();
    this.getPickUpList();
    this.getDestination();
    this.getProduct();

    // tslint:disable-next-line:label-position
    this.dropdownSettings = {
      enableCheckAll: false,
      singleSelection: false,
      idField: 'visible',
      textField: 'type',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.showColumnsByDeviceSize();
  }

  showColumnsByDeviceSize(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1600) {
      this.columns.forEach((el: {}, i: number) => {
        if (i >= 10 && i <= 11) {
          this.columns[i].visible = false;
          this.visibility[i].visible = false;
          this.columns[i].style = true;
        }
      });
    }

    if (this.innerWidth < 1469) {
      this.columns.forEach((el: {}, i: number) => {
        if (i >= 8 && i <= 11) {
          this.columns[i].visible = false;
          this.visibility[i].visible = false;
          this.columns[i].style = true;
        }
      });
    }

    if (this.innerWidth < 1230) {
      this.columns.forEach((el: {}, i: number) => {
        if (i >= 6 && i <= 11) {
          this.columns[i].visible = false;
          this.visibility[i].visible = false;
          this.columns[i].style = true;
        }
      });
    }

    if (this.innerWidth < 1030) {
      this.columns.forEach((el: {}, i: number) => {
        if (i >= 5 && i <= 11) {
          this.columns[i].visible = false;
          this.visibility[i].visible = false;
          this.columns[i].style = true;
        }
      });
    }
  }

  getForm(): void {
    this.service.states.find((el) => {
      // @ts-ignore
      if (el === this.stateValue) {
        const elem = el;
        this.ind = this.service.states.indexOf(elem);
      }
    });
    this.formGroup = this.fb.group({
      searchField: [],
      stateSelect: [this.service.states[this.ind]],
      searchByLocationSender: [],
      carrierSelect: [],
    });
  }

  getChecked(): boolean {
    return true;
  }

  onOrderList(): void {
    if (this.route.url.includes('service-provider/details')) {
      this.showBtnList = true;
    } else {
      this.showBtnList = false;
    }
  }

  onSort({ column, direction }: SortEvent, columnName = ''): any {
    this.sortByDate(columnName, direction.toString());
  }

  getVisibleStatus(evt: Event, ind: number): void {
    // @ts-ignore
    const checked = evt.target.checked;
    if (checked === false) {
      this.columns[ind].visible = false;
      this.visibility[ind] = false;
    } else {
      this.columns[ind].visible = true;
      this.visibility[ind] = true;
    }
  }

  pushParamsUrl(): void {
    this.route.navigate(['/admin/orders'], {
      relativeTo: this.activatedRoute,

      queryParams: {
        searchBy: this.searchField,
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        status: this.stateValue,
        carrierId: this.carrierId,
        providerServiceId: this.spIdforFilter,
        pickupPointCode: this.pickupPointCode,
        destinationRegionCode: this.destinationRegionCode,
        productCode: this.productCode,
        fromDay: this.fromDate?.day == undefined ? null : this.fromDate?.day,
        fromMonth: this.fromDate?.month == undefined ? null : this.fromDate?.month,
        fromYear: this.fromDate?.year == undefined ? null : this.fromDate?.year,
        toDay: this.toDate?.day == undefined ? null : this.toDate?.day,
        toMonth: this.toDate?.month == undefined ? null : this.toDate?.month,
        toYear: this.toDate?.year === undefined ? null : this.toDate?.year,
      },
    });
  }

  pageDataChanged(pageNumber: number): void {
    this.currentPage = pageNumber;
    this.pushParamsUrl();
    this.saveFilter();
  }

  changePagesize(num: string): void {
    this.currentPage = 1;
    this.itemsPerPage = +num;
    this.pushParamsUrl();
    this.saveFilter();
  }

  getField(): void {
    this.searchField = this.formGroup.get('searchField')?.value;
    this.searchField !== '' ? this.currentPage = 1 : null;
    this.formGroup.get('searchByLocationSender')?.value ? this.searchLocationValue = this.formGroup.get('searchByLocationSender')?.value : null;
    this.saveFilter();
    this.pushParamsUrl();
  }

  checkedUncheckedAll(evt: Event): boolean {
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.isExport = false;
    // @ts-ignore
    this.checked = evt.target.checked;
    if (this.checked === false) {
      this.isExport = true;
    }
    this.getRegistredOrders();
    if (this.activatedRoute.snapshot.queryParams.status === 'Processing') {
      this.items.forEach((el) => {
        el.checked = this.checked;
        if (el.status === 'Processing' && el.checked === true) {
          this.processingDeliveriesIdArr.push(el.deliveryId);
          this.processingDeliveriesStatusArr.push(el.status);
        }
      });
    }
    return this.checked;
  }

  getRegistredOrders(): void {
    this.valueBar = 30;
    this.loading = true;
    setTimeout(() => {
      this.service
        .getRegistredOrders()
        .pipe(finalize(() => {
          this.valueBar = 100;
          setTimeout(() => {
            if (this.valueBar === 100) {
              this.valueChecking = true;
            }
            setTimeout(() => {
              this.loading = false;
              this.valueChecking = false;
              this.valueBar = 30;
            }, 300);
          }, 800);
        })
        )
        .subscribe((res: { paged: { totalCount: number; }; items: any[]; }) => {
          this.state = [];
          this.totalCount = res.paged.totalCount;
          this.items = res.items;
          if (this.isStyled) {
            this.doingStyleOrder();
          }
        },
          (err: HttpErrorResponse) => {
            this.state = [];
            this.totalCount = 0;
            this.items = [];
            this.feedbackService.handleInfo('No result');
          });
    });
  }

  doingStyleOrder(): void {
    this.idDeliveryArr = [];
    this.items.forEach((el: string | any, i: number) => {
      el.status == 1 ? (el.status = 'Awaiting Payment') : null;
      el.status == 'Awaiting Payment'
        ? (el.color = {
          backgroundColor: '#b7b8b9',
          ...this.styles,
        })
        : null;
      el.status === 2 ? (el.status = 'Paid') : null;
      el.status === 'Paid'
        ? (el.color = {
          backgroundColor: '#4382B4',
          ...this.styles,
        })
        : null;
      el.status === 3 ? (el.status = 'Processing') : null;
      el.status === 'Processing'
        ? (el.color = {
          backgroundColor: '#f1c947',
          ...this.styles,
        })
        : null;
      el.status === 4 ? (el.status = 'Cancelled') : null;
      el.status === 'Cancelled'
        ? (el.color = {
          backgroundColor: '#d00049',
          ...this.styles,
        })
        : null;
      el.status === 5 ? (el.status = 'Expired ') : null;
      el.status === 'Expired'
        ? (el.color = {
          backgroundColor: '#6b0606',
          ...this.styles,
        })
        : null;
      el.status === 6 ? (el.status = 'Ready') : null;
      el.status === 'Ready'
        ? (el.color = {
          backgroundColor: '#bc7daf',
          ...this.styles,
        })
        : null;
      el.status === 7 ? (el.status = 'Awaiting Pick Up') : null;
      el.status === 'Awaiting Pick Up'
        ? (el.color = {
          backgroundColor: '#99dbdb',
          ...this.styles,
        })
        : null;
      el.status === 8 ? (el.status = 'Delivering') : null;
      el.status === 'Delivering'
        ? (el.color = {
          backgroundColor: '#17A2B8',
          ...this.styles,
        })
        : null;
      el.status === 9 ? (el.status = 'Delivered') : null;
      el.status === 'Delivered'
        ? (el.color = {
          backgroundColor: '#9cdd7f',
          ...this.styles,
        })
        : null;
      el.status === 10 ? (el.status = 'Confirmed') : null;
      el.status === 'Confirmed'
        ? (el.color = {
          backgroundColor: '#276727',
          ...this.styles,
        })
        : null;
      el.status === 11 ? (el.status = 'Not Delivered') : null;
      el.status === 'Not Delivered'
        ? (el.color = {
          backgroundColor: '#F34B4B',
          ...this.styles,
        })
        : null;
      el.status === 12 ? (el.status = 'Returning') : null;
      el.status === 'Returning'
        ? (el.color = {
          backgroundColor: '#7DBCAA',
          ...this.styles,
        })
        : null;
      el.status === 13 ? (el.status = 'Returned') : null;
      el.status === 'Returned'
        ? (el.color = {
          backgroundColor: '#006d6e',
          ...this.styles,
        })
        : null;
      el.status === 14 ? (el.status = 'Unknown') : null;
      el.status === 'Unknown'
        ? (el.color = {
          backgroundColor: '#a9a9a9',
          ...this.styles,
        })
        : null;
      el.status === 15 ? (el.status = 'Lost') : null;
      el.status === 'Lost'
        ? (el.color = {
          backgroundColor: '#F34B4B',
          ...this.styles,
        })
        : null;
      // @ts-ignore
      el.checked = this.checked;
      if (this.checked === true) {
        this.idDeliveryArr.push(el.deliveryId);
      }
      // @ts-ignore
      this.state.push(el.status);
      this.spList.forEach((sp: any) => {
        if (sp.id === el.providerServicesId.toLowerCase()) {
          el.spName = sp.name;
          el.style = this.activeSender;
        }
      });
    });

    // @ts-ignore
    this.state = [...new Set(this.state)];
  }

  getOrderDetails(evt: Event, order: { checked: boolean, externalWaybillNumber: string, status: string }, ind: number): void {
    this.order = '';
    this.isExport = false;
    // @ts-ignore
    const idDelivery = order.deliveryId;
    this.order = order.status;
    // @ts-ignore
    const target = evt.target?.checked;
    if (target === true) {
      this.idDeliveryArr.push(idDelivery);
      if (order.status === 'Processing' || order.status === 'Returning') {
        this.processingDeliveriesIdArr.push(idDelivery);
        this.processingDeliveriesStatusArr.push(order.status);
      }
      // @ts-ignore
      order.checked = true;
    } else {
      const index = this.idDeliveryArr.indexOf(idDelivery);
      this.idDeliveryArr.splice(index, 1);
      const id = this.processingDeliveriesIdArr.indexOf(idDelivery);
      this.processingDeliveriesIdArr.splice(id, 1);
      this.processingDeliveriesStatusArr.splice(id, 1);
      order.checked = false;
    }

    if (this.idDeliveryArr.length === 0) {
      this.checked = false;
      this.isExport = true;
      this.changeStatus = false;
    }
  }

  getDetails(id: number): void {
    this.route.navigate(['admin/delivery-details', id]);
  }

  getStateParam(value: string, item: any, i: number): void {
    this.openStatesFilter();
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.changeStatus = false;
    const index = i;
    this.currentPage = 1;

    this.orderStatus = { item, value, i };
    this.saveFilter();

    value === 'All' ? (value = '') : null;
    this.stateValue = '';
    this.stateValue = value.split(' ').join('');
    this.pushParamsUrl();
    this.getRegistredOrders();
    item.style = true;
    this.service.states.forEach((el, i) => {
      if (index != i) {
        el.style = false;
      }
    });
  }

  getCarrierName(name: string, carrier: any): void {
    this.currentPage = 1;
    this.openCarrierFilter();
    this.changeStatus = false;
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    carrier.style = true;
    this.carrierId = '';
    this.carriersList.find((el: any) => {
      if (el.name === name) {
        this.carrierId = el.id;
      } else if (el.name === name + ' ') {
        this.carrierId = el.id;
      }

      if (carrier.id != el.id || carrier.id != el.id + '') {
        el.style = false;
      }
    });

    this.filterCourier = { value: name, item: carrier };
    this.saveFilter();

    this.pushParamsUrl();
    this.getRegistredOrders();
  }

  getCarriers(): void {
    this.service.getCarriersList().subscribe((res) => {
      this.carriersList = res.items;
      this.carriersList.unshift({ name: 'All' });
    });
  }

  getSPList(): void {
    this.service.getSpList().subscribe((res) => {
      this.spList = res.items;
    });
  }

  getPickUpList(): void {
    this.service.getPicUpPointList(this.context).subscribe((res) => {
      this.pickUpPointItems = res;
    });
  }

  getFilterBySp(value: string, sp: any, i: number): void {
    this.currentPage = 1;
    this.openFilterSp();
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.changeStatus = false;
    sp.style = true;
    this.spIdforFilter = '';
    // this.activeSender = !this.activeSender;
    this.spList.find((el: any) => {
      if (el.name === value) {
        this.spIdforFilter = el.id.toUpperCase();
      }
      if (el.id != sp.id) {
        el.style = false;
      }
    });

    this.sender = { value, item: sp, i };
    this.saveFilter();

    this.pushParamsUrl();
    this.getRegistredOrders();
  }

  getFilterByPickUpPoint(value: string, pickUpPoint: any, i: number): void {
    this.currentPage = 1;
    this.openPickUpPointFilter();
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.changeStatus = false;
    this.pickupPointCode = pickUpPoint.name;

    this.pickUpPoint = { value, item: pickUpPoint, i };
    this.saveFilter();

    this.pushParamsUrl();
    this.getRegistredOrders();
  }

  filtreByDestination(destination: any, i: number): void {
    this.currentPage = 1;
    this.openFilterDestination();
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.changeStatus = false;
    this.destinationRegionCode = destination;

    this.destinationLocality = { value: destination, i };
    this.saveFilter();

    this.pushParamsUrl();
    this.getRegistredOrders();
  }

  getDestination(): void {
    this.service.getDestinationRegionCodes().subscribe((res) => {
      this.destinationItems = res;
    });
  }

  filterByProduct(product: any, i: number): void {
    this.currentPage = 1;
    this.processingDeliveriesIdArr = [];
    this.processingDeliveriesStatusArr = [];
    this.changeStatus = false;

    this.filterProductCode = { value: product, i };
    this.saveFilter();

    if (product === 'All') {
      this.openProductFilter();
      this.productCode = '';
      this.processingDeliveriesIdArr = [];
      this.processingDeliveriesStatusArr = [];
      this.pushParamsUrl();
      this.getRegistredOrders();
    } else {
      this.openProductFilter();
      this.productCode = product;
      this.pushParamsUrl();
      this.getRegistredOrders();
    }
  }

  getProduct() {
    this.service.getProductCodes().subscribe((res) => {
      this.productItems = res;
      this.productItems.unshift('All');
    });
  }

  openStatesFilter() {
    this.statusFilter = !this.statusFilter;
    this.carrierFilter = false;
    this.changeStatus = false;
    this.filterSp = false;
    this.pickUpPointFilter = false;
    this.destinationFiltre = false;
    this.productFilter = false;
  }

  sortByDate(columnName: any, direction: any) {
    if (columnName == 'orderDate') {
      if (direction == 'asc') {
        this.items.sort((a: any, b: any) => Date.parse(a.orderDate) - Date.parse(b.orderDate));
      } else {
        this.items.sort((a: any, b: any) => Date.parse(b.orderDate) - Date.parse(a.orderDate));
      }
    }

    if (columnName == 'estimatedDeliveryStart') {
      if (direction == 'asc') {
        this.items.sort((a: any, b: any) => Date.parse(a.estimatedDeliveryStart) - Date.parse(b.estimatedDeliveryStart));
      } else {
        this.items.sort((a: any, b: any) => Date.parse(b.estimatedDeliveryStart) - Date.parse(a.estimatedDeliveryStart));
      }
    }

    if (columnName == 'estimatedDeliveryEnd') {
      if (direction == 'asc') {
        this.items.sort((a: any, b: any) => Date.parse(a.estimatedDeliveryEnd) - Date.parse(b.estimatedDeliveryEnd));
      } else {
        this.items.sort((a: any, b: any) => Date.parse(b.estimatedDeliveryEnd) - Date.parse(a.estimatedDeliveryEnd));
      }
    }
  }

  selectFilter(i: number, e?: any): void {
    if (i === this.visibility[i].id && this.visibility[i].visible === false) {
      this.visibility[i].visible = true;
      this.columns[i].visible = true;
      this.columns[i].style = false;
      this.arrFilter.push(i);
    } else {
      const index = this.arrFilter.indexOf(i);
      this.visibility[i].visible = false;
      this.columns[i].visible = false;
      this.columns[i].style = true;
      this.arrFilter.splice(index, 1);
    }
    this.saveFilter();
  }

  toggleBtn(): void {
    this.show = !this.show;
  }

  onSorter(): void {
    this.sorter = !this.sorter;
  }

  onChangeStatus(): void {
    this.changeStatus = !this.changeStatus;
  }

  filterHide(e: any) {
    const name = e.target.className;

    if (name !== 'form-columns' && !name.includes('itemsTd')) {
      this.show = true ? this.show = false : this.show = false;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.saveFilter();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  newActive(): void {
    this.showMenu = !this.showMenu;
    this.active = !this.active;
  }

  openFilterSp(): void {
    this.filterSp = !this.filterSp;
    this.statusFilter = false;
    this.carrierFilter = false;
    this.pickUpPointFilter = false;
    this.destinationFiltre = false;
    this.productFilter = false;
    this.changeStatus = false;
  }

  preventDefault(e: Event): void {
    e.preventDefault();
  }

  openPickUpPointFilter(): void {
    this.pickUpPointFilter = !this.pickUpPointFilter;
    this.statusFilter = false;
    this.carrierFilter = false;
    this.filterSp = false;
    this.destinationFiltre = false;
    this.productFilter = false;
    this.changeStatus = false;
  }

  openProductFilter(): void {
    this.productFilter = !this.productFilter;
    this.statusFilter = false;
    this.carrierFilter = false;
    this.filterSp = false;
    this.pickUpPointFilter = false;
    this.destinationFiltre = false;
    this.changeStatus = false;
  }

  openFilterDestination(): void {
    this.destinationFiltre = !this.destinationFiltre;
    this.statusFilter = false;
    this.carrierFilter = false;
    this.filterSp = false;
    this.pickUpPointFilter = false;
    this.productFilter = false;
    this.changeStatus = false;
  }

  openCarrierFilter(): void {
    this.carrierFilter = !this.carrierFilter;
    this.statusFilter = false;
    this.filterSp = false;
    this.pickUpPointFilter = false;
    this.destinationFiltre = false;
    this.productFilter = false;
    this.changeStatus = false;
  }

  updateStatuses(): void {
    const self = this;
    this.syncronizingOrders = true;
    const syncPromises = this.items.map((order: any) => {
      return self.deliveryDetailsService.SyncStatusById(order.deliveryId)
        .toPromise();
    });

    Promise.all(syncPromises)
      .then(() => {
        self.syncronizingOrders = false;
      }).catch(error => {
        self.syncronizingOrders = false;
        self.feedbackService.handleError(error);
      });

  }

  resetAllFields(): void {
    this.visibility.forEach((el: { visible: boolean; }, i: number) => {
      el.visible = true;
      this.columns[i].style = false;
      this.columns[i].visible = true;
    });

    this.service.states.forEach((state) => {
      state.style = false;
    });

    this.service.states[0].style = true;

    this.searchField = '',
      this.currentPage = 1,
      this.itemsPerPage = 25,
      this.stateValue = '',
      this.carrierId = '',
      this.spIdforFilter = '',
      this.pickupPointCode = '',
      this.destinationRegionCode = '',
      this.productCode = '';

    this.orderStatus = undefined;
    this.sender = undefined;
    this.pickUpPoint = undefined;
    this.destinationLocality = undefined;
    this.filterProductCode = undefined;
    this.filterCourier = undefined;

    this.route.navigate(['/admin/orders'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        searchBy: '',
        page: 1,
        itemsPerPage: this.itemsPerPage,
        status: '',
        carrierId: '',
        providerServiceId: '',
        pickupPointCode: '',
        destinationRegionCode: '',
        productCode: this.productCode,
        fromDay: '',
        fromMonth: '',
        fromYear: '',
        toDay: '',
        toMonth: '',
        toYear: '',
        active: '1'
      }
    }),
      this.formGroup.reset();
    this.from.reset();
    this.to.reset();

    this.getRegistredOrders();
    this.resetFilter();
  }

  exportData(): void {
    if (this.idDeliveryArr.length > 0) {
      this.loading = true;
      this.isExport = true;

      this.serviceAdmin.getPdfForPrint(this.idDeliveryArr).subscribe(
        (file) => {
          this.loading = false;
          this.isExport = false;
          FileSaver.saveAs(file, buildFileName(this.idDeliveryArr[0], 'pdf'));
          this.feedbackService.handleSuccess('success-print-waybill');
        },
        () => {
          this.isExport = false;
          this.loading = false;
          this.feedbackService.handleWarning('print-waybill-err');
        }
      );
    }
  }

  generateInvoice(): void {
    this.loading = true;
    this.pdfBtn = true;
    this.service.generateInvoice(this.idDeliveryArr).pipe(
      delay(3000),
      finalize(() => {
        this.loading = false;
        this.pdfBtn = false, this.loading = false;
      })
    ).subscribe(
      (res: any) => {
        FileSaver.saveAs(res, buildFileName(this.idDeliveryArr[0], 'pdf'));
        this.feedbackService.handleSuccess('success-print-invoice');
      }, (err: HttpErrorResponse) => {
        if (err.status == 404) {
          this.feedbackService.handleInfo('select-order');
        } else {
          this.feedbackService.handleWarning('server-error');
        }
      });
  }

  exportOrdersToCSV(): void {
    this.loading = true;
    this.pushParamsUrl();
    this.service.exportOrdersToCSV().pipe(
      delay(3000),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe(
      (res: any) => {
        FileSaver.saveAs(res, buildFileName('exportOrdersToCSV', 'csv'));
        this.feedbackService.handleSuccess('success-export-orders-to-csv');
      }, error => {
        this.feedbackService.handleWarning('server-error');
      });
  }

  getStatusDate(item: any, status: string, historyStatus: string) {
    const statuses = item.statuses as Array<StatusData>;

    const deliveryStatus = statuses.find((x) => x.name == status) as StatusData;

    if (deliveryStatus.isActive) {
      const deliveryHistoryStatuses = item.historyStatuses as Array<StatusData>;

      let deliveryHistoryStatus = deliveryHistoryStatuses.find((x) => x.name == historyStatus) as StatusData;

      if (deliveryHistoryStatus == undefined && status == 'created') {
        deliveryHistoryStatus = deliveryHistoryStatuses.find((x) => x.name == 'Paid') as StatusData;
      }

      return deliveryHistoryStatus?.date;
    }

    return;
  }

  sort(value: string): string {
    value === 'pickUpDate' ? this.sortByDate('orderDate', 'asc') : null;
    value === 'createDate' ? this.sortByDate('estimatedDeliveryStart', 'asc') : null;
    value === 'deliveryDate' ? this.sortByDate('estimatedDeliveryEnd', 'asc') : null;
    value ? this.sorting = value : this.sorting = '';

    this.saveFilter();

    this.sorting !== undefined ? this.sorter = false : this.sorter = true;
    return this.sorting;
  }

  changeStatuses(status: string): void {
    let isAllProcessing = true;
    let isAllReturning = true;

    if (this.processingDeliveriesStatusArr.length > 0) {
      for (let i = 0; i < this.processingDeliveriesStatusArr.length; i++) {
        if (this.processingDeliveriesStatusArr[i] === 'Returning') {
          isAllProcessing = false;
        }

        if (this.processingDeliveriesStatusArr[i] === 'Processing') {
          isAllReturning = false;
        }
      }
    }

    if (this.processingDeliveriesIdArr.length > 0) {
      this.service.changeStatusToReady(this.processingDeliveriesIdArr).subscribe(
        (res: any) => {
          if (isAllReturning) {
            this.stateValue = 'Returned';
            this.service.states[6].style = true;
            this.service.states[3].style = false;
            this.orderStatus = { i: 6, value: this.stateValue };
          } else {
            this.stateValue = 'Ready';
            this.service.states[13].style = true;
            this.service.states[3].style = false;
            this.orderStatus = { i: 13, value: this.stateValue };
          }
          this.pushParamsUrl();
          this.getRegistredOrders();
          this.checked = false;
          this.processingDeliveriesIdArr = [];
          this.processingDeliveriesStatusArr = [];
          this.saveFilter();
        }
        , error => {
          this.feedbackService.handleWarning('server-error');
        });
    }
    this.changeStatus !== undefined ? this.changeStatus = false : this.changeStatus = true;
  }

  saveFilter(): void {
    const obj: ISavedFilter = {
      itemsPerPage: this.itemsPerPage,
      pageNumber: this.currentPage,
      orderStatus: this.orderStatus,
      sender: this.sender,
      pickUpPoint: this.pickUpPoint,
      destinationLocality: this.destinationLocality,
      filterProductCode: this.filterProductCode,
      filterCourier: this.filterCourier,
      sortBy: this.sorting,
      dateFrom: this.fromDate,
      dateTo: this.toDate,
      searchBy: this.searchField,
    };
    this.sessionStorage.store('savedSearch', obj);
  }

  resetFilter(): void {
    this.sessionStorage.clear('savedSearch');
  }

  retrieveFilter(obj: ISavedFilter): void {
    if (obj.itemsPerPage) {
      this.itemsPerPage = obj.itemsPerPage;
    }

    if (obj.pageNumber) {
      this.currentPage = +(obj.pageNumber);
    }

    if (obj.orderStatus) {
      this.isStatus = true;
      this.orderStatus = obj.orderStatus;
      let value = obj.orderStatus.value;
      value === 'All' ? (value = '') : '';
      this.stateValue = '';
      this.stateValue = value!.split(' ').join('');
    }

    if (obj.sender) {
      this.isSender = true;
      this.sender = obj.sender;
      this.spIdforFilter = obj.sender.item.id;
    }

    if (obj.pickUpPoint) {
      this.pickUpPoint = obj.pickUpPoint;
      this.pickupPointCode = obj.pickUpPoint.item.code;
    }

    if (obj.destinationLocality) {
      this.destinationLocality = obj.destinationLocality;
      this.destinationRegionCode = obj.destinationLocality.value!;
    }

    if (obj.filterProductCode) {
      const product = obj.filterProductCode;
      if (product.value === 'All') {
        this.productCode = '';
        this.processingDeliveriesIdArr = [];
        this.processingDeliveriesStatusArr = [];
      } else {
        this.openProductFilter();
        this.productCode = product.value!;
      }
      this.filterProductCode = obj.filterProductCode;
    }

    if (obj.filterCourier) {
      this.isCarrier = true;
      this.filterCourier = obj.filterCourier;
      this.carrierId = obj.filterCourier.item.id;
    }

    if (obj.sortBy) {
      this.isSorted = true;
      this.sortBy = obj.sortBy;
    }

    if (obj.dateFrom) {
      this.fromDate = obj.dateFrom;
      this.from.patchValue(this.formatter.format(obj.dateFrom));
    }

    if (obj.dateTo) {
      this.toDate = obj.dateTo;
      this.to.patchValue(this.formatter.format(obj.dateTo));
    }

    if (obj.searchBy) {
      this.searchField = obj.searchBy;
      this.getForm();
      this.formGroup.patchValue({
        searchField: this.searchField
      });
    }

    this.pushParamsUrl();
    this.getRegistredOrders();
  }

  subscribeEvent(): void {
    this.routeSubscriber = this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!event.url.includes('admin/orders') && !event.url.includes('admin/delivery')) {
          this.resetFilter();
        }
      }
    });
  }

  ngDoCheck(): void {
    if (this.items.length !== 0 && this.isSorted) {
      this.isSorted = false;
      this.sort(this.sortBy!);
    }

    if (this.service.states.length !== 0 && this.isStatus) {
      this.isStatus = false;
      this.service.states.forEach((el, i) => {
        el.style = this.orderStatus!.i === i;
      });
    }

    if (this.spList !== undefined && this.spList.length !== 0 && this.isSender) {
      this.isSender = false;

      this.spList.find((el: any) => {
        if (el.name === this.sender?.value) {
          this.spIdforFilter = el.id.toUpperCase();
        }
        el.style = el.id === this.sender?.item.id;
      });
    }

    if (this.carriersList !== undefined && this.carriersList.length !== 0 && this.isCarrier) {
      this.isCarrier = false;

      const currentCarrier = this.filterCourier?.item.id;
      this.carriersList.find((el: any) => {
        el.style = !(currentCarrier !== el.id || currentCarrier !== el.id + '');
      });
    }

    if (this.totalCount && !this.isStyled && this.spList) {
      this.isStyled = true;
      this.doingStyleOrder();
    }
  }

  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }

  groupByProducts(products: []) {
    if (products.length > 1) {
      return "...";
    }
    return "";
  }

  groupBy = (array: any[], key: string | number) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      )
      return result;
    }, []);
  };
}
