import { Component, Input, OnInit } from '@angular/core';
import { PickupPointsModel } from '../../models/pickupPoints.model';
import { PickupPointsService } from '../../services/pickup-points.service';
import { DisableConfirmModalComponent } from '../disable-confirm-modal/disable-confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagination } from '../../models';
import { Paged } from '../../models/paged.model';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FeedbackService } from '../../services';

@Component({
  selector: 'app-pickup-points-list',
  templateUrl: './pickup-points.component.html',
  styleUrls: ['./pickup-points.component.scss']
})
export class PickupPointsComponent implements OnInit {

  @Input() spId: string;
  pageNumber: string = "pickupPointPageNumber";
  pickupPoints: PickupPointsModel[];
  paged: Paged;
  query = {
    itemsPerPage: 10,
    page: 1
  } as Pagination;
  loading: boolean;
  constructor(
    private pPService: PickupPointsService,
    private modalService: NgbModal,
    private feedbackService: FeedbackService
  ) {
  }

  ngOnInit(): void {
    this.query.page = JSON.parse(localStorage.getItem(this.pageNumber) ?? "1") as number;
    this.getPickUpPointData();
  }

  getPickUpPointData(): void {
    this.loading = true;
    localStorage.setItem(this.pageNumber, this.query.page.toString());
    if (this.spId) {
      this.pPService.getPickupPointsBySp(this.query, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {
          this.pickupPoints = res.items;
          this.paged = res.paged;
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
    }
  }

  deactivateSp(pP: PickupPointsModel): void {
    const modalRef = this.modalService.open(DisableConfirmModalComponent);
    modalRef.componentInstance.obj = pP;
    modalRef.result.then((response: string) => {
      if (response === 'confirmed') {
        this.disablePickupPoint(pP, this.spId);
      }
    });
  }

  disablePickupPoint(sp: PickupPointsModel, spId: string): void {
    this.pPService.disableEnablePickupPoint(sp, this.spId)
      .subscribe(
        () => {
          this.feedbackService.handleSuccess('change-pickup-point-success');
          this.getPickUpPointData();
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
  }
}


