import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SavedAddress, ShippingAddress} from '../models';
import {AppConfigService} from './app-config.service';
import {AddressModel} from '../models/address.model';

@Injectable({
   providedIn: 'root',
})
export class AddressService {
   private api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;

   constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

   getList(): Observable<Array<SavedAddress>> {
      return this.http.get<Array<SavedAddress>>(`${this.api}/receiver/address`);
   }

  saveInternationalAddress(address: any): Observable<any> {
     const body = {
       internationalAddress: address
     };
     return this.http.post<string>(`${this.api}/receiver/address/international`, {...body});
  }

   saveAddress(address: SavedAddress): Observable<any> {
      return this.http.post<string>(`${this.api}/receiver/address`, {...address});
   }

   updateInternationalAddress(address: any, id: string): Observable<any> {
     const body = {
       internationalAddress: address
     }
     return this.http.put<string>(`${this.api}/receiver/address/international/${id}`, { ...body });
   }

   updateAddress(address: SavedAddress, id: string): Observable<any> {
      return this.http.patch<string>(`${this.api}/receiver/address/${id}`, {...address});
   }


   getAddress(id: string): Observable<SavedAddress> {
      return this.http.get<SavedAddress>(`${this.api}/receiver/address/${id}`);
   }

   deleteAddress(id: string): Observable<SavedAddress> {
      return this.http.delete<SavedAddress>(`${this.api}/receiver/address/${id}`);
   }

  deleteIntAddress(id: string): Observable<SavedAddress> {
    return this.http.delete<SavedAddress>(`${this.api}/receiver/address/international/${id}`);
  }
}
