import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { CarrierOptionsRequest, OrderResponse, PricingResponse } from '../models';
import { OrderConfirmation } from '../models/order-confirmation.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/pub`;
  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient,
    public paginationGenerator: PaginationGeneratorService
  ) { }

  getOrder(urlParams: ParamMap): Observable<OrderResponse> {
    var params = new HttpParams();
    const neededKeys = ['orderId', 'serviceId'];
    urlParams.keys.forEach(key => {
      if (urlParams.has(key) && neededKeys.includes(key)) {
        // tslint:disable-next-line:no-non-null-assertion
        params = params.append(key, urlParams.get(key)!);
      }
    }
    );
    return this.http.get<OrderResponse>(`${this.api}/orders`, { params });
  }

  getIntegrationType(id: string): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    let params = new HttpParams()
    params = params.append('orderId', id)
    params = params.append('serviceId', '1')
    return this.http.get<any>(`${api}/api/v1.0/pub/orders`, {params});
  }

  getCarrierOptions(config: CarrierOptionsRequest): Observable<Array<PricingResponse>> {
    return this.http.patch<Array<PricingResponse>>(`${this.api}/dictionaries/carrier/pricing`, config);
  }


  printOrder(deliveryId: string): Observable<any> {
    return this.http.get(`${this.api}/delivery/print/waybill/${deliveryId}`, { responseType: 'arraybuffer' })
  }

  searchOrders(ids: Array<string>, captcha: string): Observable<Array<OrderConfirmation>> {
    let params = new HttpParams();
    if (ids.length > 0) {
      ids.forEach(item => {
        params = params.append('ids', item.trim());
      });
    }
    if (captcha) {
      params = params.append('captcha', captcha);
    }
    return this.http.get<Array<OrderConfirmation>>(`${this.api}/delivery/search`, { params });
  }
}
