import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormErrorsChecker } from '../../../utils/formErrorsChecker';
import { ServicesService } from '../../../services/services.service';
import { ServiceModel } from '../../../models/service.model';
import { finalize } from 'rxjs/operators';
import { PickupPointModel } from 'src/app/shared/models/pickup-point.model';
import { PickupPointsModel } from 'src/app/shared/models/pickupPoints.model';
import { PickupPointsService } from 'src/app/shared/services/pickup-points.service';
import { FeedbackService } from 'src/app/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { translate } from '@angular/localize/src/utils';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-create-update-service',
  templateUrl: './service-add-edit.component.html',
  styleUrls: ['./service-add-edit.component.scss']
})
export class ServiceAddEditComponent implements OnInit {
  navList: any;
  servId: string;
  spId: string;
  inRequest = false;
  dropdownList: PickupPointModel[];
  dropdownSettings: any;

  servicesFormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    pickupPoints: new FormControl('', [Validators.required]),
    mPassId: new FormControl('', [Validators.required]),
    hasDelivery: new FormControl('', [])
  });

  errorCheckerUtil = new FormErrorsChecker();

  currentService: ServiceModel;
  select = '';
  unselect = '';
  loading: boolean;
  placeholder = '';

  constructor(
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private pickupPointsService: PickupPointsService,
    private feedbackService: FeedbackService,
    private multiLang: TranslateService

  ) {
  }

  ngOnInit(): void {
    this.getSetting();

    this.servId = this.route.snapshot.params['servId'];
    this.spId = this.route.snapshot.params['id'];
    if (this.servId && this.spId) {
      this.loading = true;
      this.servicesService.getServiceById(this.servId, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {
          this.currentService = res;
          this.integrateData();
          this.getPickupPointsList();
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
    } else {
      this.getPickupPointsList()
    }

    this.navList = [
      { url: '.', translatedText: 'general.services.editService', link: `/admin/sp/details/${this.spId}` },
    ];
  }

  getSetting(): IDropdownSettings {
    this.multiLang.get('select-all').subscribe(res => {
      this.select = res;
    });

    this.multiLang.get('unselect-all').subscribe(res => {
      this.unselect = res;
    });

    this.multiLang.get('placeholder').subscribe(res => {
      this.placeholder = res;
    });

    return this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      selectAllText: this.select,
      unSelectAllText: this.unselect,
      searchPlaceholderText: this.placeholder
    };
  }

  getPickupPointsList(): void {
    this.pickupPointsService.getPickupPointsBySp(undefined, this.spId)
      .subscribe(
        res => {
          this.dropdownList = res.items;
          if (this.currentService) {
            this.servicesFormGroup.patchValue({
              pickupPoints: this.dropdownList.filter((el: PickupPointsModel) => this.currentService.pickupPoints.some(item => item.id == el.id))
            });
          }
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
  }

  integrateData(): void {
    this.servicesFormGroup.patchValue({
      name: this.currentService.name,
      code: this.currentService.code,
      isDeleted: this.currentService.isDeleted,
      mPassId: this.currentService.mPassId,
      hasDelivery: this.currentService.hasDelivery
    });
  }

  submit(): void {
    this.servicesFormGroup.markAllAsTouched();
    if (!this.servicesFormGroup.invalid) {
      const servFinal: ServiceModel = { ...this.currentService, ...this.servicesFormGroup.value } as ServiceModel;

      servFinal.hasDelivery = servFinal.hasDelivery == true ? true : false;

      this.inRequest = true;
      this.servicesService.createOrUpdateService(servFinal, this.spId)
        .subscribe(() => {
          this.feedbackService.handleSuccess('save-service-data-success');
          this.inRequest = false;
          this.router.navigate(['/admin/sp/details', this.spId]);
        }, (err: HttpErrorResponse) => {
          this.inRequest = false;
          this.feedbackService.handleError(err, this.servicesFormGroup);
        });
    }
  }
}
