import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthModel, ContextModel } from '../models';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly userKey = 'user';
  private readonly contextKey = 'selectedContext';
  private userSubject: BehaviorSubject<AuthModel> = new BehaviorSubject<AuthModel>(null as unknown as AuthModel);
  private selectedContextSubject: BehaviorSubject<ContextModel> = new BehaviorSubject<ContextModel>(null as unknown as ContextModel);
  public isAutenticated$ = this.userSubject.asObservable();
  public contextIsSelected$ = this.selectedContextSubject.asObservable();

  constructor(
    public appConfigService: AppConfigService,
    private http: HttpClient,
  ) { }

  loadCurrentUser(): void {
    const api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;
    this.http.get<any>(`${api}/pub/me`).subscribe((user: any) => {
      if (user && user.isAuthenticated) {

        // Iterate over the user's boards and set the profileDecoded property
        if (user.user.boards) {
          user.user.boards.forEach((board: ContextModel) => {
            // Encode the profile property
            board.properties.profile = encodeURIComponent(board.properties.profile);
            // Set the profileDecoded property
            board.properties.profileDecoded = decodeURIComponent(board.properties.profile);
          });
        }

        localStorage.setItem(this.userKey, JSON.stringify(user));
        this.userSubject.next(user);
      } else {
        this.sessionLogout(user);
      }
    }, () => {
      this.sessionLogout(null as unknown as AuthModel);
    });
  }

  sessionLogout(user: AuthModel): void {
    this.logoutCurrentUser();
    this.userSubject.next(user);
    this.selectedContextSubject.next(null as unknown as ContextModel);

  }

  logoutCurrentUser(): void {
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.contextKey);

  }

  getCurrentUser(): any {
    return this.userSubject.value;
  }

  getCurrentContext(): ContextModel | null {
    return this.selectedContextSubject.value;
  }

  loadUser(): void {
    const user = localStorage.getItem(this.userKey);
    if (user) {
      this.userSubject.next(JSON.parse(user));
    }
  }

  setContext(context: ContextModel, doUpdate = true): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;
    localStorage.setItem('contextProfile', context.properties.profile);
    return this.http
      .put<any>(`${api}/pub/board/${context.properties.idn}/select`, context.properties.idn)
      .pipe(tap(
        res => {
          if (res.success) {
            localStorage.setItem(this.contextKey, JSON.stringify(context));
            if (doUpdate) {
              this.selectedContextSubject.next(context);
            }
          }
        })
      );
  }

  getUser(): Observable<object> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/pub/me`);
  }

  getLoginUrl(returnUrl = this.appConfigService.appConfig.APP_BASE_URL): Observable<string> {
    let api = this.appConfigService.appConfig.SERVER_URL;
    const body = { returnUrl: window.location.href };
    return this.http.post(`${api}/api/v1.0/pub/account/login`, body, { responseType: 'text' });
  }
}
