<header>
   <div class="navbar-top bg-gradient">
      <div class="page-wrapper container-fluid">
         <div class="d-flex align-items-center justify-content-between">
            <a
               target="_blank"
               href=" https://gov.md"
               class="top-brand text-white d-flex align-items-center flex-no-wrap"
            >
               <img
                  src="./../../../assets/images/logo-gov.png"
                  class="mr-2"
                  width="21"
                  alt="Guvernul Republicii Moldova"
               />
               <span>{{ translatePath + 'government' | translate | uppercase }}</span>
            </a>
            <ul class="nav-nav d-inline-flex ml-auto">
               <li class="nav-item dropdown" *ngIf="currentLang">
                  <a
                     class="nav-link lang dropdown-toggle text-white"
                     href=""
                     id="navbarDropdown"
                     role="button"
                     data-toggle="dropdown"
                     aria-haspopup="true"
                     aria-expanded="false"
                  >
                     <i class="fas fa-globe mr-2"></i> {{ currentLang | language }}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a class="dropdown-item" (click)="switchLang(item)" *ngFor="let item of langs">{{
                        item | language
                     }}</a>
                  </div>
               </li>
               <li class="nav-item" *ngIf="isAuthenticated">
                  <div class="col">
                     <div ngbDropdown class="d-inline-block">
                        <a class="nav-link lang" id="userMenu" ngbDropdownToggle>
                           <i class="fas fa-users mr-2"></i>
                           <span class="text-white">{{ activeContext?.properties?.name }}</span>
                        </a>
                        <div
                           color="primary"
                           ngbDropdownMenu
                           aria-labelledby="userMenu"
                           class="dropdown-menu context-menu"
                        >
                           <app-select-context-list></app-select-context-list>
                        </div>
                     </div>
                  </div>
               </li>
               <li class="nav-item" *ngIf="!isAuthenticated">
                  <a (click)="login()" class="nav-link auth text-white">
                     <i class="fas fa-sign-out-alt mr-2"></i> {{ translatePath + 'signIn' | translate }}
                  </a>
               </li>
               <li class="nav-item" *ngIf="isAuthenticated">
                  <a (click)="logout()" class="nav-link auth text-white">
                     <i class="fas fa-sign-out-alt mr-2"></i> {{ translatePath + 'signOut' | translate }}
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
   <div class="bg-white">
      <div class="page-wrapper">
         <nav role="navigation" style="position: relative">
            <div id="menuToggle">
               <input type="checkbox" (click)="mobileActive()" />
               <span></span>
               <span></span>
               <span></span>
               <ul id="menu">
                  <li *ngIf="currentLang">
                     <a
                        class="links-mobile dropdown-toggle text-dark"
                        href=""
                        id=""
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <i class="fas fa-globe mr-2"></i> {{ currentLang | language }}
                     </a>
                     <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="lomks-mobile dropdown-item" (click)="switchLang(item)" *ngFor="let item of langs">{{
                           item | language
                        }}</a>
                     </div>
                  </li>

                  <li class="" *ngIf="!isAuthenticated">
                     <a (click)="login()" class="links-mobile text-dark">
                        <i class="fas fa-sign-out-alt mr-2"></i> {{ translatePath + 'signIn' | translate }}
                     </a>
                  </li>
                  <li class="" *ngIf="isAuthenticated">
                     <a (click)="logout()" class="links-mobile text-dark">
                        <i class="fas fa-sign-out-alt mr-2"></i> {{ translatePath + 'signOut' | translate }}
                     </a>
                  </li>
                <!-- <li class="pr-0 text-dark">
                     <a class="links-mobile pr-0" (click)="addDelivery()">{{
                      translatePath + 'add-delivery' | translate
                   }}</a>
                 </li> -->
                  <li class="nav-item d-flex align-items-center pr-0">
                     <a class="nav-link menu-auth pr-0 text-dark" href="public/integrated-service-providers">{{
                        translatePath + 'documents-delivery' | translate
                     }}</a>
                  </li>
                  <li class="pr-0 text-dark">
                     <a class="links-mobile pr-0 text-dark" [routerLink]="['/public', 'tracking', 'find']">{{
                        translatePath + 'track' | translate
                     }}</a>
                  </li>
               </ul>
            </div>
         </nav>

         <nav class="navbar navbar-expand-lg bg-white px-3 align-items-stretch">
            <a class="navbar-brand py-3 d-flex" [routerLink]="['/public']">
               <img src="./../../../assets/images/logo-mdelivery-header.svg" class="img-fluid" />
               <span class="brand-long-text mdelivery-text">{{ translatePath + 'name' | translate }}</span>
            </a>
            <div class="navbar-links d-flex ml-auto" id="navbarSupportedContent">
               <ul class="navbar-nav d-flex justify-content-end mb-0">
                  <li class="nav-item d-flex align-items-center pr-0">
                     <a class="nav-link menu-auth pr-0 text-dark" [routerLink]="['/public', 'about-service']">{{
                        translatePath + 'about' | translate
                     }}</a>
                  </li>

                  <!-- <li class="nav-item d-flex align-items-center pr-0">
                     <a class="nav-link menu-auth pr-0 text-dark" (click)="addDelivery()">{{
                        translatePath + 'add-delivery' | translate
                     }}</a>
                  </li> -->
                  <li class="nav-item d-flex align-items-center pr-0">
                     <a class="nav-link menu-auth pr-0 text-dark" href="public/integrated-service-providers">{{
                        translatePath + 'documents-delivery' | translate
                     }}</a>
                  </li>
                  <li class="nav-item d-flex align-items-center pr-0">
                     <a class="nav-link menu-auth pr-0 text-dark" [routerLink]="['/public', 'tracking', 'find']">{{
                        translatePath + 'track' | translate
                     }}</a>
                  </li>
               </ul>
            </div>
         </nav>
      </div>
   </div>
</header>
