// export class AppConfigModel {
//   langs!: Array<string>;
//   defaultLang!: string;
//   SERVER_URL!: string;
//   API_STRING!: string;
//   APP_BASE_URL!: string;
//   RECAPTCHA_KEY: string;
// }


export interface AppConfigModel {
  langs: Array<string>;
  defaultLang: string;
  SERVER_URL: string;
  APP_BASE_URL: string;
  production: boolean;
  useHash: boolean;
  hmr: boolean;
  API_STRING: string;
  RECAPTCHA_KEY: string;
}

export class AppConfigModel {
  langs: Array<string>;
  defaultLang: string;
  SERVER_URL: string;
  APP_BASE_URL: string;
  production: boolean;
  useHash: boolean;
  hmr: boolean;
  API_STRING: string;
  RECAPTCHA_KEY: string;
  constructor(appConfig?: AppConfigModel) {
    if (appConfig) {
      this.langs = appConfig.langs;
      this.defaultLang = appConfig.defaultLang;
      this.SERVER_URL = appConfig.SERVER_URL;
      this.APP_BASE_URL = appConfig.APP_BASE_URL;
      this.production = appConfig.production;
      this.useHash = appConfig.useHash;
      this.hmr = appConfig.hmr;
      this.API_STRING = appConfig.API_STRING;

      this.RECAPTCHA_KEY = appConfig.RECAPTCHA_KEY;
    } else {
      this.langs = [];
      this.defaultLang = '';
      this.SERVER_URL = '';
      this.APP_BASE_URL = '';
      this.API_STRING = '';
      this.production = false;
      this.useHash = false;
      this.hmr = false;
      this.RECAPTCHA_KEY = '';
    }
  }
}
