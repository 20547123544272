import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { FormBuilder, Validators } from '@angular/forms';
import { emailShippingValidator } from '../../validators/email.validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  @Input() deliveryId?: string | null = null;
  @Input() phone?: string | null = null;
  @Input() email?: string | null = null;
  @Input() submitted = false;
  @Output() isValidPersonalInfo: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangePersonalInfo: EventEmitter<any> = new EventEmitter<any>();

  translatePath = 'public.shipping-details.';

  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [CountryISO.Moldova, CountryISO.Romania];
  separateDialCode = true;

  constructor(public fb: FormBuilder) { }

  contactForm = this.fb.group({
    phone: ['', [Validators.required]],
    email: ['', [emailShippingValidator]],
  });

  ngOnInit(): void {
    if (this.phone || this.email) {
      this.contactForm.patchValue({
        email: this.email
      });
      this.updatePhoneField(this.phone || '');
    }

    this.contactForm.get('phone')?.valueChanges.subscribe((value) => {
      this.onChangePersonalInfo.emit(value?.e164Number);
    });
  }

  cssPhoneValidator(): any {
    return (this.contactForm.get('phone')?.invalid && this.contactForm.get('phone')?.touched) ? 'form-control is-invalid' : 'valid-phone form-control';
  }

  private updatePhoneField(phoneNumber: string): void {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedNumber) {
      const fullNumber = parsedNumber.formatInternational();
      this.contactForm.get('phone')?.setValue(fullNumber);
    }
  }

  isValid(): boolean {
    return this.contactForm.valid;
  }
}
