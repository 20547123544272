export interface ReportInterval {
  estimatedDeliveryIntervalStart: string;
  estimatedDeliveryIntervalEnd: string;
}

export interface Report {
  status?: number;
  totalDeliveryCountPerStatus?: number;
  deliveriesByProvider?: DeliveryByProvider[];
  totalSum?: number;
}

export interface DeliveryByProvider {
  providerServiceName: string;
  deliveriesByProviderService: DeliveryByProviderService[];
}

export interface DeliveryByProviderService {
  providerProduct: string;
  providerCount: number;
  serviceName?: string;
  serviceCount?: number;
}
export interface DeliveryReportPerDeliveryType {
  providerProduct: string;
  deliveryNationalCount: number;
  deliveryInternationalCount: number;
  allDeliveryCount: number;
}

export interface DeliveryReportPerProductType {
  providerProduct: string;
  providerProductAllCount: number
  countPerDeliveryCarrierName: Array<DeliveriesPerCarrier>;
}

export interface DeliveriesPerCarrier {
  deliveryCarrierName: string;
  deliveryCount: number;
}

export interface DeliveryReportPerCountryType {
  national: ReportPerCountryName[];
  international: ReportPerCountryName[];
}
export interface ReportPerCountryName {
  country: string;
  processingDeliveriesCount: number;
  deliveredDeliveriesCount: number;
  allDeliveriesCount: number;
}

export interface DeliveryReportPerDestinationType {
  national: ProductsPerCountryData[];
  international: ProductsPerCountryData[];
}

export interface ProductsPerCountryData {
  country: string;
  totalProductCount: number;
  productDetails: ProductDetails[];
}

export interface ProductDetails {
  deliveryProductCode: string;
  productCount: number;
}

export enum ReportStatus {
  Unknwon = 0,
  AwaitingPayment = 1,
  Paid = 2,
  Processing = 3,
  Cancelled = 4,
  Expired = 5,
  Ready = 6,
  AwaitingPickup = 7,
  Delivering = 8,
  Delivered = 9,
  Confirmed = 10,
  Problem = 11,
  Returning = 12,
  Returned = 13
}
