import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguagePipe } from './pipes/language.pipe';
import { FooterComponent } from './footer/footer.component';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgWizardModule } from 'ng-wizard';
import { ContactPersonInputsComponent } from './components/contact-person-inputs/contact-person-inputs.component';
import { NavigationListComponent } from './components/navigation-list/navigation-list.component';
import { RouterModule } from '@angular/router';
import { SelectContextModalComponent } from './select-context-modal/select-context-modal.component';

import { PaginationComponent } from './components/pagination/pagination.component';
import { GeneralViewDataComponent } from './components/general-view-data/general-view-data.component';
import { DisableConfirmModalComponent } from './components/disable-confirm-modal/disable-confirm-modal.component';
import { PickupPointsEditComponent } from './components/pickup-points/add-edit-pickup-point/pickup-points-edit.component';
import { PickupPointsComponent } from './components/pickup-points/pickup-points.component';
import { ProductAddEditComponent } from './components/products/product-add-edit/product-add-edit.component';
import { ProductsListComponent } from './components/products/products-list.component';
import { ServiceAddEditComponent } from './components/services/add-edit-service/service-add-edit.component';
import { ServicesComponent } from './components/services/services.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SelectContextListComponent } from './components/select-context-list/select-context-list.component';
import { ConfirmCancelModalComponent } from './components/confirm-cancel-modal/confirm-cancel-modal.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { NgxMaskModule } from 'ngx-mask';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaV3Module } from 'ng-recaptcha';
import { FilterPipe } from './pipes/filter.pipe';
import { DateTranslatePipe } from './pipes/date-translate.pipe';
import { ReportStatusPipe } from './pipes/report-status.pipe';
import {PopUpAlertComponent} from './components/pop-up-alert/pop-up-alert.component';
import {PersonalInfoComponent} from './components/personal-info/personal-info.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { ContactSupportInputsComponent } from './components/contact-support-inputs/contact-support-inputs.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LanguagePipe,
    FooterComponent,
    TruncatePipe,
    ContactPersonInputsComponent,
    ContactSupportInputsComponent,
    NavigationListComponent,
    SelectContextModalComponent,
    PaginationComponent,
    GeneralViewDataComponent,
    DisableConfirmModalComponent,
    PickupPointsComponent,
    PickupPointsEditComponent,
    ServiceAddEditComponent,
    ServicesComponent,
    ProductsListComponent,
    ProductAddEditComponent,
    SelectContextListComponent,
    ConfirmCancelModalComponent,
    ToasterComponent,
    SideMenuComponent,
    AddressFormComponent,
    AddressBlockComponent,
    FilterPipe,
    DateTranslatePipe,
    ReportStatusPipe,
    PopUpAlertComponent,
    PersonalInfoComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgWizardModule,
    NgMultiSelectDropDownModule,
    RouterModule,
    NgbToastModule,
    NgxMaskModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    NgxIntlTelInputModule

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    LanguagePipe,
    TruncatePipe,
    NgbModule,
    NgWizardModule,
    ContactPersonInputsComponent,
    ContactSupportInputsComponent,
    NavigationListComponent,
    PaginationComponent,
    GeneralViewDataComponent,
    DisableConfirmModalComponent,
    PickupPointsComponent,
    PickupPointsEditComponent,
    ServiceAddEditComponent,
    ServicesComponent,
    ProductsListComponent,
    ProductAddEditComponent,
    SelectContextListComponent,
    ConfirmCancelModalComponent,
    NgbToastModule,
    ToasterComponent,
    NgxMaskModule,
    SideMenuComponent,
    AddressFormComponent,
    AddressBlockComponent,
    RecaptchaModule,
    RecaptchaFormsModule,
    RecaptchaV3Module,
    FilterPipe,
    DateTranslatePipe,
    ReportStatusPipe,
    PopUpAlertComponent,
    PersonalInfoComponent
  ]
})
export class SharedModule { }
