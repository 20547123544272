import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '.';
import { PagedResponse, Pagination, Region, ZipCode } from '../models';
import { SelectableAddressField } from '../models/selectable-address-field.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/pub`;
  zipApi = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/adm`
  paginatorGenerator = new PaginationGeneratorService();
  constructor(
    public http: HttpClient,
    public appConfigService: AppConfigService
  ) { }

  getRegions() {
    return this.http.get<Array<SelectableAddressField>>(`${this.api}/geo/regions`);
  }

  getSettlements(regionId: number) {
    return this.http.get<Array<SelectableAddressField>>(`${this.api}/geo/${regionId}/settlements`);
  }

  getStreets(settlementId: number, regionId: number) {
    return this.http.get<Array<SelectableAddressField>>(`${this.api}/geo/${regionId}/${settlementId}/streets`);
  }

  sincronZipCode() {
    return this.http.get<any>(`${this.zipApi}/nomenclature/import-zip`);
  }
  getAllZipCode(queryParams: Pagination): Observable<PagedResponse<ZipCode>> {
    const params = this.paginatorGenerator.generatePagination(queryParams);
    return this.http.get<PagedResponse<ZipCode>>(`${this.zipApi}/ZipCodes/all-zip-codes`, { params });
  }
  getAllRegions(): Observable<Region[]> {
    return this.http.get<Region[]>(`${this.zipApi}/ZipCodes/all-regions`);
  }
  putZipCode(zipCode: ZipCode): Observable<ZipCode> {
    return this.http.put<ZipCode>(`${this.zipApi}/ZipCodes`, zipCode);
  }
  postZipCode(zipCode: ZipCode): Observable<ZipCode> {
    return this.http.post<ZipCode>(`${this.zipApi}/ZipCodes`, zipCode);
  }
  deleteZipCode(id: string): Observable<any> {
    return this.http.delete<any>(`${this.zipApi}/ZipCodes/${id}`);
  }

  search(zipCode: string, locality: string): Observable<PagedResponse<ZipCode>> {
    let params = new HttpParams()
    params = params.append('ZipCode', zipCode)
    params = params.append('Locality', locality)
    return this.http.get<PagedResponse<ZipCode>>(`${this.zipApi}/ZipCodes/searsh`, { params });
  }
}
