import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Order } from '../../service-provider/orders-list/model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Params } from '@angular/router';
import { SortColumn } from '../../service-provider/orders-list/sortable.directive';
import { AppConfigService } from '../../../shared/services';
import { PrintDeliveryDetailsCommand } from '../../../shared/models/delivery-details-print-model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private loading = new BehaviorSubject<boolean>(true);
  private search = new Subject<void>();
  private orders = new BehaviorSubject<Order[]>([]);
  private total = new BehaviorSubject<number>(0);

  callBack: any;

  states = [
    { state: 'All', style: false },
    { state: 'Awaiting Payment', style: false },
    { state: 'Paid', style: false },
    { state: 'Processing', style: false },
    { state: 'Cancelled', style: false },
    { state: 'Expired', style: false },
    { state: 'Ready', style: false },
    { state: 'Awaiting Pick Up', style: false },
    { state: 'Delivering', style: false },
    { state: 'Delivered', style: false },
    { state: 'Confirmed', style: false },
    { state: 'Problem', style: false },
    { state: 'Returning', style: false },
    { state: 'Returned', style: false },
    { state: 'Lost', style: false },
    { state: 'Unknown', style: false }
  ];

  state = {
    page: 1,
    pageSize: 4,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  sorting = [
    'pickUpDate', 'createDate', 'deliveryDate'
  ]

  public items: any;
  public totalCount: any;
  load = false;

  constructor(public http: HttpClient,
    public route: ActivatedRoute,
    public appConfigService: AppConfigService) {
  }

  get orders$(): object {
    return this.orders.asObservable();
  }

  get total$(): Observable<any> {
    return this.total.asObservable();
  }

  get loading$(): Observable<any> {
    return this.loading.asObservable();
  }

  get page(): any {
    return this.state.page;
  }

  get pageSize(): any {
    return this.state.pageSize;
  }

  get searchTerm$(): any {
    return this.state.searchTerm;
  }

  set sortColumn(sortColumn: SortColumn) {
    // tslint:disable-next-line:no-unused-expression
    sortColumn;
  }

  private set(param: {
    sortColumn: 'idMdelivery' | 'state' | 'idOrder' | 'idWaybill' | 'serviceProvider'
    | 'receiver' | 'expeditionLocation' | 'receiverLocation' | 'typeItem' | 'dateCreationOrder' |
    'dateOfPaymentOrder' | 'datePickUpAtCarrier' | 'dateOfDelivered' | 'carries' | 'visible' | ''
  }) {
  }

  getRegistredOrders(): any {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    let params: Params;
    params = this.route.snapshot.queryParams;
    this.load = true;
    if (params["searchByOrderId"] !== undefined) {
      params = { "searchBy": params["searchByOrderId"] } as Params;
    };
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider/orders`, { params });
  }

  getCarriersList(): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/adm/carrier`);
  }

  getSpList(pickUpPoint: string = '0'): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider?pickUpPoint=${pickUpPoint}`);
  }

  getPicUpPointList(pickUpPoint: string): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/pickup-point-code/${pickUpPoint}`);
  }

  getDestinationRegionCodes(): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/destination-region-codes`);
  }

  getProductCodes(productCode: string = '0'): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/product-codes/${productCode}`);
  }

  generateInvoice(mDeliveriesId: Array<string>): Observable<object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content - Type': 'application/pdf'
    });
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/adm/serviceprovider/generatemdeliverypdf`, { mDeliveriesId }, { responseType: 'blob' });
  }

  deliveryDetailstoPdf(printDeliveryDetails: PrintDeliveryDetailsCommand): Observable<object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content - Type': 'application/pdf'
    });
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/pub/delivery/PrintDeliveryDetails`, { printDeliveryDetails }, { responseType: 'blob' });
  }

  changeStatusToReady(deliveriesId: string[]): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/pub/delivery/changeStatus`, { deliveriesId });
  }

  changeStatusToCancel(deliveryId: string, reason: string): Observable<any> {
    const api = `${this.appConfigService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/pub/delivery/changeStatusToCancel`, { deliveryId, reason });
  }

  exportOrdersToCSV(): Observable<object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content - Type': 'application/csv'
    });
    const api = `${this.appConfigService.appConfig.SERVER_URL}`
    let params: Params;
    params = this.route.snapshot.queryParams;
    this.load = true;
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider/exportOrdersToCSV`, { params, responseType: 'blob' });
  }
}
