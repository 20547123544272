import { ContextModel } from "./context.model";

export class UserModel {
  firstName!: string;
  lastName!: string;
  userName!: string;
  emailAddress: string;
  mobilePhone: string;
  idnp: string;
  isActive: boolean;
  isDeleted: boolean;
  boards!: ContextModel[]
}
