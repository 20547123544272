<div class="modal-body p-3">
  <div>
    <h4 class="article-title text-center m-0"
        *ngIf="true">
      {{'general.generalModal.cancelConfirmMessage' | translate}}
    </h4>
  </div>
  <div class="d-flex justify-content-center">
    <button type="button" class="btn btn-secondary mt-3 ml-3 mr-3" data-dismiss="modal"
            (click)="activeModal.close('Close click')">
      {{'general.generalModal.cancelButton' | translate}}
    </button>
    <button type="button" class="btn btn-primary mt-3 ml-3 mr-3"
            (click)="activeModal.close('confirmed');">
      {{'general.generalModal.confirmButton' | translate}}
    </button>
  </div>
</div>
