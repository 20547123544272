import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { SideMenuItem } from '../../models';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  @Input() menuList: SideMenuItem[];
  constructor(
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  back(){
    this.location.back();
  }

}
