<div class="container-fluid">
    <div class="row">
        <div class="col mb-5 pb-5" style="position: relative;">
            <form [formGroup]="formGroup" [ngClass]="{'form-container': !loading}" class="d-flex flex-column">
                <nav class="navbar navbar-light navbar-expand-lg d-flex  flex-wrap">
                    <div class="d-flex justify-content-center nav-box box-state pt-2" style="position: relative">
                        <span [style.color]="processingDeliveriesIdArr.length === 0 ? '#DADADA' : 'black'"
                              class="reset-container__icon-state ml-3 mr-2">
                        </span>
                        <button (click)="onChangeStatus()"
                                [disabled]="processingDeliveriesIdArr.length === 0"
                                [ngClass]="{btn: processingDeliveriesIdArr.length === 0}"
                                class="nav-link__state text-center ml-1 mb-3">
                            {{'admin.table.changeStatus' | translate}}
                        </button>
                        <table *ngIf="changeStatus" class="table-col d-flex justify-content-center align-items-center"
                               style="overflow: hidden; max-height: 80px;">
                            <tbody>
                                <tr (click)="changeStatuses('Ready')" class="nav-link d-flex justify-content-center align-items-center">
                                    <td>
                                        <span class="nav-link pl-xl-5">
                                            <span>
                                                <i class="bi bi-arrow-right"></i>
                                            </span>{{'Ready' | translate}}
                                        </span>
                                    </td>
                                </tr>
                                <tr (click)="changeStatuses('Returned')" class="nav-link d-flex justify-content-center align-items-center">
                                    <td>
                                        <span class="nav-link pl-xl-5">
                                            <span>
                                                <i class="bi bi-arrow-right"></i>
                                            </span>{{'Returned' | translate}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="form-date">
                        <div class="d-flex justify-content-center nav-box" style="position: relative">
                            <span (click)="onSorter()" class="nav-link text-center ml-5">
                                <i class="bi bi-filter"></i>{{sorting ? (sorting | translate) : ('sorting' | translate)}} <i class="bi bi-arrow-down-short ml-1 mb-1"></i>
                            </span>
                            <table *ngIf="sorter" class="table-col ml-5">
                                <tbody>
                                    <tr (click)="sort(item)" *ngFor="let item of this.service.sorting; let i = index; let odd = odd"
                                        [ngClass]="{'ecedef': false, 'f5f6f7':  false }"
                                        class="nav-link d-flex justify-content-center align-items-center">
                                        <td>
                                            <span class="nav-link"><span><i class="bi bi-filter"></i></span>  {{item | translate }} </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <form class="form-inline">

                            <div class="form-group hidden">
                                <div class="input-group" style="position: absolute; top: 49%; right: 53%; width: 485px">
                                    <input #datepicker="ngbDatepicker"
                                           (dateSelect)="onDateSelection($event)"
                                           [autoClose]="'inside'"
                                           [dayTemplate]="t"
                                           [displayMonths]="2"
                                           [maxDate]="{year: 2025, month: 12, day: 31}"
                                           [minDate]="{year: 2019, month: 1, day: 1}"
                                           [startDate]="fromDate!"
                                           class="form-control calendar-date"
                                           name="datepicker"
                                           ngbDatepicker
                                           outsideDays="hidden"
                                           style="opacity: 0; left: 30%; width: 500px; z-index: -1"
                                           tabindex="-1" />
                                    <ng-template #t let-date let-focused="focused">
                                        <span (mouseenter)="hoveredDate = date"
                                              (mouseleave)="hoveredDate = null"
                                              [class.faded]="isHovered(date) || isInside(date)"
                                              [class.focused]="focused"
                                              [class.range]="isRange(date)"
                                              class="custom-day">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row">
                                <span (click)="datepicker.toggle()" class="form-date__calendar col-md-1">
                                    <img src="./../../../../assets/images/calendar.svg " />
                                </span>
                                <div class="form-group col-md-4 ml-2">
                                    <div class="input-group fs-1">
                                        <input #dpFromDate
                                               (click)="datepicker.toggle()"
                                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                                               [formControl]="from"
                                               [value]="formatter.format(fromDate)"
                                               class="form-control"
                                               name="dpFromDate"
                                               placeholder="aaaa-ll-zz" />
                                        <div class="input-group-append"></div>
                                    </div>
                                </div>
                                <div class="form-group ml-2 col-md-4">
                                    <div class="input-group">
                                        <input #dpToDate
                                               (click)="datepicker.toggle()"
                                               (input)="toDate = validateInput(toDate, dpToDate.value)"
                                               [formControl]="to"
                                               [value]="formatter.format(toDate)"
                                               class="form-control"
                                               name="dpToDate"
                                               placeholder="aaaa-ll-zz" />
                                        <div class="input-group-append"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="search-block d-flex ml-2">
                        <textarea class="search-block__input form-control ml-3 mr-3"
                                  formControlName="searchField"
                                  name="searchTerm"
                                  placeholder="{{'admin.placeholder' | translate}}"
                                  type="text"></textarea>

                        <button (click)="getField()"
                                (click)="getRegistredOrders()"
                                (keyup.enter)="getField()"
                                (keyup.enter)="getRegistredOrders()"
                                class="search-block__button">
                            {{ 'admin.buttons.search' | translate }}
                        </button>
                    </div>
                    <div (click)="resetAllFields()" class="reset-container d-flex">
                        <span class="reset-container__icon"><i class="bi"></i></span>
                        <button class="reset-container__button">{{'admin.buttons.reset' | translate}}</button>
                    </div>
                    <div class="print-container mb-2 ml-1">
                        <button [disabled]="isExport" class="btn-awb" data-toggle="tooltip" title="Print AWB">
                            <span (click)="exportData()" class="print-container__icon"><i class="bi bi-printer"></i></span>
                        </button>
                        <button (click)="generateInvoice()" [disabled]="pdfBtn || isExport" class="btn-awb" data-toggle="tooltip"
                                title="Print Borderou">
                            <span class="print-container__pdf"><i class="bi bi-file-earmark-richtext"></i> </span>
                        </button>
                        <button (click)="exportOrdersToCSV()" class="btn-awb" data-toggle="tooltip" title="Expot to csv">
                            <span class="print-container__pdf">
                                <img class="file-csv" src="./../../../../assets/images/filetype-csv.svg">
                            </span>
                        </button>
                    </div>
                    <div class="form form-group mt-2 ml-3">
                        <span (click)="toggleBtn()">
                            <button class="form-columns" type="button">{{'admin.table.show' | translate }}</button>
                        </span>
                        <div *ngIf="show" class="table-columns" style="background-color: white; opacity: 1; z-index: 99">
                            <table class="table-columns__items table">
                                <tbody>
                                    <tr *ngFor="let c of columns; let i = index">
                                        <td (click)="selectFilter(i, $event.target)"
                                            [ngClass]="{itemsTd: true, items: c.visible, 'items-gree': false, selected: c.style}">
                                            <span [ngClass]="{itemsTd: true}"> {{ c.type | translate }} </span>
                                            <span>{{ c.types | translate }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </nav>

                <div style="position: relative">
                    <p *ngIf="loading" class="progress-bar-custom" style="max-width: 289px">
                        <ngb-progressbar [animated]="true"
                                         [showValue]="false"
                                         [value]="valueBar"
                                         id="bar"
                                         textType="white"
                                         type="info"></ngb-progressbar>
                        <span *ngIf="valueChecking" class="checkbox-customs">
                            <input [checked]="true" class="checkbox-custom" id="checkbox-2" name="checkbox-2"
                                   type="checkbox">
                            <label class="checkbox-custom-label" for="checkbox-2"></label>
                        </span>


                    </p>

                </div>

                <div class="nav-container" id="navContainer">
                    <div>
                        <table class="table table-striped">
                            <thead>
                                <tr class="styles-headers">
                                    <th (sort)="onSort($event)"
                                        appSortable="receiver"
                                        class="check-background"
                                        scope="col"
                                        style="padding: 0px 0px 30px 11px; width: 30px">
                                        <input (change)="checkedUncheckedAll($event)"
                                               [checked]="checked"
                                               style="min-width: 15px; height: 15px"
                                               type="checkbox" />
                                    </th>

                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[0].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 130px">
                                        <span>{{ columns[0].type | translate }}</span> <br />
                                        <span>{{ columns[0].types | translate }}</span>
                                    </th>

                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[1].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col">
                                        <span>{{ columns[1].type | translate }}</span> <br />
                                        <span>{{ columns[1].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[2].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 150px">
                                        <span>{{ columns[2].type | translate }}</span> <br />
                                        <span>{{ columns[2].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[3].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 150px">
                                        <span>{{ columns[3].type | translate }}</span> <br />
                                        <span>{{ columns[3].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[4].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="padding-bottom: 31.9px">
                                        <span>{{ columns[4].type | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[5].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="padding-bottom: 31.9px">
                                        <span>{{ columns[5].type | translate }}</span>
                                    </th>

                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[6].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 120px">
                                        <span>{{ columns[6].type | translate }}</span> <br />
                                        <span>{{ columns[6].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[7].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="max-width: 120px">
                                        <span>{{ columns[7].type | translate }}</span> <br />
                                        <span>{{ columns[7].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[8].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 120px">
                                        <span>{{ columns[8].type | translate }}</span> <br />
                                        <span>{{ columns[8].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[9].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="max-width: 75px">
                                        <span>{{ columns[9].type | translate }}</span> <br />
                                        <span>{{ columns[9].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event,'estimatedDeliveryStart')"
                                        *ngIf="columns[10].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="max-width: 75px">
                                        <span>{{ columns[10].type | translate }}</span> <br />
                                        <span>{{ columns[10].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event,'estimatedDeliveryEnd')"
                                        *ngIf="columns[11].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="max-width: 75px">
                                        <span>{{ columns[11].type | translate }}</span> <br />
                                        <span>{{ columns[11].types | translate }}</span>
                                    </th>
                                    <th (sort)="onSort($event)"
                                        *ngIf="columns[12].visible"
                                        appSortable="state"
                                        class="thead-class"
                                        scope="col"
                                        style="width: 100px; padding-bottom: 31.9px">
                                        <span>{{ columns[12].type | translate }}</span>
                                    </th>
                                </tr>
                            </thead>

                            <thead class="filter-header">
                                <tr>
                                    <th></th>
                                    <th *ngIf="columns[0].visible"></th>
                                    <th *ngIf="columns[1].visible" style="position: relative; width: 130px">
                                        <img (click)="openStatesFilter()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="statusFilter" class="filter-state">
                                            <table>
                                                <tbody>
                                                    <tr #state *ngFor="let item of service.states; let i = index">
                                                        <td (click)="getStateParam(item.state, item, i)"
                                                            [ngClass]="{'items-gree': false, selected: item.style}"
                                                            class="filter-state-td">
                                                            {{ item.state | translate }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                    <th *ngIf="columns[2].visible"></th>
                                    <th *ngIf="columns[3].visible"></th>
                                    <th *ngIf="columns[4].visible" style="position: relative; width: 100px; padding-left: 5px">
                                        <img (click)="openFilterSp()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="filterSp" class="filter-sp">
                                            <table>
                                                <tbody>
                                                    <tr *ngFor="let sp of spList; let i = index">
                                                        <td (click)="getFilterBySp(sp.name, sp, i)"
                                                            [ngClass]="{'items-gree': columns.style, selected: sp.style}"
                                                            class="filter-sp-td">
                                                            {{ sp.name}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                    <th *ngIf="columns[5].visible"></th>
                                    <th *ngIf="columns[6].visible" style="position: relative; width: 100px; padding-left: 5px">
                                        <img (click)="openPickUpPointFilter()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="pickUpPointFilter" class="filter-pickUpPoint">
                                            <table>
                                                <tbody>
                                                    <tr *ngFor="let pickUpPoint of pickUpPointItems; let i = index let e = even">
                                                        <td (click)="getFilterByPickUpPoint(pickUpPoint.pickupPointCode,pickUpPoint,i)"
                                                            [ngClass]="{'items-gree': e}"
                                                            class="filter-pickUpPoint-td">
                                                            {{pickUpPoint.name + ' (' + pickUpPoint.code + ')'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                    <th *ngIf="columns[7].visible" style="position: relative; width: 100px; padding-left: 5px">
                                        <img (click)="openFilterDestination()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="destinationFiltre" class="filter-destination">
                                            <table>
                                                <tbody>
                                                    <tr *ngFor="let item of destinationItems; let i = index; let e = even">
                                                        <td (click)="filtreByDestination(item,i)" [ngClass]="{'items-gree': e}"
                                                            class="filter-destination-td">
                                                            <span>{{ item }}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                    <th *ngIf="columns[8].visible" style="position: relative">
                                        <img (click)="openProductFilter()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="productFilter" class="filter-product">
                                            <table>
                                                <tbody>
                                                    <tr *ngFor="let item of productItems; let i = index; let e = even">
                                                        <td (click)="filterByProduct(item,i)"
                                                            [ngClass]="{'items-gree': e}"
                                                            class="filter-product-td">
                                                            {{item | translate}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                    <th *ngIf="columns[9].visible"></th>
                                    <th *ngIf="columns[10].visible"></th>
                                    <th *ngIf="columns[11].visible"></th>
                                    <th *ngIf="columns[12].visible" style="position: relative">
                                        <img (click)="openCarrierFilter()"
                                             src="./../../../../assets/images/down-arrow-of-angle-svgrepo-com.svg" />
                                        <div *ngIf="carrierFilter" class="filter-carrier">
                                            <table>
                                                <tbody>
                                                    <tr *ngFor="let carrier of carriersList; let i = index; let e = even">
                                                        <td (click)="getCarrierName(carrier.name, carrier)"
                                                            [ngClass]="{'items-gree': e, selected: carrier.style}"
                                                            class="filter-carrier-td">
                                                            {{ carrier.name | translate}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let order of items; let ind = index" class="table-tr-body">
                                    <td class="select-input" style="padding: 11px 0px 0px 10px">
                                        <input (change)="getOrderDetails($event, order, ind)"
                                               [checked]="this.checked"
                                               [disabled]="order.statusUp"
                                               class="select-item"
                                               type="checkbox" />
                                    </td>
                                    <td (click)="getDetails(order.deliveryId)"
                                        *ngIf="visibility[0].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        scope="row">
                                        {{ order.deliveryId }}
                                    </td>
                                    <td *ngIf="visibility[1].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        style="padding-left: 0">
                                        <ngb-highlight [ngStyle]="order.color" [result]="order.status | translate"></ngb-highlight>
                                    </td>
                                    <td *ngIf="visibility[2].visible" [ngClass]="{'select-bold': order.checked}">
                                        {{ order.service.orderCode }}
                                    </td>
                                    <td *ngIf="visibility[3].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        style="padding-left: 5px; padding-right: 5px">
                                        <ngb-highlight [result]="order.externalWaybillNumber"
                                                       [term]="service.searchTerm$"></ngb-highlight>
                                    </td>
                                    <td *ngIf="visibility[4].visible" [ngClass]="{'select-bold': order.checked}">
                                        <ng-container>{{ order.spName }}</ng-container>
                                    </td>
                                    <td *ngIf="visibility[5].visible" [ngClass]="{'select-bold': order.checked}">
                                        <ngb-highlight [result]="
                                    order.deliveryContactPerson.firstName + ' ' + order.deliveryContactPerson.lastName
                                 "
                                                       style="padding: 0px 11px"></ngb-highlight>
                                    </td>
                                    <td *ngIf="visibility[6].visible" [ngClass]="{'select-bold': order.checked}">
                                        <ngb-highlight [result]="order.pickupPointCode"></ngb-highlight>
                                    </td>
                                    <td *ngIf="visibility[7].visible" [ngClass]="{'select-bold': order.checked}">
                                        <ngb-highlight [result]="order?.deliveryAddress?.city?.value"></ngb-highlight>
                                        <ngb-highlight [result]="order?.internationalDeliveryAddress?.country"></ngb-highlight>

                                    </td>
                                    <td *ngIf="visibility[8].visible" [ngClass]="{'select-bold': order.checked}">
                                        <ng-container *ngIf="order.products.length > 0">
                                          {{ order.products[0].code }}
                                          <span *ngIf="order.products.length > 1">...</span>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="visibility[9].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        class="date-table">
                                        {{ getStatusDate(order, 'paid', 'Paid') | date: 'dd-MM-yyyy' }}
                                    </td>
                                    <td *ngIf="visibility[10].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        class="date-table">
                                        {{ getStatusDate(order, 'shipped', 'Delivering') | date: 'dd-MM-yyyy' }}
                                    </td>
                                    <td *ngIf="visibility[11].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        class="date-table">
                                        {{ getStatusDate(order, 'delivered', 'Delivered') | date: 'dd-MM-yyyy' }}
                                    </td>
                                    <td *ngIf="visibility[12].visible"
                                        [ngClass]="{'select-bold': order.checked}"
                                        style="padding-right: 11px">
                                        <ngb-highlight [result]="order.carrier.name"></ngb-highlight>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div *ngIf="context?.includes('Administrator')" (click)="updateStatuses()" class="reset-container d-flex">
                            <span class="reset-container__icon"><i class="bi"></i></span>
                            <button class="reset-container__button" [disabled]="syncronizingOrders" style="width: 100px!important;">
                             {{'admin.buttons.update' | translate}}
                            </button>
                        </div>
                    </div>
                </div>
                <span class="prev">{{'admin.carrier.labels.command' | translate}}  {{totalCount}}  {{'admin.carrier.labels.order' | translate}}</span>
                <div *ngIf="totalCount" class="d-flex justify-content-center p-2">
                    <ngb-pagination (click)="getRegistredOrders()"
                                    (pageChange)="pageDataChanged($event)"
                                    [(page)]="currentPage"
                                    [collectionSize]="totalCount"
                                    [maxSize]="5"
                                    [pageSize]="itemsPerPage">

                        <ng-template ngbPaginationPrevious>
                            <span class="prev">{{'admin.pagination.previous' | translate}}</span> <span class="page-prev"><<</span>
                        </ng-template>
                        <ng-template ngbPaginationNext>
                            <span class="page-next">>></span><span class="next">{{'admin.pagination.next' | translate}}</span>
                        </ng-template>
                    </ngb-pagination>

                    <select #val
                            (input)="changePagesize(val.value)"
                            (input)="getRegistredOrders()"
                            [(ngModel)]='itemsPerPage'
                            class="custom-select"
                            [ngModelOptions]="{standalone: true}"
                            style="width: auto; margin-left: 11px">
                        <option *ngFor="let num of sizePage; let i = index">{{ num }}</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
</div>
