import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IdeliveryAddress} from '../interface/idelivery-address';
import {DeliveryDetailsModel} from '../models/delivery-details.model';
import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDetailsService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;
  constructor(
    public http: HttpClient,
    public appConfigService: AppConfigService
  ) {
  }

  getDeliveryDetailsById(id: string): Observable<DeliveryDetailsModel> {
    return this.http.get<any>(`${this.api}/pub/delivery/${id}`);
  }

  getDeliveryTrackingDetailsById(id: string): Observable<DeliveryDetailsModel> {
    return this.http.get<any>(`${this.api}/pub/delivery/${id}/tracking`);
  }

  SyncStatusById(id: string): Observable<DeliveryDetailsModel> {
    return this.http.get<any>(`${this.api}/pub/delivery/${id}/SyncStatus`);
  }

  ResetAwbJobStatusById(id: string): Observable<boolean> {
    return this.http.patch<any>(`${this.api}/adm/awbjob`, {deliveryId: id});
  }

  putDelivery(deliveryAddres: IdeliveryAddress, isLocal: boolean): Observable<string> {
    return this.http.put<any>(`${this.api}/pub/delivery`, {
      Identifier: deliveryAddres.identifier,
      Address: isLocal ? deliveryAddres : null,
      InternationalAddress: isLocal ? null : deliveryAddres
    });
  }

  putNumber(deliveryId: string, phone: string): Observable<any> {
    return this.http.patch(`${this.api}/adm/contactperson/change-phone-number`, {
      deliveryId, phone
    });
  }
}
