import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZipCode } from '../models';
import { SelectableAddressField } from '../models/selectable-address-field.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/pub`;
  constructor(
    public http: HttpClient,
    public appConfigService: AppConfigService
  ) { }

  getCountries(): Observable<Array<SelectableAddressField>> {
    return this.http.get<Array<SelectableAddressField>>(`${this.api}/dictionaries/locations`);
  }

  getZipCodes(): Observable<Array<ZipCode>> {
    return this.http.get<Array<ZipCode>>(`${this.api}/dictionaries/zip-codes`);
  }

  checkZipCode(zip: string,regionId:string) {
    return this.http.patch(`${this.api}/dictionaries/zip-codes/${zip}/${regionId}`,'');
  }

}
