<div class="page-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 py-3">
       <div class="ml-3">
         <app-navigation-list [navigationList]="navList"></app-navigation-list>
       </div>
        <div class="parent bg-white mb-4 col-12 mr-auto ml-auto">
          <i *ngIf="loading" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <form class="text-left" [formGroup]="servicesFormGroup">
            <div class="blocks d-flex justify-content-center mr-auto ml-auto">
              <div class=" cod form-group col-6">
                <label for="code"
                       [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('code'))}">{{'general.services.labels.serviceId' | translate}}
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="code"
                       [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('code')) , 'is-invalid': servicesFormGroup.get('code')?.invalid  && servicesFormGroup.get('code')?.touched,
                        'is-valid': servicesFormGroup.get('code')?.valid }"
                       formControlName="code" aria-describedby="code" placeholder="Ex: 7362873829403284">
              </div>
              <div class=" name form-group col-6">
                <label for="name"
                       [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('name')) }">{{'general.services.labels.name' | translate}}
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="name"
                       [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('name')), 'is-invalid': servicesFormGroup.get('name')?.invalid  && servicesFormGroup.get('name')?.touched,
                        'is-valid': servicesFormGroup.get('name')?.valid }"
                       formControlName="name" aria-describedby="name" placeholder="{{'general.pickUpPoints.labels.custom-name' | translate}}">
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center flex-column align-items-center">
              <div class="form-group col-12">
                <label for="pickupPointID"
                  [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('pickupPoints')) }">{{'general.typeOfProducts.labels.pickupPoint' | translate}}
                  <span class="text-danger">*</span></label>
                <ng-multiselect-dropdown
                  [ngClass]="{'invalid-select' : errorCheckerUtil.isInvalid(servicesFormGroup.get('pickupPoints'))}"
                  formControlName="pickupPoints" [settings]="getSetting()" [data]="dropdownList"
                  id="pickupPointID" placeholder="{{'choose-pickup-point' | translate}}" (click)="getSetting()">
                </ng-multiselect-dropdown>
              </div>
              <div class="form-group col-12">
                <label for="mPassId"
                  [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('mPassId')) }">{{'general.services.labels.clientId' | translate}}
                  <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="mPassId"
                  [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('mPassId')) , 'is-invalid': servicesFormGroup.get('hasDelivery')?.invalid  && servicesFormGroup.get('hasDelivery')?.touched,
                'is-valid': servicesFormGroup.get('hasDelivery')?.valid }"
                  formControlName="mPassId" aria-describedby="mPassId" placeholder="Ex: ID Mpass">
              </div>
            </div>

            <div class="form-group  col-12 d-flex mb-5 mt-3 ml-2 pl-4">
              <label class="mt-1 mr-3" for="hasDelivery">{{'general.services.labels.has-delivery' | translate}}

              </label>

              <input type="checkbox" class="form-group-control" id="hasDelivery"
                     formControlName="hasDelivery" aria-describedby="hasDelivery">
              <label for="hasDelivery" [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(servicesFormGroup.get('hasDelivery')) }">
                <span style="display: none">{{'general.services.labels.clientId' | translate}}</span>
              </label>
            </div>


            <hr>


            <div class="buttons-group col-12 d-flex  justify-content-xl-end justify-content-center ml-auto mr-auto">
              <button type="button" class="btn btn-outline-info mr-2"
                [routerLink]="['/admin/sp/details', spId]">{{'admin.buttons.cancelButton' | translate}}</button>
              <button type="submit" class="btn btn-outline-info"
                (click)="submit()" [disabled]="inRequest">{{'admin.buttons.saveButton' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

