import { DeliveryEnum, DestinationEnum, IdentifierEnum, ProductEnum, RegionEnum } from "../enums"

export class CarrierSettings { //TODO Remove this calss and use Tariff instead
  productType!: ProductEnum;
  length!: number;
  height!: number;
  width!: number;
  weight!: number;
  deliveryType!: DeliveryEnum;
  destinationType!: DestinationEnum;
  regionType!: RegionEnum;
  identifierType!: IdentifierEnum;
  serviceId!: string;
  price!: string;
  estimatedStart!: number;
  estimatedEnd!: number;
}
