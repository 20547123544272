<ol class="breadcrumb justify-content-start bg-white pt-5" aria-label="breadcrumb">
  <div class="delivery-header d-flex justify-content-center ml-xl-3">
    <div class=" d-flex justify-content-end ml-xl-2" *ngFor="let item of navigationList">
      <button *ngIf="item.link" class="btn btn-outline-info btn-sm order-0" [routerLink]="[item.link]"><i class="bi bi-arrow-left mr-1 mb-1"></i>{{'buttons.back' | translate}}</button>
    </div>
<!--    <div class="d-flex align-items-center mt-1 ml-4">-->
<!--      <svg width="1" height="16" viewBox="0 0 1 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <rect width="1" height="16" fill="#CED1D6"/>-->
<!--    </svg>-->
<!--    </div>-->
  </div>
  <div class="d-flex ml-4  mt-xl-0 mt-md-0">
    <li class="item d-flex align-items-center" *ngFor="let item of navigationList let i = index">
      <a class="mr-3"  [routerLink]="item.url">{{item.translatedText | translate | uppercase   }}</a>
    </li>
  </div>
</ol>
