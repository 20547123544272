import { DeliveryEnum, DestinationEnum, IdentifierEnum, ProductEnum, RegionEnum, ServiceTypeEnum } from "../enums"
import { AddressModel } from "./address.model";

export class Tariff {
  id!: string;
  carrierId!: string;
  carrierName: string;
  productType!: ProductEnum;
  length!: number;
  width!: number;
  height!: number;
  weight!: number;
  deliveryType!: DeliveryEnum;
  destinationType!: DestinationEnum;
  regionType!: RegionEnum;
  identifierType!: IdentifierEnum;
  serviceId!: string;
  price!: number;
  estimatedStart!: Date;
  estimatedEnd!: Date;
  isDeleted!: boolean;
  serviceType: ServiceTypeEnum;
  address: AddressModel;
  carrierLogo: string;
  carrierWebURL: string;
}
