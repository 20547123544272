import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewChecked, AfterViewInit, Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, OperatorFunction, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, map, takeUntil, takeWhile } from 'rxjs/operators';
import { ISelectableAddressField, SelectableAddressField } from '../../models/selectable-address-field.model';
import { FeedbackService, LocationService } from '../../services';
import { CountryManagerService } from '../../services/country-manager.service';
import { GeoService } from '../../services/geo.service';
import { provinceCodeValidator } from 'src/app/shared/validators/provinceCode.validator';
import {
  specialApartmentCaracterValidator,
  specialBlockCaracterValidator,
  specialStreetCaracterValidator
} from '../../validators/email.validator';
import { ShippingService } from '../../../public-module/shipping/shipping.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, AfterViewInit, AfterViewChecked, DoCheck {
  private findItem: any;

  constructor(
    public fb: FormBuilder,
    public locationService: LocationService,
    public geoService: GeoService,
    public feedbackService: FeedbackService,
    public router: Router,
    public countrySvc: CountryManagerService,
    private shippingService: ShippingService
  ) {
  }

  @Input() isAdminEdit = false;
  zipValidating: boolean;
  gettingRegions: boolean;
  gettingSettlements: boolean;
  gettingStreets: boolean;
  checkShippment = false;
  checkRouting: boolean;
  countryList: any;
  path: boolean;
  @Input() checking = true;
  @Input() isLocal = false;
  @Input() editStatus!: boolean;
  @Output() checkShipp = new EventEmitter();
  @Input() addressForm: FormGroup = this.fb.group({
    country: ['', Validators.required],
    region: [null, Validators.required],
    provinceCode: ['', [provinceCodeValidator.cannotContainSpace]],
    city: ['', Validators.required],
    street: ['', specialStreetCaracterValidator],
    block: ['', specialBlockCaracterValidator],
    apartment: ['', specialApartmentCaracterValidator],
    zipCode: ['', [Validators.required]],
  });

  countries!: Array<SelectableAddressField>;
  defaultCountry: SelectableAddressField;

  translatePath = 'public.shipping-details.';
  firstInit = true;

  regions: SelectableAddressField[];
  provinceCodes: SelectableAddressField[];
  settlements: SelectableAddressField[];
  streets: SelectableAddressField[];

  addressValidatorHandle = false;
  isCustomValidationRequired: boolean = false;
  private notifier = new Subject();

  ngOnInit(): void {
    sessionStorage.clear();
    this.router.url.includes('/public/shipping') ? this.path = true : this.path = false;

    this.getCountries();
    this.handleFormStatus();
    this.formSubscribe();
    this.getRegions();
    this.internationalDeliveryCountry();
  }

  formatter = (state: SelectableAddressField) => state.value;

  searchRegions: OperatorFunction<string | any, readonly SelectableAddressField[] | any> = (text$: Observable<string>) =>
    text$.pipe(
      distinctUntilChanged(),
      map(term => {
        const v = this.regions ? this.regions.filter(state => new RegExp(this.normalizeString(term), 'mi').test(this.normalizeString(state.value))).slice(0, 10) : [];
        return this.checkShippment ? null : v;
      })
    )

  searchSettlements: OperatorFunction<string | any, readonly SelectableAddressField[] | any> = (text$: Observable<string>) => text$.pipe(
    distinctUntilChanged(),
    map(term => {
      const v = this.settlements ? this.settlements.filter(state => new RegExp(this.normalizeString(term), 'mi').test(this.normalizeString(state.value))).slice(0, 10) : [];
      return this.checkShippment ? null : v;
    })
  )

  select(): boolean {
    return true;
  }

  searchStreets: OperatorFunction<string | any, readonly SelectableAddressField[] | any> = (text$: Observable<string>) => text$.pipe(
    distinctUntilChanged(),
    map(term => {
      const v = this.streets ? this.streets.filter(state => new RegExp(this.normalizeString(term), 'mi').test(this.normalizeString(state.value))).slice(0, 10) : [];
      return this.checkShippment ? null : v;
    })
  )

  normalizeString(value: string) {
    value = value.toLowerCase();
    value = value.replace('ă', 'a');
    value = value.replace('â', 'a');
    value = value.replace('î', 'i');
    value = value.replace('ț', 't');
    value = value.replace('ţ', 't');
    value = value.replace('ș', 's');
    value = value.replace('ş', 's');
    return value;
  }

  regionSelectChanged(event: any) {
    const zipCode = this.addressForm.get('zipCode')?.value;
    const provinceCode = this.addressForm.get('provinceCode')?.value;
    if (zipCode && zipCode != '' || provinceCode && provinceCode != '' ) {
      this.addressForm.get('zipCode')?.reset();
      this.addressForm.get('provinceCode')?.reset();
    }
    this.nulifyCity();
    this.nulifyStreet();
    this.getSettlements(event.item.id);
  }

  settlementSelectChanged(event: any) {
    if (!this.isAdminEdit) {
      this.nulifyStreet();
      const region = this.addressForm.get('region')!.value;
      this.getStreets(event.item.id, region.id);
    }
  }

  getRegions() {
    this.gettingRegions = true;
    this.addressForm.get('region')!.markAsPending();
    this.geoService.getRegions()
      .pipe(
        finalize(() => {
          this.gettingRegions = false;
        })
      )
      .subscribe(
        res => {
          this.regions = res;
        },
      );
  }

  getSettlements(regionId: number) {
    this.gettingSettlements = true;
    this.addressForm.get('city')!.markAsPending();
    this.geoService.getSettlements(regionId)
      .pipe(
        finalize(() => {
          this.gettingSettlements = false;
        })
      )
      .subscribe(
        res => {
          this.settlements = res;
          if (this.settlements && this.settlements.length > 0) {
            this.addressForm.get('city');
          }
          this.addressForm.get('city')?.updateValueAndValidity();
        },
      );
  }

  getStreets(settlementId: number, regionId: number) {
    this.gettingStreets = true;
    this.addressForm.get('street')!.setValidators(null);
    this.addressForm.get('street')!.markAsPending();
    this.geoService.getStreets(settlementId, regionId)
      .pipe(
        finalize(() => {
          this.gettingStreets = false;
        })
      )
      .subscribe(
        {
          next: (res) => {
            this.streets = res;
            if (this.streets && this.streets.length > 0) {
              this.addressForm.get('street');
            }
            this.addressForm.get('street')?.updateValueAndValidity();
          },
          complete: () => {
            !this.isAdminEdit ? this.checkIfZipCodeExists() : '';
          }
        });
  }

  checkIfZipCodeExists(): void {
    const streets: ISelectableAddressField[] = this.streets;

    const containsZipCode = streets.some((street: ISelectableAddressField) => street.zipCode !== null);

    if (containsZipCode) {
      const objectMap = streets.map(street => street.zipCode);
      const index = objectMap.findIndex(street => street !== null);
      this.zipInputChanged(null, objectMap[index]);
    }
  }

  compareByID(itemOne: any, itemTwo: any) {
    return itemOne && itemTwo && itemOne.id == itemTwo.id;
  }

  getCountries() {
    this.locationService.getCountries().subscribe(
      res => {
        this.countries = res;
        const foundCountry = this.countries.find(elem => elem.value === 'Republica Moldova' || elem.value === 'Republic of Moldova');
        this.defaultCountry = foundCountry != undefined ? foundCountry : null as unknown as SelectableAddressField;
        // this.addressForm.patchValue({ country: this.defaultCountry });
      },
      (err: HttpErrorResponse) => {
        this.feedbackService.handleError(err);
      }
    );
  }

  nulifyStreet() {
    this.addressForm.get('street')?.patchValue(null);
    this.streets = [];
  }

  nulifyCity() {
    this.addressForm.get('city')?.patchValue(null);
    this.settlements = [];
  }

  nulifyRegionCode() {
    this.addressForm.get('provinceCode')?.patchValue(null);
    this.provinceCodes = [];
  }

  zipInputChanged(event: any, autocompletedZip?: string): void {
    if (!this.checkShippment) {
      let value: string;
      if (autocompletedZip) {
        value = autocompletedZip;
        this.addressForm.patchValue({ zipCode: value });
      } else {
        value = event.target.value;
      }
      this.validateZipNational(value);
    }
  }

  validateZipNational(value: any): void {
    if (value.match(/^.*[0-9]{1}$/)) {
      const region = this.addressForm.get('region')?.value;
      if (region == null || region == undefined || region == '') {
        this.addressForm.get('zipCode')?.setErrors({ validationErrors: ['region-required'] });
        this.addressForm.get('zipCode')?.markAsTouched();
        this.addressForm.updateValueAndValidity();
      } else {
        const zip = value.substring(value.length - 4, value.length);
        this.zipValidating = true;
        this.addressForm.get('zipCode')!.markAsPending();
        this.locationService.checkZipCode(zip, region.id)
          .pipe(
            finalize(() => {
              this.zipValidating = false;
            })
          )
          .subscribe(
            _ => {
              this.addressForm.get('zipCode')!.updateValueAndValidity();
            },
            (err: HttpErrorResponse) => {
              // this.feedbackService.handleError(err, this.addressForm);
              this.addressForm.get('zipCode')?.markAsTouched();
              this.addressForm.updateValueAndValidity();
              this.addressForm.get('zipCode')?.setErrors({ validationErrors: ['ZIP_CODE_NOT_EXISTS'] });
            }
          );
      }
    }
  }

  checkShipping({ target }: Event): boolean {
    this.addressForm.reset();
    // @ts-ignore
    this.checkShippment = (target)!.checked;
    this.checkShipp.emit(!this.checkShippment);
    if (this.checkShippment) {
      this.addressForm = this.fb.group({
        country: ['', [Validators.required]],
        region: [null, [Validators.required]],
        provinceCode: ['', [provinceCodeValidator.cannotContainSpace]],
        city: ['', [Validators.required]],
        street: ['', [specialStreetCaracterValidator]],
        block: ['', [Validators.required, specialBlockCaracterValidator]],
        apartment: ['', [specialApartmentCaracterValidator]],
        zipCode: ['', [Validators.required]],
      });
    } else {
      this.addressForm = this.fb.group({
        country: ['', [Validators.required]],
        region: [null, [Validators.required]],
        city: ['', [Validators.required]],
        street: ['', [specialStreetCaracterValidator]],
        block: ['', [specialBlockCaracterValidator]],
        apartment: ['', [specialApartmentCaracterValidator]],
        zipCode: ['', [Validators.required]],
      });
      this.getCountries();
    }
    !this.checkShippment ? this.addressForm.get('country')?.setValue(this.countries[0]) : null;
    this.handleShippingType();
    console.log(this.addressForm.value);
    return this.checkShippment;
  }

  internationalShipping(): void {
    this.checkShippment = !this.checkShippment;
    this.handleShippingType();
    this.getCountries();
  }

  ngAfterViewChecked(): void {
    if (this.firstInit && this.addressForm.get('country')?.value) {
      const country = this.addressForm.get('country')?.value;
      if (country.id === undefined) {
        this.internationalShipping();
      }
      this.firstInit = false;
    } else if (this.defaultCountry && !this.addressForm.get('country')?.value && !this.checkShippment) {
      this.firstInit = false;
      this.addressForm.patchValue({ country: this.defaultCountry });
    }
  }

  internationalDeliveryCountry(): void {
    this.countrySvc.countryListManager().subscribe(res => {
      // @ts-ignore
      this.countryList = res.items;
    });
  }

  isoCountry(item: any): void {
    this.countryList.forEach((el: any) => {
      if (el.nameCountry === item) {
        this.findItem = el;
        sessionStorage.setItem('infoCountry', JSON.stringify(this.findItem));
  
        const countriesWithCustomValidation = ['Canada', 'Mexic', 'Statele Unite ale Americii', 'India', 'Emiratele Arabe Unite'];
      
        if (countriesWithCustomValidation.includes(el.nameCountry)) {
          this.isCustomValidationRequired = true;
          this.addressForm.get('provinceCode')?.setValidators([Validators.required, provinceCodeValidator.cannotContainSpace]);
        } else {
          this.isCustomValidationRequired = false;
          this.addressForm.get('provinceCode')?.clearValidators();
        }
  
        this.addressForm.controls.region.reset();
        this.addressForm.controls.provinceCode.reset();
        this.addressForm.controls.city.reset();
        this.addressForm.controls.street.reset();
        this.addressForm.controls.block.reset();
        this.addressForm.controls.apartment.reset();
        this.addressForm.controls.zipCode.reset();
      }
    });
  }
  

  handleFormStatus(): void {
    this.shippingService.addressValidatorStatus.subscribe((status) => {
      status === true ? this.addressValidatorHandle = true : status === false ? this.addressValidatorHandle = false : '';
    });
  }

  formSubscribe(): void {
    this.addressForm.get('street')?.valueChanges.pipe(takeUntil(this.notifier)).subscribe(newValue => {
      if (newValue && typeof (newValue) == 'string') {
        this.addressForm.get('street')!.patchValue({ id: null, value: newValue }, { emitEvent: false, onlySelf: true });
      }
    });

    this.addressForm.get('country')?.valueChanges.pipe(takeUntil(this.notifier)).subscribe(newValue => {
      if (newValue && typeof (newValue) == 'string') {
        this.addressForm.get('country')!.patchValue({ id: null, value: newValue }, { emitEvent: false, onlySelf: true });
      }
    }),

      this.addressForm.get('region')?.valueChanges.pipe(takeUntil(this.notifier)).subscribe(newValue => {
        if (newValue && typeof (newValue) == 'string') {
          this.addressForm.get('region')!.patchValue({ id: null, value: newValue }, { emitEvent: false, onlySelf: true });
        }
      }),

      this.addressForm.get('city')?.valueChanges.pipe(takeUntil(this.notifier)).subscribe(newValue => {
        if (newValue && typeof (newValue) == 'string') {
          this.addressForm.get('city')!.patchValue({ id: null, value: newValue }, { emitEvent: false, onlySelf: true });
        }
      }),

      this.addressForm.get('provinceCode')?.valueChanges.pipe(takeUntil(this.notifier)).subscribe(newValue => {
        if (newValue && typeof (newValue) == 'string') {
          this.addressForm.get('provinceCode')!.patchValue({ id: null, value: newValue }, { emitEvent: false, onlySelf: true });
        }
      });
  }

  handleShippingType(): void {
    this.shippingService.setInternationalShipping(this.checkShippment);
  }

  ngDoCheck(): void {
    if (this.checkShippment) {
      this.notifier.next();
      this.notifier.complete();
    } else {
      this.formSubscribe();
    }
  }

  ngAfterViewInit(): void {
    const country = this.addressForm.get('country')?.value;

    if (this.isAdminEdit && country.id !== undefined) {
      const regionId = this.addressForm.get('region')?.value;
      const cityId = this.addressForm.get('city')?.value;

      const zipCode = this.addressForm.get('zipCode')?.value;

      zipCode.length !== 0 ? this.validateZipNational(zipCode) : '';

      cityId.value.includes('mun. Chi') ? cityId.id = '659826' : '';

      this.getStreets(cityId.id, regionId.id);
    }
  }
}
