import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DisableConfirmModalComponent } from '../../../shared/components/disable-confirm-modal/disable-confirm-modal.component';
import { Pagination } from '../../../shared/models';
import { FeedbackService } from '../../../shared/services';
import { CountryManagerService } from '../../../shared/services/country-manager.service';

@Component({
  selector: 'app-country-manage',
  templateUrl: './country-manage.component.html',
  styleUrls: ['./country-manage.component.scss'],
})
export class CountryManageComponent implements OnInit {
  file: any;
  name = ''
  formGroup: FormGroup;
  country: any = [];
  requesting = true;
  navList = [
    { url: '/admin', translatedText: 'admin.pageName', link: '/admin' },
    { url: '.', translatedText: 'admin.country-manage.country' },
  ];

  query = {
    page: 1,
    itemsPerPage: 5
  } as Pagination;
  currentPage = 1;
  itemsPerPage = 5;
  sizePage: Array<number> = [5, 10, 15, 25];
  totalCount: number;

  loading = false;
  base64 = 'Base64...';

  constructor(
    public service: CountryManagerService,
    public serviceFeedback: FeedbackService,
    public router: Router,
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public modalService: NgbModal
  ) { }


  ngOnInit(): void {
    this.formGroup = this.fb.group({
      upload: ['', [Validators.required]],
    });
    this.pushParamsUrl();
    this.getCountry();
  }

  changePagesize(num: string): void {
    this.itemsPerPage = +num;
    this.pushParamsUrl();
  }

  pushParamsUrl(): void {
    this.router.navigate(['/admin/country'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      },
    });
    this.country.length === 0 ? this.getCountry() : null;
  }

  pageDataChanged(num: number): void {
    this.currentPage = num;
    this.pushParamsUrl();
  }

  getCountry(): void {
    this.loading = true;
    setTimeout(() => {
      this.service.countryListManager().
        pipe(
          finalize(() => this.loading = false)
        )
        .subscribe((res) => {
          // @ts-ignore
          this.totalCount = res.paged.totalCount;
          // this.paged = res.paged
          // @ts-ignore

          this.country = res.items;
        });
    });
  }

  goToEdit(id: string): void {
    this.service.countryOneData(id).subscribe((res) => {
      localStorage.setItem('country', JSON.stringify(res));
      this.router.navigate(['admin/country/add']);
    });
  }

  clearCountryStorage(): void {
    localStorage.removeItem('country');
  }

  deleteCountryFromManager(cn: any): void {
    const modalRef = this.modalService.open(DisableConfirmModalComponent);
    modalRef.componentInstance.obj = cn;
    modalRef.componentInstance.title = cn.nameCountry;
    modalRef.componentInstance.delete = true;
    modalRef.result.then((response: string) => {
      if (response === 'confirmed') {
        this.service.deleteCountry(cn.countryId).subscribe((res) => {

          this.getCountry();
          this.serviceFeedback.handleSuccess('admin-country-delete');
        });
      }
    });
  }


  getFile(e: Event): void {
    // @ts-ignore
    this.file = e.target?.files.item(0);
    this.name = this.file.name;
    if (this.file) {
      this.requesting = false;
    }
  }

  countryListCsv(): void {
    this.requesting = true;
    if (this.file !== undefined) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file as Blob);
      reader.onload = () => {
        this.base64 = reader.result as string;
        const regexp = /[^,]*$/;

        // @ts-ignore
        this.base64 = this.base64.match(regexp)[0];
        this.service.countryListCsv(this.base64)
          .subscribe((res) => {
            this.serviceFeedback.handleSuccess('admin-upload-csv');
            this.getCountry();
            // this.requesting = true;
            this.formGroup.reset();
          }, err => {
            this.serviceFeedback.handleWarning('server-error');
            this.requesting = true;
          });
      };
    }
  }
}
