import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackType } from '../enums';
import { CustomError } from '../models/custom-error.model';
import { ToastService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(
    private toaster: ToastService,
    private translateService: TranslateService,
  ) { }

  handleSuccess(messageKey: string) {
    this.show(messageKey, FeedbackType.Success);
  }

  handleInfo(messageKey: string) {
    this.show(messageKey, FeedbackType.Info);
  }

  handleWarning(messageKey: string) {
    this.show(messageKey, FeedbackType.Warning);
  }

  handleError(error: HttpErrorResponse, form?: FormGroup) {
    switch (error.status) {
      case 400: this.handle400(error, form); break;
      case 404: this.handle404(); break;
      case 500: this.handle500(); break;
      case 403: this.handle403(); break;
      default: this.handleDefaultError(); break;
    }
  }

  private handle400(error: HttpErrorResponse, form?: FormGroup) {
    this.show(`generic-error`, FeedbackType.Error);
    this.assignErrors(error, form);
  }

  private assignErrors(error: HttpErrorResponse, form?: FormGroup) {
    if (form) {
      let formKeys = Object.keys(form.controls);
      let customError = error.error as CustomError;
      let customErrors = customError.Errors || customError.errors;
      let customErrorKeys = Object.keys(customErrors);
      customErrorKeys.forEach(element => {
        formKeys.forEach(item => {
          if (form.controls[item] instanceof FormControl && element.toLowerCase().includes(item.toLowerCase())) {
            form.controls[item].setErrors({ validationErrors: customErrors[element] });
          }
          if (form?.controls[item] instanceof FormGroup) {
            this.assignErrors(error, form?.controls[item] as FormGroup);
          };
        })
      });
    }
  };

  private handle404() {
    this.show(`not-found-error`, FeedbackType.Error);
  }

  private handle500() {
    this.show(`server-error`, FeedbackType.Error);
  }

  private handle403() {
    this.show(`unauthorized-error`, FeedbackType.Error);
  }

  private handleDefaultError() {
    this.show(`generic-error`, FeedbackType.Error);
  }

  private show(messageKey: string, type: FeedbackType) {
    this.toaster.show(this.translateService.instant(`messages.${messageKey}`), { type: type, classname: `${type} w-300`, delay: 5000, })
  }
}
