import { SelectableAddressField } from "./selectable-address-field.model";

export class AddressModel {
  zipCode: string;
  region: SelectableAddressField;
  city: SelectableAddressField;
  provinceCode: string;
  street: SelectableAddressField;
  block: string;
  apartment: string;
  country: SelectableAddressField;
  isLocal: string;
}
