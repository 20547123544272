<div class="ml-5">
    <app-navigation-list [navigationList]="navList"></app-navigation-list>
</div>


<div class="pickup col d-flex flex-column align-items-center justify-content-center mr-auto ml-auto">
    <div class="pickup-item col-md-11">
        <label class="pickup-item__label col-3">
            {{'admin.country-manage.country' | translate | uppercase}}
        </label>
        <label class="pickup-item__label col-2">
            {{'admin.country-manage.alpha-2' | translate | uppercase}}
        </label>
        <label class="pickup-item__label col-2">
            {{'admin.country-manage.alpha-3' | translate | uppercase}}
        </label>
        <label class="pickup-item__label col-2">
            {{'admin.country-manage.zip' | translate | uppercase}}
        </label>
    </div>

    <ng-container>
        <div class="pickup-info d-flex align-items-center" *ngFor="let cn of country">
            <p class="pickup-info__txt col-3">
                {{ cn.nameCountry | truncate: [29] }}
            </p>
            <p class="pickup-info__txt col-2">
                {{ cn.codeAlpha2 | truncate: [29] }}
            </p>

            <p class="pickup-info__txt col-2">
                {{ cn.codeAlpha3 | truncate: [29] }}
            </p>

            <p class="pickup-info__txt col-2">
                {{ cn.zipCountry | truncate: [29] }}
            </p>


            <div class="col-3 col-arrow-button d-flex  justify-content-center">
                <a class="btn btn-outline-info d-flex align-items-center" (click)="goToEdit(cn.countryId)">
                    <i class="bi bi-pen mr-2"></i>
                    <span>
                        {{'buttons.edit' | translate}}
                    </span>
                </a>

                <a class="btn btn-outline-info d-flex align-items-center ml-3" (click)="deleteCountryFromManager(cn)">
                    <i class="bi bi-x mr-2"></i>
                    <span>
                        {{'home.receiver.addresses.labels.remove' | translate}}
                    </span>
                </a>
            </div>

        </div>
    </ng-container>

    <div *ngIf="(!country || country?.length == 0) && !loading">
        <div class="row mt-3">
            <p class="text-muted">{{ 'admin.sp.noContentText' | translate }}</p>
        </div>
    </div>





    <div class="d-flex align-items-center mt-5">
        <ngb-pagination [collectionSize]="totalCount"
                        [(page)]="currentPage"
                        [maxSize]="5"
                        [pageSize]="itemsPerPage"
                        (pageChange)="pageDataChanged(currentPage)"
                        (click)="getCountry()">

            <ng-template ngbPaginationPrevious>
                <span class="prev">{{'admin.pagination.previous' | translate}}</span> <span class="page-prev"><<</span>
            </ng-template>
            <ng-template ngbPaginationNext>
                <span class="page-next">>></span><span class="next">{{'admin.pagination.next' | translate}}</span>
            </ng-template>

        </ngb-pagination>

        <select class="custom-select"
                style="width: auto"
                #val
                (input)="changePagesize(val.value)"
                (input)="getCountry()">
            <option *ngFor="let num of sizePage; let i = index">{{ num }}</option>
        </select>


        <div class="row-buttons d-flex justify-content-end align-items-center pt-2 px-0 ml-5 mt-3">
            <form class="form-group d-flex justify-content-end align-items-center" [formGroup]="formGroup">
                <div class="d-flex flex-column" style="position: relative">
                    <label class="add-btn d-flex justify-content-center align-items-center mr-3 mt-1 px-3">
                        {{'admin.country-manage.choice' | translate}}
                        <input class="form-control-file mt-2" type="file" formControlName="upload" (change)="getFile($event)">
                    </label>
                    <span class="file mt-3">{{file?.name}}</span>
                </div>
                <button class="btn add-btn  mr-3" (click)="countryListCsv()" [disabled]="requesting">{{'admin.country-manage.download' | translate }}</button>
            </form>
            <div class="pickup-add row-buttons d-flex align-items-start pb-1">
                <span class="d-flex align-items-start mb-3" routerLink="add" (click)="clearCountryStorage()">
                    <i class="bi bi-plus-circle"></i>
                </span>
            </div>
        </div>
    </div>

</div>
