import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from '../models';
import { PickupPointsModel } from '../models/pickupPoints.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { AppConfigService } from './app-config.service';
import { IPickupPoints } from './iterfaces/pickup-points.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PickupPointsService implements IPickupPoints {
  private backendUrl: string;
  paginatorGenerator = new PaginationGeneratorService();

  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient,
    public userService: UserService
  ) {
    this.backendUrl = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/adm`;
  }

  getPickupPointsBySp(queryParams?: Pagination, spId?: string): Observable<{ items: PickupPointsModel[], paged: any }> {
    let params;
    if (queryParams) {
      params = this.paginatorGenerator.generatePagination(queryParams);
    }
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point`, { params });
  }

  getPickupPointsById(pPId: string, spId: string): Observable<PickupPointsModel> {
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point/${pPId}`);
  }

  getPickupPointsAddressByDeliveryId(deliveryId: string): Observable<PickupPointsModel> {
    return this.http.get<any>(`${this.backendUrl}/pickup-point/getPicUpPointAddressByDelyveryId/${deliveryId}`);
  }

  createOrUpdatePickUpPoint(data: any, spId: string): Observable<PickupPointsModel> {
    if (!data.id) {
      return this.createPickupPoint(data, spId);
    }
    return this.updatePickupPoint(data.id, data, spId);
  }

  createPickupPoint(data: any, spId: string): Observable<PickupPointsModel> {
    return this.http.post<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point`, data);
  }

  updatePickupPoint(pPId: string, data: any, spId: string): Observable<PickupPointsModel> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point/${pPId}`, data);
  }

  disableEnablePickupPoint(pp: PickupPointsModel, spId: string): Observable<any> {
    if (!pp.isDeleted) {
      return this.disablePickupPoint(pp, spId);
    } else {
      return this.activatePickupPoint(pp, spId);
    }
  }


  disablePickupPoint(pp: PickupPointsModel, spId: string): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point/${pp.id}`);
  }

  activatePickupPoint(pp: PickupPointsModel, spId: string): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/${spId}/pickup-point/${pp.id}/activate`, { id: pp.id });
  }


}
