export enum DeliveryStatus {
  // New = 1,
  // PartiallyPaid = 2,
  // Paid = 3,
  // Completed = 4,
  // Expired = 5,
  // Cancelled = 6,
  // Refunding = 7,
  // Refunded = 8,
  /// <summary>
  /// Default fallback value that shall not be used.
  /// </summary>
  Unknown = 0,
  AwaitingPayment,
  Paid,
  Processing,
  Cancelled,
  Expired,
  Ready,
  AwaitingPickup,
  Delivering,
  Delivered,
  Confirmed,
  Problem,
  Returning,
  Returned
}
