import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { NgWizardConfig, NgWizardModule, THEME } from 'ng-wizard';
import { appInitializerFn } from './shared/util/initializer.factory';
import { UserService } from './shared/services/user.service';
import { AUTH_INTERCEPTOR_PROVIDER } from './shared/interceptors/auth.interceptor';
import { DatePipe } from '@angular/common';
import * as appSettings from './../assets/config/appsettings.json';
import { OrdersListComponent } from './home/service-provider/orders-list/orders-list.component';
import { SortableDirective } from './home/service-provider/orders-list/sortable.directive';
import { OrdersComponent } from './home/admin/orders/orders.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PrintComponent } from './home/service-provider/orders-list/print/print.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CountryManageComponent } from './home/admin/country-manage/country-manage.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';

import localeFrExtra from '@angular/common/locales/extra/fr';
import { ReportsComponent } from './home/admin/reports/reports.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportDatePickerComponent } from './home/admin/reports/date-picker/report-date-picker.component';
import {NgxWebstorageModule} from "ngx-webstorage";

registerLocaleData(localeRo, 'ro', localeFrExtra);
registerLocaleData(localeRu, 'ru', localeFrExtra);


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};

@NgModule({
  declarations: [
    AppComponent,
    SortableDirective,
    OrdersComponent,
    PrintComponent,
    CountryManageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    TranslateModule.forRoot(
      {
        defaultLanguage: appSettings.defaultLang,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
    SharedModule,
    NgWizardModule.forRoot(ngWizardConfig),
    NgbModule,
    PdfJsViewerModule,
    NgMultiSelectDropDownModule,
    BrowserAnimationsModule
  ],
  providers: [
    DatePipe,
    AUTH_INTERCEPTOR_PROVIDER,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService, UserService],
      useFactory: appInitializerFn
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
