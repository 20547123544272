
<div *ngIf="isOrderStatus" class="modal-body p-3">
    <h4 class="article-title text-center mt-3 ml-3 mr-3">
        {{'admin.confirmDisable.orderCancelStatus' | translate}}
    </h4>
    <form class="text-left" [formGroup]="formGroup">
        <label class="form-label" for="reason">{{'admin.confirmDisable.reason' | translate}}</label>
        <input class="form-control" formControlName="reason" name="reason" id="reason" required [(ngModel)]="reason">
    </form>
        <div class="d-flex row justify-content-center">
            <p class="col-6 mt-4 e-bold">{{'admin.confirmDisable.confirmOrderCancelStatus' | translate}}</p>
            <button type="button" class="btn btn-secondary col-2 mt-3 ml-3 mr-3" data-dismiss="modal"
                    (click)="activeModal.close('Close click')">
                {{'admin.confirmDisable.No' | translate}}
            </button>
            <button type="button" class="btn btn-primary col-2 mt-3 ml-3 mr-3"
                    (click)="submit()">
                {{'admin.confirmDisable.Yes' | translate}}
            </button>
        </div>
    
</div>

<div *ngIf="!isOrderStatus" class="modal-body p-3">
    <div *ngIf="!delete; else DeleteTemplate">
        <h4 class="article-title text-center m-0"
            *ngIf="!obj.isDeleted; else EnableTempl">
            {{'admin.confirmDisable.disableMessage' | translate}}
            "{{title && title.length ? title : obj.name}}"?
        </h4>
        <ng-template #EnableTempl>
            <h4 class="article-title text-center m-0">
                {{'admin.confirmDisable.activateMessage' | translate}}
                "{{title && title.length ? title : obj.name}}"?
            </h4>
        </ng-template>
    </div>

    <ng-template #DeleteTemplate>
        <div>
            <h4 class="article-title text-center m-0">
                {{'admin.confirmDisable.confirmDeleteMessage' | translate}}
                "{{title && title.length ? title : obj.name}}"?
            </h4>
        </div>
    </ng-template>

    <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-secondary mt-3 ml-3 mr-3" data-dismiss="modal"
                (click)="activeModal.close('Close click')">
            {{'admin.confirmDisable.cancel' | translate}}
        </button>
        <button type="button" class="btn btn-primary mt-3 ml-3 mr-3"
                (click)="activeModal.close('confirmed');">
            {{'admin.confirmDisable.confirm' | translate}}
        </button>
    </div>
</div>
