import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthModel, ContextModel } from '../models';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-select-context-modal',
  templateUrl: './select-context-modal.component.html',
  styleUrls: ['./select-context-modal.component.scss']
})
export class SelectContextModalComponent implements OnInit {
  auth!: AuthModel;
  contexts = Array<ContextModel>();
  canClose = false;

  constructor(
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.retrieveContextList();

  }

  retrieveContextList(): void {
    this.auth = this.userService.getCurrentUser();
    this.contexts = this.auth.user.boards;
  }

  selectContext(context: ContextModel): void {
    this.userService.setContext(context).subscribe(
      res => {
        if (res.success) {
          this.activeModal.close();
        } else {
          this.router.navigate(['auth'], { replaceUrl: true });
        }
      }
    );
  }
}
