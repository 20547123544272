import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PipeTransform } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfigService } from '../../../shared/services';
import { Order } from './model';
import { SortColumn, SortDirection } from './sortable.directive';

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

interface SearchResult {
  countries: Order[];
  total: number;
}

// @ts-ignore
const compare = (v1: boolean | string | undefined, v2: boolean | string | undefined) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(orders: Order[], column: SortColumn, direction: string): Order[] {
  if (direction === '' || column === '') {
    return orders;
  } else {
    return [...orders].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(order: Order, term: string, pipe: PipeTransform) {
  return order.idWaybill.toLowerCase().includes(term.toLowerCase())
    || pipe.transform(order.idWaybill).includes(term)
    || pipe.transform(order.idOrder).includes(term);
}

// @ts-ignore
@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  private loading = new BehaviorSubject<boolean>(true);
  private search = new Subject<void>();
  private orders = new BehaviorSubject<Order[]>([]);
  private total = new BehaviorSubject<number>(0);

  callBack: any;


  state = {
    page: 1,
    pageSize: 4,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  sorting = [
    'pickUpDate', 'createDate', 'deliveryDate'
  ]

  states = [
    { state: 'All', style: false },
    { state: 'Awaiting Payment', style: false },
    { state: 'Paid', style: false },
    { state: 'Processing', style: false },
    { state: 'Cancelled', style: false },
    { state: 'Expired', style: false },
    { state: 'Ready', style: false },
    { state: 'Awaiting Pick Up', style: false },
    { state: 'Delivering', style: false },
    { state: 'Delivered', style: false },
    { state: 'Confirmed', style: false },
    { state: 'Problem', style: false },
    { state: 'Returning', style: false },
    { state: 'Returned', style: false },
    { state: 'Refunded', style: false },
    { state: 'Unknown', style: false }
  ];

  public items: any;
  public totalCount: any;
  load = false;

  constructor(public http: HttpClient,
    public route: ActivatedRoute,
    public configService: AppConfigService) {
  }

  get orders$(): object {
    return this.orders.asObservable();
  }

  get total$(): Observable<any> {
    return this.total.asObservable();
  }

  get loading$(): Observable<any> {
    return this.loading.asObservable();
  }

  get page(): any {
    return this.state.page;
  }

  get pageSize(): any {
    return this.state.pageSize;
  }

  get searchTerm$(): any {
    return this.state.searchTerm;
  }

  set sortColumn(sortColumn: SortColumn) {
    // tslint:disable-next-line:no-unused-expression
    sortColumn;
  }

  private set(param: {
    sortColumn: 'idMdelivery' | 'state' | 'idOrder' | 'idWaybill' | 'serviceProvider'
    | 'receiver' | 'expeditionLocation' | 'receiverLocation' | 'typeItem' | 'dateCreationOrder' |
    'dateOfPaymentOrder' | 'datePickUpAtCarrier' | 'dateOfDelivered' | 'carries' | 'visible' | ''
  }) { }

  getRegistredOrders(): any {
    const api = `${this.configService.appConfig.SERVER_URL}`
    let params: Params;
    params = this.route.snapshot.queryParams;
    this.load = true;
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider/orders`, { params });

  }

  getPdfForPrint(data: string[]): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/pub/delivery/print/mergewaybills`, data, { responseType: 'blob' });
  }

  changeStatusToReady(deliveriesId: string[]): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/pub/delivery/changeStatus`, { deliveriesId });
  }

  changeStatus(deliveriesId: string[], status: string): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    console.log(status);
    return this.http.post(`${api}/api/v1.0/pub/delivery/changeStatus`, { deliveriesId, status});
  }

  getCarriersList(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/pub/dictionaries/carriers`);
  }

  serviceProviderInfo(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile`);
  }

  getSpList(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider`);
  }


  getPicUpPointList(pickUpPoint: string): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/pickup-point-code/${pickUpPoint}`);
  }

  getDestinationRegionCodes(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/destination-region-codes`);
  }

  getProductCodes(productCode: string = '0'): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/product-codes/${productCode}`);
  }

  locationPickUp(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/sp/serviceprovider/profile/pickup-point`);
  }

  receiverAdres(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/receiver/address`);
  }

  geoRegions(): Observable<any> {
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.get(`${api}/api/v1.0/pub/geo/regions`);
  }

  generateInvoice(mDeliveriesId: Array<string>): Observable<object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content - Type': 'application/pdf'
    });
    const api = `${this.configService.appConfig.SERVER_URL}`;
    return this.http.post(`${api}/api/v1.0/adm/serviceprovider/generatemdeliverypdf`, { mDeliveriesId }, { responseType: 'blob' });
  }

  exportOrdersToCSV(): Observable<object> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content - Type': 'application/csv'
    });
    const api = `${this.configService.appConfig.SERVER_URL}`
    let params: Params;
    params = this.route.snapshot.queryParams;
    this.load = true;
    return this.http.get(`${api}/api/v1.0/adm/serviceprovider/exportOrdersToCSV`, { params, responseType: 'blob' });
  }
}
