<div class="pickup col d-flex flex-column align-items-center justify-content-center" *ngIf="services">
    <div class="pickup-item col-md-11">
        <label class="pickup-item__label col-md-3 pl-0">
            {{ 'general.pickUpPoints.labels.pickup-point-id' | translate }}
        </label>
        <label class="pickup-item__label col-md-3 pl-2">
            {{ 'general.pickUpPoints.labels.name' | translate }}
        </label>
    </div>

    <ng-container *ngIf="services && services.length">
        <div class="pickup-info d-flex align-items-center" *ngFor="let pP of services">

            <p class="pickup-info__txt col-md-3">
                {{ pP.code | truncate: [14] }}
            </p>

            <p class="pickup-info__txt col-md-6">
                {{ pP.name | truncate: [100] }}
            </p>

            <div class="col-md-3 col-arrow-button d-flex align-items-start justify-content-end">
                <a class="btn btn-outline-info d-flex align-items-center" [routerLink]="['service/add/', pP.id]">
                    <i class="fas fa-edit mr-1"></i>
                    <span>
                        {{'admin.buttons.edit' | translate}}
                    </span>
                </a>
                <button class="btn btn-outline-info mw-100" (click)="deactivateService(pP)">
                    <i *ngIf="pP.isDeleted" class="fas fa-lock mr-2"></i>
                    <i *ngIf="!pP.isDeleted" class="fas fa-lock-open mr-2"></i>
                    <span *ngIf="!pP.isDeleted">{{'admin.buttons.disable' | translate | truncate: [8]}}</span>
                    <span *ngIf="pP.isDeleted">{{'admin.buttons.enable' | translate | truncate: [8]}}</span>


                </button>
            </div>

        </div>
    </ng-container>

    <div *ngIf="(!services || services?.length == 0) && !loading">
        <div class="row mt-3">
            <p class="text-muted">{{ 'admin.sp.noContentText' | translate }}</p>
        </div>
    </div>

    <div class="pickup-add row-buttons d-flex align-self-end">
        <a class="btn btn-circle" type="button" routerLink="service/add">
            <i class="bi bi-plus-circle"></i>
        </a>
    </div>

    <app-pagination *ngIf="paged?.totalCount! > 3" [paged]="paged" [(inputQuery)]="query" (pageClicked)="getServicesData()">
    </app-pagination>
</div>




























<!--<div class="content-holder bg-white mb-4">-->
<!--  <i *ngIf="loading" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>-->
<!--  <div *ngIf="services && services.length">-->
<!--    <div class="data-row" *ngFor="let serv of services">-->
<!--      <div class="row no-gutters">-->
<!--        <div class="col-md-4 form-group">-->
<!--          <label>-->
<!--            {{'general.services.labels.serviceId' | translate}}-->
<!--          </label>-->
<!--          <div class="label-value font-weight-bold">-->
<!--            {{serv.code | truncate:[14] }}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-8 form-group">-->
<!--          <label>-->
<!--            {{'general.services.labels.name' | translate}}-->
<!--          </label>-->
<!--          <div class="label-value font-weight-bold">-->
<!--            {{serv.name | truncate:[20]}}-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col col-arrow-button d-flex align-items-center justify-content-end">-->
<!--          <a class="btn btn-light" [routerLink]="['service/add/', serv.id]">-->
<!--            <i class="fas fa-edit text-muted"></i>-->
<!--          </a>-->
<!--          <button class="btn btn-light mw-100" (click)="deactivateService(serv)">-->
<!--            <i *ngIf="serv.isDeleted" class="fas fa-lock text-muted"></i>-->
<!--            <i *ngIf="!serv.isDeleted" class="fas fa-lock-open text-muted"></i>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="(!services || services?.length == 0) && !loading">-->
<!--    <div class="row mt-3">-->
<!--      <p class="text-muted">{{'admin.sp.noContentText' | translate }}</p>-->
<!--    </div>-->
<!--  </div>-->
<!--  <app-pagination *ngIf="paged" [paged]="paged" [(inputQuery)]="query" (pageClicked)="getServicesData()">-->
<!--  </app-pagination>-->
<!--  <div class="row-buttons d-flex justify-content-end py-2 px-0">-->
<!--    <a class="btn btn-circle btn-primary" type="button" routerLink="service/add">-->
<!--      <i class="fas fa-plus"></i>-->
<!--    </a>-->
<!--  </div>-->
<!--</div>-->
