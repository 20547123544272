import { Locality } from "./locality";

export class ZipCode {
  
  id: string = "";
  code: string;
  type: number;
  createBy: string;
  createDate: Date
  zipCode: string = "";
  regionId: string = "";
  regionName: string = "";
  localityId: string = "";
  localityName: string = "";
  isDeleted: boolean;
  errorMessage: string;
}
