import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthModel, ContextModel } from '../../models';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-select-context-list',
  templateUrl: './select-context-list.component.html',
  styleUrls: ['./select-context-list.component.scss']
})
export class SelectContextListComponent implements OnInit {

  auth!: AuthModel;
  contexts = Array<ContextModel>();
  canClose = false;
  adminContext: ContextModel | null;

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.retrieveContextList();
  }

  retrieveContextList(): void {
    this.auth = this.userService.getCurrentUser();
    this.contexts = this.auth.user.boards;
  }

  selectContext(context: ContextModel): void {
    this.userService.setContext(context).subscribe(
      res => {
        if (res.success) { } else {
          this.router.navigate(['auth'], { replaceUrl: true });
        }
      }
    );
  }
}
