import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { Pagination, PagedResponse } from '../models';
import { OrderConfirmation } from '../models/order-confirmation.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';

@Injectable({
  providedIn: 'root'
})
export class ReceiverService {
  private api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private paginationGenerator: PaginationGeneratorService
  ) { }

  getOrderList(pagination: Pagination): Observable<PagedResponse<OrderConfirmation>> {
    const params = this.paginationGenerator.generatePagination(pagination);
    return this.http.get<PagedResponse<OrderConfirmation>>(`${this.api}/receiver/orders`, { params });
  }
}
