import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { ServiceProviderModel } from '../models/service-provider.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { IServiceProvider } from './iterfaces/service-provider.interface';
import { OrderConfirmation } from '../models/order-confirmation.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderProfileService implements IServiceProvider {

  backendUrl: string;
  api = this.appConfigService.appConfig.SERVER_URL
  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient,
    private paginatorGenerator: PaginationGeneratorService
  ) {
    this.backendUrl = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/sp`;
  }
  getServiceProviders(q: any): Observable<{ items: ServiceProviderModel[]; paged: any; }> {
    throw new Error('Method not implemented.');
  }
  createServiceProvider(data: any): Observable<string> {
    throw new Error('Method not implemented.');
  }
  disableServiceProvider(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  activateServiceProvider(id: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getServiceProviderById(): Observable<ServiceProviderModel> {
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/profile`);
  }

  createOrUpdateServiceProvider(data: any): Observable<string> {
    return this.updateServiceProvider(data);
  }

  updateServiceProvider(data: any): Observable<string> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/profile`, data);
  }

  searchOrder(idType: string, id: string, idTypeRelatedId: string | null) {
    let params = new HttpParams();
    params = params.append('searchBy', idType);
    params = params.append('value', id);
    if (idTypeRelatedId) {
      params = params.append('id', idTypeRelatedId);
    }
    return this.http.get<OrderConfirmation>(`${this.backendUrl}/serviceprovider/profile/search/order`, { params })
  }

  serviceProviderProduct(): Observable<any> {
    return this.http.get(`${this.api}/api/v1.0/sp/serviceprovider/profile/product`)
  }

}
