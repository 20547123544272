<div class="d-flex flex-column">
    <span>
        <span *ngIf="address?.street?.value">{{(address.street?.value ? address.street?.value : address.street)}}</span>
        <span *ngIf="!address?.street?.value && !address.country.id">{{address.street}}</span>
        <span *ngIf="address.block">  {{(address.block ? address.block : '')}}</span>
        <span *ngIf="address.apartment">  ap. {{address.apartment}}</span>
    </span>
    <span>
        <span *ngIf="address.provinceCode">{{address.provinceCode}}</span>
    </span>
    <span>
        <span *ngIf="address.country.id">MD-</span> <span>{{address.zipCode}}</span>
    </span>
    <span>{{address.city.value ? (address.city.value) : (address.city)}}</span>
    <span>{{address.region.value ? (address.region.value) : address.region}}</span>
    <span>{{ address.country.value ? (translatePath + address.country.value | translate) : address.country }}</span>
</div>
