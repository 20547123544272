<div id="selectRole" class="list-group d-flex align-items-center flex-column">
   <a *ngFor="let context of contexts" class="dropdown-item" ngbDropdownItem (click)="selectContext(context)">
      <div>
         <div>
            <span>{{ context.properties.profileDecoded }} </span>
         </div>
         <div class="role-details">
            {{ context.properties.name }} (<span>{{ context.properties.isLegal ? 'IDNO' : 'IDNP' }}</span>
            {{ context.properties.idn }})
         </div>
      </div>
   </a>
</div>
