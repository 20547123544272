import { AbstractControl, ValidationErrors } from "@angular/forms";

export class provinceCodeValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'string' && control.value.indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }
}
