<div class="form d-flex flex-column justify-content-center align-items-center" *ngIf="addressForm"
    [formGroup]="addressForm">
    <div class="col-12 d-flex  ml-4" [ngClass]="path ? 'justify-content-start' : ' justify-content-center'">
        <div class="international-check form-check col-10  mb-4  pl-0 ml-2" *ngIf="checking">
            <input class="form-check-input" type="checkbox" id="internationalDev" (change)="checkShipping($event)"
                [checked]="checkShippment" />
            <label class="form-check-label" for="internationalDev">
                <span class=" check-label font-weight-bold ml-3 mr-3">
                    {{translatePath + 'labels.international-dev' | translate}}
                </span>
            </label>
        </div>
    </div>
    <div class="d-flex flex-column flex-sm-row col-12 justify-content-center">
        <!-- country -->
        <ng-container *ngIf="!checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label class="label">{{ translatePath + 'labels.country' | translate }}</label>
                <select *ngIf="!checkShippment" [compareWith]="compareByID" class="form-control align-self-center"
                    formControlName="country" [ngClass]="{
                'is-admin-edit': isAdminEdit,
               'is-invalid': addressForm.get('country')?.invalid && addressForm.get('country')?.touched,
               'is-valid': addressForm.get('country')?.valid && addressForm.get('country')?.touched
            }">
                    <option [disabled]="isAdminEdit" *ngFor="let item of countries" [value]="item"
                        class="dropdown-item">{{ item.value | translate }}</option>
                </select>
                <div class="invalid-feedback" *ngIf="addressForm.get('country')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('country')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('country')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="checkShippment">
            <div class="col-12 col-sm-4 form-group d-flex flex-column justify-self-end"
                [ngClass]="{'col-md-4' : !path}">
                <label class="label">{{ translatePath + 'labels.country' | translate }}</label>
                <select #select *ngIf="checkShippment" (change)="isoCountry(select.value)" [compareWith]="compareByID"
                    class="form-control align-self-center" formControlName="country" [ngClass]="{
                    'is-admin-edit': isAdminEdit,
                   'is-invalid': addressForm.get('country')?.invalid && addressForm.get('country')?.touched,
                   'is-valid': addressForm.get('country')?.valid && addressForm.get('country')?.touched
                }">
                    <option [disabled]="isAdminEdit" *ngFor="let item of countryList" [value]="item.nameCountry"
                        class="dropdown-item">
                        {{ item.nameCountry }}
                    </option>
                </select>
                <div class="invalid-feedback" *ngIf="addressForm.get('country')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('country')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('country')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- country end -->

        <!-- Region  -->
        <ng-container *ngIf="!checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label class="label">
                    {{ translatePath + 'labels.region' | translate }}
                    <i *ngIf="gettingRegions" class="fa fa-spinner fa-spin"></i>
                </label>
                <input *ngIf="!checkShippment" type="text" class="form-control" formControlName="region"
                    autocomplete="new-region" [ngbTypeahead]="searchRegions" [inputFormatter]="formatter"
                    [resultFormatter]="formatter" [editable]="checkShippment" [readOnly]="isAdminEdit" [ngClass]="{
                    'is-admin-edit': isAdminEdit,
                    'form-control': true,
                    'is-invalid': addressForm.get('region')?.invalid && addressForm.get('region')?.touched,
                    'is-valid': addressForm.get('region')?.valid && addressForm.get('region')?.touched}"
                    [placeholder]="translatePath + 'placeholders.enter-region' | translate"
                    (selectItem)="regionSelectChanged($event)" />
                <div class="invalid-feedback" *ngIf="addressForm.get('region')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('region')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('region')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="checkShippment">
            <div class="col-12 col-sm-4 form-group d-flex flex-column justify-self-end" [ngClass]="{'col-md-4' : !path}">
                <div class="label-container d-flex justify-content-between align-items-center">
                    <label class="label">
                        {{ translatePath + 'labels.region' | translate }}
                    </label>
                    <span class="important-star"> </span>
                </div>
                <input *ngIf="checkShippment" type="text" class="form-control" formControlName="region"
                    autocomplete="new-region" [readOnly]="isAdminEdit" [ngClass]="{
                    'is-admin-edit': isAdminEdit,
                    'form-control': true,
                    'is-invalid': addressForm.get('region')?.invalid && addressForm.get('region')?.touched,
                    'is-valid': addressForm.get('region')?.valid && addressForm.get('region')?.touched }"
                    [placeholder]="translatePath + 'placeholders.enter-region' | translate" />
                <div class="invalid-feedback" *ngIf="addressForm.get('region')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('region')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('region')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
            
        </ng-container>
        <!-- Region end -->

        <!-- ProvinceCode-->
        <ng-container *ngIf="checkShippment">
            <div class="col-12 col-sm-4 form-group d-flex flex-column justify-self-end" 
            [ngClass]="{'col-md-4' : !path}">
                <label [ngClass]="{label : !checkShippment}">
                    {{ translatePath + 'labels.provinceCode' | translate }}
                    <span *ngIf="isCustomValidationRequired" class="red-asterisk">*</span> 
                </label>
                <input type="text" class="form-control" formControlName="provinceCode" autocomplete="new-provinceCode"
                    [ngClass]="{
                            'form-control': true,
                            'is-invalid': addressForm.get('provinceCode')?.invalid && addressForm.get('provinceCode')?.touched,
                            'is-valid': addressForm.get('provinceCode')?.valid && addressForm.get('provinceCode')?.touched
                        }" [placeholder]="translatePath + 'placeholders.enter-provinceCode' | translate" />
                <div class="invalid-feedback" *ngIf="addressForm.get('provinceCode')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>

                <div class="invalid-feedback" *ngIf="addressForm.get('provinceCode')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('provinceCode')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="d-flex flex-column  flex-sm-row col-12 justify-content-center">
        <!-- City -->
        <ng-container *ngIf="!checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label class="label">
                    {{ translatePath + 'labels.city' | translate }}
                    <i *ngIf="gettingSettlements" class="fa fa-spinner fa-spin"></i>
                </label>
                <input *ngIf="!checkShippment" type="text" class="form-control align-self-center" autocomplete="new-city"
                    formControlName="city" [ngbTypeahead]="searchSettlements" [inputFormatter]="formatter"
                    [resultFormatter]="formatter" [editable]="checkShippment" [readOnly]="isAdminEdit" [ngClass]="{
                    'is-admin-edit': isAdminEdit,
                   'form-control': true,
                   'is-invalid': addressForm.get('city')?.invalid && addressForm.get('city')?.touched,
                   'is-valid': addressForm.get('city')?.valid && addressForm.get('city')?.touched
                    }" [placeholder]="translatePath + 'placeholders.enter-city' | translate"
                    (selectItem)="settlementSelectChanged($event)" />
                    <div class="invalid-feedback" *ngIf="addressForm.get('city')?.hasError('required')">
                        {{ 'validation-errors.required' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="addressForm.get('city')?.hasError('validationErrors')">
                        <div *ngFor="let item of addressForm.get('city')?.getError('validationErrors')">
                            {{ 'validation-errors.' + item | translate }}
                        </div>
                    </div>
            </div>
        </ng-container>
        
        <ng-container *ngIf="checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label class="label">
                    {{ translatePath + 'labels.city' | translate }}
                    <i *ngIf="gettingSettlements" class="fa fa-spinner fa-spin"></i>
                </label>
                <input *ngIf="checkShippment" type="text" class="form-control align-self-center" autocomplete="new-city"
                    formControlName="city" [readOnly]="isAdminEdit" [ngClass]="{
                    'is-admin-edit': isAdminEdit,
                   'form-control': true,
                   'is-invalid': addressForm.get('city')?.invalid && addressForm.get('city')?.touched,
                   'is-valid': addressForm.get('city')?.valid && addressForm.get('city')?.touched
                    }" [placeholder]="translatePath + 'placeholders.enter-city' | translate" />
    
                <div class="invalid-feedback" *ngIf="addressForm.get('city')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('city')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('city')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>        
        </ng-container>
        <!-- City end -->
        <!-- Street -->
        <ng-container *ngIf="!checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label [ngClass]="{'label' : checkShippment}">
                    {{ translatePath + 'labels.street' | translate }}
                    <i *ngIf="gettingSettlements" class="fa fa-spinner fa-spin"></i>
                </label>
                <input *ngIf="!checkShippment" type="text" class="form-control align-self-center" autocomplete="new-street"
                    formControlName="street" [ngbTypeahead]="searchStreets" [inputFormatter]="formatter"
                    [resultFormatter]="formatter" [ngClass]="{
                   'form-control': true,
                   'is-invalid': addressForm.get('street')?.invalid || addressValidatorHandle,
                   'is-valid': addressForm.get('street')?.valid && addressForm.get('street')?.touched && !addressValidatorHandle
                    }" [placeholder]="translatePath + 'placeholders.enter-street' | translate"
                    (selectItem)="settlementSelectChanged($event)" />
    
                    <div class="invalid-feedback"
                        *ngIf="!checkShippment && addressForm.get('street')?.invalid  || addressValidatorHandle">
                        {{ 'validation-errors.required' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="addressForm.get('street')?.hasError('required')">
                        {{ 'validation-errors.required' | translate }}
                    </div>
                    <div class="invalid-feedback" *ngIf="addressForm.get('street')?.hasError('validationErrors')">
                        <div *ngFor="let item of addressForm.get('street')?.getError('validationErrors')">
                            {{ 'validation-errors.' + item | translate }}
                        </div>
                    </div>
                </div>
        </ng-container>
        
        <ng-container *ngIf="checkShippment">
            <div class="col-12 col-sm-6  form-group d-flex flex-column" [ngClass]="{'col-md-6' : !path}">
                <label [ngClass]="{'label' : checkShippment}">
                    {{ translatePath + 'labels.street' | translate }}
                    <i *ngIf="gettingSettlements" class="fa fa-spinner fa-spin"></i>
                </label>
                <input *ngIf="checkShippment" type="text" class="form-control align-self-center" autocomplete="new-street"
                    formControlName="street" [ngClass]="{
                   'form-control': true,
                   'is-invalid': addressForm.get('street')?.invalid && addressForm.get('street')?.touched,
                   'is-valid': addressForm.get('street')?.valid && addressForm.get('street')?.touched
                }" [placeholder]="translatePath + 'placeholders.enter-street' | translate" />
    
                <div class="invalid-feedback" *ngIf="addressForm.get('street')?.hasError('required')">
                    {{ 'validation-errors.required' | translate }}
                </div>
                <div class="invalid-feedback" *ngIf="addressForm.get('street')?.hasError('validationErrors')">
                    <div *ngFor="let item of addressForm.get('street')?.getError('validationErrors')">
                        {{ 'validation-errors.' + item | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- street end  -->
    </div>
    
    <div class="d-flex flex-column  flex-sm-row col-12 justify-content-center">
        <!-- Block -->
        <div class="col-12 col-sm-4 form-group d-flex flex-column justify-self-end" [ngClass]="{'col-md-4': !path}">
            <label [ngClass]="{'label' : checkShippment}">
                {{ translatePath + 'labels.block' | translate }}
                <i *ngIf="gettingSettlements" class="fa fa-spinner fa-spin"></i>
            </label>
            <input *ngIf="!checkShippment" type="text" class="form-control align-self-center" autocomplete="new-block"
                formControlName="block" maxlength="5" [ngClass]="{
               'form-control': true,
               'is-invalid': addressForm.get('block')?.invalid && addressForm.get('block')?.touched,
               'is-valid': addressForm.get('block')?.valid && addressForm.get('block')?.touched
            }" [placeholder]="translatePath + 'placeholders.enter-block' | translate" />
            
            <input *ngIf="checkShippment" type="text" class="form-control align-self-center" autocomplete="new-block"
                formControlName="block" maxlength="5" [ngClass]="{
               'form-control': true,
               'is-invalid': addressForm.get('block')?.invalid && addressForm.get('block')?.touched,
               'is-valid': addressForm.get('block')?.valid && addressForm.get('block')?.touched
            }" [placeholder]="translatePath + 'placeholders.enter-block' | translate" />

            <div class="invalid-feedback" *ngIf="addressForm.get('block')?.hasError('required')">
                {{ 'validation-errors.required' | translate }}
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('block')?.hasError('validationErrors')">
                <div *ngFor="let item of addressForm.get('block')?.getError('validationErrors')">
                    {{ 'validation-errors.' + item | translate }}
                </div>
            </div>
        </div>
        <!-- Block end -->

        <!-- Apartment -->
        <div class="col-12 col-sm-4 form-group d-flex flex-column justify-self-end">
            <label>{{ translatePath + 'labels.apartment' | translate }}</label>
            <input formControlName="apartment" maxlength="5" [ngClass]="{
               'is-invalid': addressForm.get('apartment')?.invalid && addressForm.get('apartment')?.touched,
               'is-valid': addressForm.get('apartment')?.valid && addressForm.get('apartment')?.touched
            }" class="form-control ap align-self-center"
                placeholder="{{ translatePath + 'placeholders.enter-apartment' | translate }}" type="text" />
            <div class="invalid-feedback" *ngIf="addressForm.get('apartment')?.hasError('required')">
                {{ 'validation-errors.required' | translate }}
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('apartment')?.hasError('validationErrors')">
                <div *ngFor="let item of addressForm.get('apartment')?.getError('validationErrors')">
                    {{ 'validation-errors.' + item | translate }}
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('apartment')?.hasError('apartment')">
                {{'validation-errors.invalid-caracter' | translate}}
            </div>
        </div>
        <!-- Apartment end -->
        <!-- Zip -->
        <div class="class ap-zip col-12 col-sm-4 form-group d-flex flex-column" *ngIf="!checkShippment">
            <label class="label">
                {{ translatePath + 'labels.zip' | translate }}
                <i *ngIf="zipValidating" class="fa fa-spinner fa-spin"></i>
            </label>
            <input (input)="zipInputChanged($event)" [showMaskTyped]="true" prefix="MD-" mask="0000"
                formControlName="zipCode" [ngClass]="{
               'is-invalid': addressForm.get('zipCode')?.invalid && addressForm.get('zipCode')?.touched,
               'is-valid': addressForm.get('zipCode')?.valid
            }" class="form-control zip justify-self-end align-self-end"
                placeholder="{{ translatePath + 'placeholders.enter-zip' | translate }}" type="text" />


            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('required')">
                {{ 'validation-errors.required' | translate }}
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('validationErrors')">
                <div *ngFor="let item of addressForm.get('zipCode')?.getError('validationErrors')">
                    {{ 'validation-errors.' + item | translate }}
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('mask')">
                {{ 'validation-errors.invalid-format' | translate }}
            </div>
        </div>


        <div class="class ap-zip col-12 col-sm-4 form-group d-flex flex-column" *ngIf="checkShippment">
            <label class="label">
                {{ translatePath + 'labels.zip' | translate }}
                <i *ngIf="zipValidating" class="fa fa-spinner fa-spin"></i>
            </label>

            <input formControlName="zipCode" [ngClass]="{
               'is-invalid': addressForm.get('zipCode')?.invalid && addressForm.get('zipCode')?.touched,
               'is-valid': addressForm.get('zipCode')?.valid
            }" class="form-control zip" placeholder="{{ translatePath + 'placeholders.enter-zip' | translate }}"
                type="text" />

            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('required')">
                {{ 'validation-errors.required' | translate }}
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('validationErrors')">
                <div *ngIf="addressForm.get('zipCode')?.getError('validationErrors')">
                    {{ addressForm.get('zipCode')?.getError('validationErrors')}}
                </div>
            </div>
            <div class="invalid-feedback" *ngIf="addressForm.get('zipCode')?.hasError('validationErrors')">
                <div *ngIf="addressForm.get('zipCode')?.getError('validationErrors')">
                    {{'messages.zip' | translate}}
                </div>
            </div>
        </div>
        <!-- Zip End -->
    </div>
</div>