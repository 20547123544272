import { AbstractControl, ValidationErrors } from "@angular/forms";

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return emailRegExp.test(control.value) ? null : { email: control.value };
}

export function emailShippingValidator(control: AbstractControl): ValidationErrors | null | boolean {
  const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  if (control.value === "") {
    return null;
  }

  if (control.value !== '') {
    return emailRegExp.test(control.value) ? null : { email: control.value };
  } else {
    return null;
  }
}

export function streetShippingValidator(control: AbstractControl): ValidationErrors | null | boolean {

  return true;

}

export function specialBlockCaracterValidator(control: AbstractControl): ValidationErrors | null {

  if (control?.value?.toString().trim() === "<" || control?.value?.toString().trim() === ">") {
    return { block: control.value };;
  }
  return null;

}

export function specialStreetCaracterValidator(control: AbstractControl): ValidationErrors | null {
  if (
    control?.value?.toString().trim() === "<"
    || control?.value?.toString().trim() === ">"
    || control?.value?.value?.toString().trim() === "<"
    || control?.value?.value?.toString().trim() === ">") {
    return { street: control.value };;
  }
  return null;

}

export function specialApartmentCaracterValidator(control: AbstractControl): ValidationErrors | null {

  if (control?.value?.toString().trim() === "<" || control?.value?.toString().trim() === ">") {
    return { apartment: control.value };;
  }
  return null;

}
