<div class="items py-2" [formGroup]="supportContacts">
 <div class="d-flex justify-content-center mb-4 mt-1 pl-4">
   <h5 class="col-10">{{'general.supportContacts.title' | translate}}</h5>
 </div>
  <div class="d-flex flex-column flex-sm-row justify-content-center">
    <div class="name form-group col-12 pl-xl-0">
      <label for="emailId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(supportContacts.get('email')) }">{{'general.supportContacts.labels.email' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="emailId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(supportContacts.get('email')) , 'is-invalid': supportContacts.get('email')?.invalid && supportContacts.get('email')?.touched,
               'is-valid': supportContacts.get('email')?.valid && supportContacts.get('email')?.touched }"
        formControlName="email" aria-describedby="email" placeholder="Ex: john.doe@mail.com">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('email'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('email'), 'email')">{{'general.errorTexts.email' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('email'), 'validationErrors')">
        <div *ngFor="let item of supportContacts.get('email')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
  </div>
  <div class="d-flex flex-column flex-sm-row justify-content-center">
    <div class="name form-group col-12 col-md-6 pr-xl-0">
      <label for="phoneId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(supportContacts.get('phoneNumber')) }">{{'general.supportContacts.labels.phoneNumber' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="phoneId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(supportContacts.get('phoneNumber')) ,
               'is-invalid': supportContacts.get('phoneNumber')?.invalid && supportContacts.get('phoneNumber')?.touched,
               'is-valid': supportContacts.get('phoneNumber')?.valid && supportContacts.get('phoneNumber')?.touched }"
        formControlName="phoneNumber" aria-describedby="type" placeholder="Ex: +373 60232353">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('phoneNumber'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('phoneNumber'), 'phoneNumber')">{{'general.errorTexts.phone' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('phoneNumber'), 'validationErrors')">
        <div *ngFor="let item of supportContacts.get('phoneNumber')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
    <div class="name form-group col-12 col-md-6 pr-xl-0">
      <label for="phoneId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(supportContacts.get('mobilePhone')) }">{{'general.supportContacts.labels.mobilePhone' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="phoneId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(supportContacts.get('mobilePhone')) ,
               'is-invalid': supportContacts.get('mobilePhone')?.invalid && supportContacts.get('mobilePhone')?.touched,
               'is-valid': supportContacts.get('mobilePhone')?.valid && supportContacts.get('mobilePhone')?.touched }"
        formControlName="mobilePhone" aria-describedby="type" placeholder="Ex: +373 60232353">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('mobilePhone'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('mobilePhone'), 'mobilePhone')">{{'general.errorTexts.phone' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(supportContacts.get('mobilePhone'), 'validationErrors')">
        <div *ngFor="let item of supportContacts.get('mobilePhone')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
  </div>
</div>
