import { PickupPointModel } from "./pickup-point.model";
import { ProductModel } from "./product.model";
import { ServiceModel } from "./service.model";

export class ServiceProviderResponseModel {
  id: string;
  idno: string;
  name: string;
  isOrderInsertedAndPaid: boolean;
  service: ServiceModel;
  products:Array<ProductModel>;
  pickupPoint: PickupPointModel
  integrationType: any;
}
