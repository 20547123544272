<nav class="side-nav" data-spy="affix" data-offset-top="100">
  <!-- Butonul de cavigare spre pagina precedenta -->
  <a href="javascript:void(0)" (click)="back()" class="go-back">
    <i class="fas fa-chevron-left mr-3"></i>
    <span>{{'menu-items.back' | translate}}</span>
  </a>
  <!-- Lista de navigare -->
  <ul *ngIf="menuList">
    <li class="ml-4" *ngFor="let item of menuList">
      <a class="side-nav-item"
        [routerLink]="[ item.link ]">{{'menu-items.'+ item.label | translate}}</a>
    </li>
  </ul>
</nav>
