<div class="items py-2" [formGroup]="contactPerson">
 <div class="d-flex justify-content-center mb-4 mt-1 pl-4">
   <h5 class="col-10">{{'general.contactPerson.title' | translate}}</h5>
 </div>
  <div class="d-flex flex-column flex-sm-row justify-content-center">
    <div class="name form-group col-12 col-md-5 col-sm-6 pl-xl-0">
      <label for="firstNameId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(contactPerson.get('firstName')) }">{{'general.contactPerson.labels.firstName' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="firstNameId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(contactPerson.get('firstName')) , 'is-invalid': contactPerson.get('firsName')?.invalid && contactPerson.get('firstName')?.touched,
               'is-valid': contactPerson.get('firstName')?.valid && contactPerson.get('firstName')?.touched }"
        formControlName="firstName" aria-describedby="firstName" placeholder="Ex: John">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('firstName'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('firstName'), 'validationErrors')">
        <div *ngFor="let item of contactPerson.get('firstName')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
    <div class="name form-group col-12 col-md-5 col-sm-6 pr-xl-0">
      <label for="lastNameId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(contactPerson.get('lastName')) }">{{'general.contactPerson.labels.lastName' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="lastNameId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(contactPerson.get('lastName')), 'is-invalid': contactPerson.get('lastName')?.invalid && contactPerson.get('lastName')?.touched,
               'is-valid': contactPerson.get('lastName')?.valid && contactPerson.get('lastName')?.touched }"
        formControlName="lastName" aria-describedby="lastName" placeholder="Ex: Doe">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('lastName'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('lastName'), 'validationErrors')">
        <div *ngFor="let item of contactPerson.get('lastName')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
  </div>
  <div class="d-flex flex-column flex-sm-row justify-content-center">
    <div class="name form-group col-12 col-md-5 col-sm-6 pl-xl-0">
      <label for="emailId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(contactPerson.get('email')) }">{{'general.contactPerson.labels.email' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="emailId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(contactPerson.get('email')) , 'is-invalid': contactPerson.get('email')?.invalid && contactPerson.get('email')?.touched,
               'is-valid': contactPerson.get('email')?.valid && contactPerson.get('email')?.touched }"
        formControlName="email" aria-describedby="email" placeholder="Ex: john.doe@mail.com">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('email'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('email'), 'email')">{{'general.errorTexts.email' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('email'), 'validationErrors')">
        <div *ngFor="let item of contactPerson.get('email')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
    <div class="name form-group col-12 col-md-5 col-sm-6 pr-xl-0">
      <label for="phoneId"
        [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(contactPerson.get('phone')) }">{{'general.contactPerson.labels.phone' | translate}}
        <span class="text-danger">*</span></label>
      <input type="text" class="form-control" id="phoneId"
        [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(contactPerson.get('phone')) , 'is-invalid': contactPerson.get('phone')?.invalid && contactPerson.get('phone')?.touched,
               'is-valid': contactPerson.get('phone')?.valid && contactPerson.get('phone')?.touched }"
        formControlName="phone" aria-describedby="type" placeholder="Ex: +373 60232353">
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('phone'), 'required')">{{'general.errorTexts.required' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('phone'), 'phone')">{{'general.errorTexts.phone' | translate}}</small>
      <small class="text-danger"
        *ngIf="errorCheckerUtil.hasSpecificError(contactPerson.get('phone'), 'validationErrors')">
        <div *ngFor="let item of contactPerson.get('phone')?.getError('validationErrors')">
          {{'validation-errors.'+item | translate}}
        </div>
      </small>
    </div>
  </div>
</div>
