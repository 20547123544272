import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes =
  [
    { path: '', redirectTo: 'public', pathMatch: 'full' },
    { path: 'public', loadChildren: () => import('./public-module/public.module').then(m => m.PublicModule) },
    { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate:[AuthGuard]},
    { path: '**', redirectTo: '', pathMatch: 'full' }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
