<footer>
   <div class="page-wrapper container-fluid pt-4">
      <div class="row">
         <div class="col-lg-3 col-md-4">
            <div class="d-flex flex-column">
               <a
                  target="_blank"
                  class="text-white d-flex align-items-center mb-3 gov-logo-container"
                  href="https://gov.md"
               >
                  <img
                     src="./../../../assets/images/logo-gov.png"
                     class="mr-2"
                     width="21"
                     alt="Guvernul Republicii Moldova"
                  />
                  <span>{{ translatePath + 'government' | translate }}</span>
               </a>
               <a [routerLink]="['/public/landing-page']">
                  <img
                     src="./../../../assets/images/logo-mdelivery.svg"
                     class="footer-logo mb-4"
                     width="800"
                     height="45"
                     title="Product Logo"
                  />
               </a>
            </div>
         </div>
         <div class="col-lg-5 col-md-8">
            <div class="footer-links container-fluid">
               <div class="row">
                  <div class="col links-group pl-0">
                     <ul>
                        <li>
                           <a routerLink="/public/about-service" class="info-links"
                              >{{ translatePath + 'about' | translate }}
                           </a>
                        </li>
                        <li>
                           <a [routerLink]="['/public/terms-and-conditions']" class="info-links"
                              >{{ translatePath + 'terms-and-co' | translate }}
                           </a>
                        </li>
                        <li>
                           <a [routerLink]="['/public/faq']" class="info-links">{{
                              translatePath + 'faq' | translate
                           }}</a>
                        </li>
                     </ul>
                  </div>
                  <div class="col links-group pl-0">
                     <ul>
                        <li>
                           <a [routerLink]="['/public/contacts']" class="info-links">{{
                              translatePath + 'contacts' | translate
                           }}</a>
                        </li>
                        <li>
                           <a href="" routerLink="/public/integration" class="info-links"
                              >{{ translatePath + 'integration' | translate }}
                           </a>
                        </li>
                        <li>
                           <!--                           <a href="./../../../assets/doc/ghidul_utilizatorului.pdf" download class="info-links"-->
                           <!--                              >{{ translatePath + 'instruction' | translate }}-->
                           <!--                           </a>-->
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="footer-right d-flex mb-3">
               <div class="support-info flex-column mb-4">
                  <span class="small-text text-gray pb-1">{{ translatePath + 'support' | translate }}</span>
                  <span class="support-phone mb-2">
                     <img class="mb-1 mr-1" src="./../../../assets/images/phone.svg" />079 207 017</span
                  >
                  <span class="support-phone">
                     <img class="mb-1 mr-1" src="./../../../assets/images/phone.svg" />022 820 023</span
                  >
                  <span>
                     <a href="mailto:suport.mdelivery@egov.md">
                        <i class="fas fa-envelope mb-1 mr-1"></i> suport.mdelivery@egov.md
                     </a>
                  </span>
               </div>
               <div class="d-flex align-items-center">
                  <div class="footer-egov-logo pr-3">
                     <a target="_blank" class="footer-egov-logo__link" href="https://egov.md/ro">
                        <img
                           src="./../../../assets/images/logo-egov-white.svg"
                           width="80"
                           height="40"
                           alt="egov-logo"
                           title="eGov"
                        />
                     </a>
                  </div>
                  <ul class="footer-social-links d-inline-flex m-0">
                     <li>
                        <a
                           target="_blank"
                           href="https://www.facebook.com/eGovernanceAgency.Moldova"
                           class="d-flex align-items-center"
                           title="Facebook"
                        >
                           <i class="fab fa-facebook-f m-auto"></i>
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href="https://twitter.com/eGovCenterMD"
                           class="d-flex align-items-center"
                           title="Twitter"
                        >
                           <i class="fab fa-twitter m-auto"></i>
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href="https://www.youtube.com/channel/UCQiYfbOzuf2A4GH2R_inZfQ"
                           class="d-flex align-items-center"
                           title="Youtube"
                        >
                           <i class="fab fa-youtube m-auto"></i>
                        </a>
                     </li>
                     <li>
                        <a
                           target="_blank"
                           href="https://www.linkedin.com/company/e-government-center/"
                           class="d-flex align-items-center"
                           title="Linked In"
                        >
                           <i class="fab fa-linkedin-in m-auto"></i>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="footer-bottom info-links p-3">&copy; {{ 'footer.copyright' | translate }} {{ currentYear }}</div>
</footer>
