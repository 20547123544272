<div class="modal-header">
   <h4 class="modal-title">{{ 'select-context-modal.select' | translate }}</h4>
   <button type="button" class="close" aria-label="Close" *ngIf="canClose" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>
<div id="selectRole" class="d-flex align-items-center flex-column modal-body">
   <button
      type="button"
      *ngFor="let context of contexts"
      class="btn btn-outline-primary btn-block col-12 text-left text-capitalize"
      (click)="selectContext(context)"
   >
      <div>
         <div>
            <span>
               {{ context.properties.profileDecoded }}
            </span>
         </div>
         <div class="role-details">
            {{ context.properties.name }} (<span>{{ context.properties.isLegal ? 'IDNO' : 'IDNP' }}</span>
            {{ context.properties.idn }})
         </div>
      </div>
   </button>
</div>
