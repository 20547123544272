<div class="page-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 py-3">
        <div class="ml-3">
          <app-navigation-list [navigationList]="navList"></app-navigation-list>
        </div>
        <div class="parent bg-white mb-4 col-12 mr-auto ml-auto">
          <i *ngIf="loading" class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <form [formGroup]="typeOfProductFormGroup" class="text-left">
            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-6">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('code')) }"
                       for="codeID">{{'general.typeOfProducts.labels.productCode' | translate}}
                  <span class="text-danger">*</span></label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('code')) , 'is-invalid' : typeOfProductFormGroup.get('code')?.invalid && typeOfProductFormGroup.get('code')?.touched ,
                   'is-valid' : typeOfProductFormGroup.get('code')?.valid }" aria-describedby="code" class="form-control"
                       formControlName="code"
                       id="codeID" placeholder="Ex: 7362873829403284" type="text">
              </div>
              <div class="form-group col-6">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('name')) }"
                       for="name">{{'general.typeOfProducts.labels.productName' | translate}}
                  <span class="text-danger">*</span></label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('name')) , 'is-invalid' : typeOfProductFormGroup.get('name')?.invalid && typeOfProductFormGroup.get('name')?.touched ,
                   'is-valid' : typeOfProductFormGroup.get('name')?.valid }" aria-describedby="name" class="form-control"
                       formControlName="name"
                       id="name" placeholder="{{'general.pickUpPoints.labels.custom-name' | translate}}"
                       type="text">
              </div>
            </div>

            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-12">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('nameEN')) }"
                       for="nameEN">{{'general.typeOfProducts.labels.productNameEn' | translate}}
                  <span class="text-danger">*</span></label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('nameEN')) , 'is-invalid' : typeOfProductFormGroup.get('nameEN')?.invalid && typeOfProductFormGroup.get('nameEN')?.touched ,
                   'is-valid' : typeOfProductFormGroup.get('nameEN')?.valid }" aria-describedby="nameEN" class="form-control"
                       formControlName="nameEN"
                       id="nameEN" placeholder="{{'general.pickUpPoints.labels.custom-name' | translate}}"
                       type="text">
              </div>
            </div>

            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-3">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup?.get('length')) }"
                       for="lengthId">{{'general.typeOfProducts.labels.length' | translate}}
                </label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup?.get('length'))}" aria-describedby="length" class="form-control"
                       formControlName="length"
                       id="lengthId" placeholder="Ex: 13" type="number">
              </div>
              <div class="form-group col-3">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('width')) }"
                       for="widthId">{{'general.typeOfProducts.labels.width' | translate}}
                </label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('width'))}" aria-describedby="width" class="form-control"
                       formControlName="width"
                       id="widthId" placeholder="Ex: 110" type="number">
              </div>
              <div class="form-group col-3">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('height')) }"
                       for="heightID">{{'general.typeOfProducts.labels.height' | translate}}
                </label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('height'))}" aria-describedby="height" class="form-control"
                       formControlName="height"
                       id="heightID" placeholder="Ex: 90" type="number">
              </div>
              <div class="form-group col-3">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('weight')) }"
                       for="wightId">{{'general.typeOfProducts.labels.weight' | translate}}
                </label>
                <input [ngClass]="{'border border-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('weight'))}" aria-describedby="weight" class="form-control"
                       formControlName="weight"
                       id="wightId" placeholder="Ex: 1" type="number">
              </div>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <div class="form-group col-12">
                <label [ngClass]="{'text-danger' : errorCheckerUtil.isInvalid(typeOfProductFormGroup.get('serviceId')) }"
                       for="serviceId">{{'general.typeOfProducts.labels.serviceId' | translate}}
                  <span class="text-danger">*</span></label>
                <select [ngClass]="{ 'is-invalid' : typeOfProductFormGroup.get('serviceId')?.invalid && typeOfProductFormGroup.get('serviceId')?.touched ,
                   'is-valid' : typeOfProductFormGroup.get('serviceId')?.valid}" class="form-control w-100" formControlName="serviceId" id="serviceId"
                        id="staticSelect01">
                  <option *ngFor="let item of services" [value]="item.id" selected>{{item.name}}</option>
                </select>
              </div>
            </div>

            <hr>

            <div class="buttons-group col-12 d-flex justify-content-xl-end justify-content-center ml-auto mr-auto">
              <button [routerLink]="['/admin/sp/details', spId]" class="mr-2 btn btn-outline-info"
                      type="button">{{'admin.buttons.cancelButton' | translate}}</button>
              <button (click)="submit()" [disabled]="inRequest"
                      class="btn btn-outline-info" type="submit">{{'admin.buttons.saveButton' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
