import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Pagination} from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaginationGeneratorService {

  generatePagination(pagination: Pagination): HttpParams {
    let params = new HttpParams();
    params = pagination.page ? params.append('Page', pagination.page.toString()) : params;
    params = pagination.itemsPerPage ? params.append('ItemsPerPage', pagination.itemsPerPage.toString()) : params;
    params = pagination.orderField ? params.append('OrderField', pagination.orderField) : params;
    params = pagination.searchBy ? params.append('SearchBy', pagination.searchBy.toString()) : params;
    return params;
  }
}
