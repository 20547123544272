import { Component, Input, OnInit } from '@angular/core';
import { DisableConfirmModalComponent } from '../disable-confirm-modal/disable-confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from '../../services/services.service';
import { ServiceModel } from '../../models/service.model';
import { Pagination } from '../../models';
import { Paged } from '../../models/paged.model';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FeedbackService } from '../../services';

@Component({
  selector: 'app-provider-services-list',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  @Input() spId: string;

  services: ServiceModel[];
  pageNumber: string = "servicePageNumber";
  paged: Paged;
  query = {
    itemsPerPage: 10,
    page: 1
  } as Pagination;
  loading: boolean;

  constructor(
    private servicesService: ServicesService,
    private modalService: NgbModal,
    private feedbackService: FeedbackService
  ) {
  }

  ngOnInit(): void {
    this.query.page = JSON.parse(localStorage.getItem(this.pageNumber) ?? "1") as number;
    this.getServicesData();
  }

  getServicesData(): void {
    if (this.spId) {
      this.loading = true;
      localStorage.setItem(this.pageNumber, this.query.page.toString());
      this.servicesService.getServicesBySp(this.query, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {

          this.services = res.items;
          this.paged = res.paged;
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
    }
  }

  deactivateService(serv: ServiceModel): void {
    const modalRef = this.modalService.open(DisableConfirmModalComponent);
    modalRef.componentInstance.obj = serv;
    modalRef.result.then((response: string) => {
      if (response === 'confirmed') {
        this.disablePickupPoint(serv);
      }
    });
  }

  disablePickupPoint(serv: ServiceModel): void {
    this.servicesService.disableEnableService(this.spId, serv).subscribe(() => {
      this.feedbackService.handleSuccess('change-pickup-point-success');
      this.getServicesData();
    }, (err: HttpErrorResponse) => {
      this.feedbackService.handleError(err);
    });
  }
}


