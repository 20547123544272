import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-cancel-modal',
  templateUrl: 'confirm-cancel-modal.component.html',
})
export class ConfirmCancelModalComponent {

  constructor(public activeModal: NgbActiveModal) {
  }
}
