import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AccountLinkGeneratorService {
  api = this.appConfigService.appConfig.SERVER_URL;
  baseUrl = this.appConfigService.appConfig.APP_BASE_URL;
  constructor(
    public http: HttpClient,
    protected appConfigService: AppConfigService
  ) { }

  getPassiveUrl(successUrl: string, failedUrl: string, lang: string | null = '', returUrl: string | null) {
    return `${this.api}/api/v1.0/pub/account/PassiveLogin?returnUrl=${escape(`${successUrl}`)}&failedUrl=${escape(`${failedUrl}`)}&lang=${lang}&returUrl=${returUrl}`;
  }


  getUrl(returnUrl: string ): string {
    return `${this.api}/account/login?returnUrl=${escape(`${returnUrl}`)}`;
  }


  getLoginUrl(returnUrl = this.baseUrl): Observable<string> {
    const body = { returnUrl: returnUrl };
    return  this.http.post(`${this.api}/api/v1.0/pub/account/login`, body, {
      responseType: 'text'
    });
  }

  getLogoutUrl(returnUrl = this.baseUrl): string {
    return `${this.api}/api/v1.0/pub/account/logout?returnUrl=${escape(`${returnUrl}`)}`;
  }

  getOrderConfirmationUrl(deliveryId: string): string {
    return `/public/shipping/order-confirmation/${deliveryId}`;
  }
}
