import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Payment, PaymentUrlResponse } from '../models';
import { OrderConfirmation } from '../models/order-confirmation.model';
import { AccountLinkGeneratorService } from './account-link-generator.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/pub`;
  baseUrl = this.appConfigService.appConfig.APP_BASE_URL;
  constructor(
    public appConfigService: AppConfigService,
    private http: HttpClient,
    private accountLinkGeneratorService: AccountLinkGeneratorService
  ) { }
  saveDelivery(paymentModel: Payment, isLocal: boolean): Observable<string> {
    // @ts-ignore
    !isLocal ? (paymentModel.internationalAddress = paymentModel.address, paymentModel.address = null) : paymentModel.internationalAddress = null;
    // @ts-ignore
    return this.http.post<string>(`${this.api}/delivery`, paymentModel);
  }

  getDeliveryById(deliveryId: string): Observable<OrderConfirmation> {
    return this.http.get<OrderConfirmation>(`${this.api}/delivery/${deliveryId}`);
  }

  getTrackingById(deliveryId: string): Observable<OrderConfirmation> {
    return this.http.get<OrderConfirmation>(`${this.api}/delivery/${deliveryId}/tracking`);
  }

  getPayData(deliveryId: string, isPaid: any): Observable<PaymentUrlResponse> {
    const returnUrl = this.accountLinkGeneratorService.getOrderConfirmationUrl(deliveryId);

    return this.http.get<PaymentUrlResponse>(`${this.api}/pay?deliveryId=${deliveryId}&isServicePaid=${isPaid}&redirectUrl=${returnUrl}`);
  }


}
