import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarrierServiceModel, PagedResponse, Pagination, Tariff } from '../models';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class TariffService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;
  namespace = `/car/carrierdelivery`;
  url = `${this.api}${this.namespace}`;
  constructor(
    private appConfigService: AppConfigService,
    private http: HttpClient,
    private paginationGenerator: PaginationGeneratorService
  ) { }

  getTariffList(pagination: Pagination): Observable<PagedResponse<Tariff>> {
    const params = this.paginationGenerator.generatePagination(pagination);
    return this.http.get<PagedResponse<Tariff>>(`${this.url}/price-list`, { params });
  }

  getTariff(id: string): Observable<Tariff> {
    return this.http.get<any>(`${this.url}/price-list/${id}`);
  }

  saveTariff(tariff: Tariff): Observable<any> {
    return this.http.post<any>(`${this.url}/price-list`, tariff);
  }

  updateTariff(tariff: Tariff, id: string): Observable<any> {
    return this.http.patch<any>(`${this.url}/price-list/${id}`, tariff);
  }

  deleteTariff(id: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/price-list/${id}`);
  }

  activateTariff(id: string): Observable<any> {
    return this.http.patch<any>(`${this.url}/price-list/${id}/activate`, {});

  }

  getServiceList(pagination: Pagination): Observable<PagedResponse<CarrierServiceModel>> {
    const params = this.paginationGenerator.generatePagination(pagination);
    return this.http.get<PagedResponse<CarrierServiceModel>>(`${this.url}/service`, { params });
  }

  editServiceName(name: string, id: string): Observable<any> {
    return this.http.patch(`${this.url}/service/${id}`, { name });
  }

  addService(name: string): Observable<any> {
    return this.http.post(`${this.url}/service/`, { name });
  }

  activateService(id: string) {
    return this.http.patch(`${this.url}/service/${id}/activate`, {});
  }

  deactivate(id: string) {
    return this.http.delete(`${this.url}/service/${id}`);
  }
}
