
<nav aria-label="Page navigation">
  <ul class="pagination justify-content-center">
    <li class="page-item" [ngClass]="{'disabled': !paged.hasPrevious}">
      <a class="page-link d-flex justify-content-center align-items-center"  (click)="onPageClickedMinus(paged.currentPage - 1)" aria-label="Previous">
        <span class="arrow-text mr-3">{{'admin.pagination.previous' | translate}}</span>
        <i  aria-hidden="true"><<</i>
      </a>
    </li>
    <li class="page-item mr-1 ml-1" *ngFor="let page of totalPages">
      <a class="page-link d-flex align-items-center justify-content-center" [ngClass]="{'active': page == paged.currentPage}" style="width: 28px; height: 28px" (click)="onPageClicked(page)"><span style="color: black">{{page}}</span></a>
    </li>
    <li class="page-item" [ngClass]="{'disabled': !paged.hasNext}">
      <a  class="page-link d-flex justify-content-center align-items-center" (click)="onPageClicked(paged.currentPage + 1 )" aria-label="Next">
        <i aria-hidden="true"> >> </i>
        <span class="arrow-text ml-3">{{'admin.pagination.next' | translate}}</span>
      </a>
    </li>
  </ul>
</nav>
