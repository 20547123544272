import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfigModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  public appConfig: AppConfigModel = new AppConfigModel();
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }
  async loadAppConfig(): Promise<any> {
    const jsonPath = './../../../assets/config/appsettings.json';
    return this.http.get<AppConfigModel>(jsonPath)
      .toPromise()
      .then((data: AppConfigModel) => {
        this.appConfig = data;
        localStorage.setItem('recapca', this.appConfig.RECAPTCHA_KEY);
      })
      .catch(_ => {
        this.appConfig = environment as any;
        return Promise.resolve(this.appConfig);
      });
  }

  public get config(): AppConfigModel {
    return this.appConfig;
  }
}
