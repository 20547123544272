import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {emailValidator} from '../../validators/email.validator';
import {FormErrorsChecker} from '../../utils/formErrorsChecker';
import {phoneValidator} from '../../validators/phone.validator';

@Component({
  selector: 'app-contact-person-form-group',
  templateUrl: './contact-person-inputs.component.html',
  styleUrls: ['./contact-person-inputs.component.scss']
})
export class ContactPersonInputsComponent implements OnInit {

  @Output() outputForm = new EventEmitter<any>();

  @Input() contactPerson = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, emailValidator]),
    phone: new FormControl('', [Validators.required, phoneValidator])
  });
  ignoreDateUpdates = false;
  errorCheckerUtil = new FormErrorsChecker();

  constructor() {
  }

  ngOnInit(): void {
    this.contactPerson.valueChanges.subscribe((value) => {
      if (this.ignoreDateUpdates) {
        this.ignoreDateUpdates = false;
        return;
      }
      this.ignoreDateUpdates = true;
      this.outputForm.emit(value);
    });
  }
}
