export class BoardProperties{
  isLegal!: boolean;
  idn!: number;
  name!: string;
  description!: string;
  role!: string;
  profile!: string;
  profileDecoded!: string;
}
export class ContextModel {
  boardType!:string;
  identifier!: string;
  properties!: BoardProperties;
}
