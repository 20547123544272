import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-disable-modal',
  templateUrl: 'disable-confirm-modal.component.html',
  styleUrls: ['./disable-confirm-modal.component.scss']
})
export class DisableConfirmModalComponent {
  constructor(public activeModal: NgbActiveModal) {
  }
  @Input() obj: { isDeleted: boolean, name: string, id: string };
  @Input() title: string;
  @Input() delete = false;
  @Input() isOrderStatus = false;
  reason: string = "";
  formGroup: FormGroup = new FormGroup({
    reason: new FormControl(this.reason, [Validators.required]),
  });
  get reasonValidate() { return this.formGroup.get('reason'); }
  submit(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (!this.formGroup.invalid) {
      this.activeModal.close('confirmed')
    }
  }
}
