import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FormErrorsChecker} from '../../../utils/formErrorsChecker';
import {ProductService} from '../../../services/product.service';
import {ProductModel} from '../../../models/product.model';
import {PickupPointModel} from 'src/app/shared/models/pickup-point.model';
import {finalize} from 'rxjs/operators';
import {ServicesService} from 'src/app/shared/services/services.service';
import {Pagination} from 'src/app/shared/models';
import {ServiceModel} from 'src/app/shared/models/service.model';
import {HttpErrorResponse} from '@angular/common/http';
import {FeedbackService} from 'src/app/shared/services';

@Component({
  selector: 'app-product-add-edit',
  templateUrl: './product-add-edit.component.html',
  styleUrls: ['./product-add-edit.component.scss']
})
export class ProductAddEditComponent implements OnInit {
  navList: any;
  prodId: string;
  spId: string;

  inRequest = false;

  typeOfProductFormGroup: FormGroup;

  errorCheckerUtil = new FormErrorsChecker();

  query = {
    page: 1,
    itemsPerPage: 1000
  } as Pagination;

  currentProduct: ProductModel;

  dropdownList: PickupPointModel[];
  dropdownSettings: any;
  loading: boolean;
  services: ServiceModel[];

  constructor(private productService: ProductService,
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private feedbackService: FeedbackService
  ) {
    this.typeOfProductFormGroup = this.fb.group({
      code: ['', [Validators.required]],
      name: ['', [Validators.required]],
      nameEN: ['', [Validators.required]],
      length: [0],
      width: [0],
      height: [0],
      weight: [0],
      serviceId: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.prodId = this.route.snapshot.params['prodId'];
    this.spId = this.route.snapshot.params['id'];
    this.getProductData();

    this.navList = [
      { url: '.', translatedText: 'general.typeOfProducts.editProduct', link: `/admin/sp/details/${this.spId}` },
    ];
  }
  getProductData(): void {
    if (this.prodId && this.spId) {
      this.loading = true;
      this.productService.getProductById(this.prodId, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {
          this.currentProduct = res;
          this.integrateData();
          this.getServiceList()
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        }
        );
    } else {
      this.getServiceList()
    }
  }

  getServiceList() {
    if (this.spId) {
      this.servicesService.getServicesBySp(this.query, this.spId)
        .pipe(
          finalize(
            () => { this.loading = false }
          )
        )
        .subscribe(res => {
          this.services = res.items;
        }, (err: HttpErrorResponse) => {
          this.feedbackService.handleError(err);
        });
    }
  };

  integrateData(): void {
    this.typeOfProductFormGroup.patchValue(this.currentProduct);
  }

  submit(): void {
    this.typeOfProductFormGroup.markAllAsTouched();
    if (!this.typeOfProductFormGroup.invalid) {
      const prodFinal = { ...this.currentProduct, ...this.typeOfProductFormGroup.value };

      this.inRequest = true;
      this.productService.createOrUpdateProduct(prodFinal, this.spId)
        .subscribe(
          () => {
            this.feedbackService.handleSuccess('save-product-data-success');
            this.inRequest = false;
            this.router.navigate(['/admin/sp/details', this.spId]);
          }, (err: HttpErrorResponse) => {
            this.feedbackService.handleError(err, this.typeOfProductFormGroup);
          });
    }
  }
}
