<ngb-toast class="wide-toast" *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
  [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
  <div [ngSwitch]="toast.type" class="header">
    <span *ngSwitchCase="feedbackTypeEnum.Success">
      <i class="mr-3 fas fa-check"></i>
      <strong>{{'messages.banner-titles.success' |translate}}</strong>
    </span>
    <span *ngSwitchCase="feedbackTypeEnum.Error">
      <i class="mr-3 fas fa-times-circle"></i>
      <strong>{{'messages.banner-titles.error'|translate}}</strong>
    </span>
    <span *ngSwitchCase="feedbackTypeEnum.Warning">
      <i class="mr-3 fas fa-exclamation-triangle"></i>
      <strong>{{'messages.banner-titles.warning'|translate}}</strong>
    </span>
    <span *ngSwitchCase="feedbackTypeEnum.Info">
      <i class="mr-3 fas fa-info-circle"></i>
      <strong>{{'messages.banner-titles.info'|translate}}</strong>
    </span>
    <span *ngSwitchDefault>
    </span>
  </div>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text class="row">
    <span class="body">
      {{ toast.textOrTpl }}
    </span>
  </ng-template>
</ngb-toast>
