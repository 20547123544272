import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'dateTranslate',
  pure: false
})
export class DateTranslatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(value: Date | string , pattern: string = 'fullDate'): any {
    const datePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern);
  }

}
