import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ManageCountryList} from '../models/manage-country.model';
import {AppConfigService} from './app-config.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Pagination} from '../models';

const httpOptions = {
   headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: '*',
   }),
};

@Injectable({
   providedIn: 'root',
})
export class CountryManagerService {
   constructor(public http: HttpClient, public appConfigService: AppConfigService, public router: ActivatedRoute) {}

   appConfigUrl = this.appConfigService.appConfig.SERVER_URL;

   addCountryList(value: ManageCountryList): Observable<ManageCountryList> {
      return this.http.post<ManageCountryList>(`${this.appConfigUrl}/api/v1.0/adm/country`, value);
   }

   countryListManager(): Observable<ManageCountryList> {
     let page = '';
     let itemsPerPage = '';
     this.router.snapshot.queryParams.page ? page = this.router.snapshot.queryParams.page : page = '1';
     this.router.snapshot.queryParams.itemsPerPage ? itemsPerPage = this.router.snapshot.queryParams.itemsPerPage : itemsPerPage = '299';
     let params: HttpParams = new HttpParams();
     params = params.append('itemsPerPage', itemsPerPage);
     params = params.append('page', page);
     return this.http.get<ManageCountryList>(`${this.appConfigUrl}/api/v1.0/adm/country`, {params});
   }

   countryOneData(id: string): Observable<ManageCountryList> {
      return this.http.get<ManageCountryList>(`${this.appConfigUrl}/api/v1.0/adm/country/${id}`);
   }

   countryUpdateData(id: string, value: ManageCountryList): Observable<ManageCountryList> {
      return this.http.put<ManageCountryList>(`${this.appConfigUrl}/api/v1.0/adm/country/${id}`, value);
   }

   deleteCountry(id: string): Observable<ManageCountryList> {
      return this.http.delete<ManageCountryList>(`${this.appConfigUrl}/api/v1.0/adm/country/${id}`);
   }

   countryListCsv(file: string): Observable<any> {
     let headers = new HttpHeaders();
     headers = headers.append('Content-Type', 'text/csv');
     const body = {
       base64CSVContent: file
     };
     return this.http.post(`${this.appConfigUrl}/api/v1.0/adm/country/importcsv`, body);
   }
}
