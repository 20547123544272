import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarrierSettings, PagedResponse, Pagination } from '../models';
import { AppConfigService } from './app-config.service';
import { CarrierModel } from '../models/carrier.model';
import { PostOfficeModel } from '../models/post-office.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {
  api = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}`;
  paginatorGenerator = new PaginationGeneratorService();

  constructor(
    public http: HttpClient,
    public appConfigService: AppConfigService
  ) {
  }

  saveTariffs(tariffs: Array<CarrierSettings>): Observable<any> {
    return this.http.post<any>(`${this.api}/carrier/settings`, tariffs);
  }

  getCarrierProfile(): Observable<CarrierModel> {
    return this.http.get<any>(`${this.api}/car/carrier/profile`);
  }

  updateCarrierProfile(data: any): Observable<string> {
    return this.http.patch<any>(`${this.api}/car/carrier/profile`, data);
  }

  getAllPostOffices(query: Pagination): Observable<PagedResponse<PostOfficeModel>> {
    const params = this.paginatorGenerator.generatePagination(query);
    return this.http.get<any>(`${this.api}/car/carrier/profile/office`, { params });
  }

  getPostOfficeById(id: string): Observable<PostOfficeModel> {
    return this.http.get<any>(`${this.api}/car/carrier/profile/office/${id}`);
  }

  createOrUpdatePO(postOffice: PostOfficeModel): Observable<string> {
    if (!postOffice.id) {
      return this.createPostOffice(postOffice);
    }
    return this.updatePostOfficeById(postOffice.id, postOffice);
  }

  createPostOffice(data: PostOfficeModel): Observable<string> {
    return this.http.post<any>(`${this.api}/car/carrier/profile/office`, data);
  }

  updatePostOfficeById(id: string, data: PostOfficeModel): Observable<string> {
    return this.http.patch<any>(`${this.api}/car/carrier/profile/office/${id}`, data);
  }

  disableEnablePO(postOffice: PostOfficeModel): Observable<string> {
    if (!postOffice.isDeleted) {
      return this.disablePostOffice(postOffice.id);
    }
    return this.enablePostOffice(postOffice.id);
  }

  disablePostOffice(id: string): Observable<any> {
    return this.http.delete<any>(`${this.api}/car/carrier/profile/office/${id}`);
  }

  enablePostOffice(id: string): Observable<any> {
    return this.http.patch<any>(`${this.api}/car/carrier/profile/office/${id}/activate`, {});
  }

  getCarriers(query: Pagination): Observable<PagedResponse<CarrierModel>> {
    const params = this.paginatorGenerator.generatePagination(query);
    return this.http.get<any>(`${this.api}/pub/dictionaries/carriers`, { params });
  }

}
