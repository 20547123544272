import { Parcel } from ".";
import { ProductModel } from "./product.model";

export class OrderDetails {
  id!: string;
  price!: number;
  receiver!: any;
  submissionDate!: Date;
  estimatedReadyAt!: Date;
  parcel!: Parcel;
  trackingId!: number;
  pickupPointCode: string;
  products: Array<ProductModel>;
  orderSubmittedAt: Date;
  message: string;
  deliveryAcceptedUntil: Date;
}

