import { OrderDetails } from ".";
import { AddressModel } from "./address.model";
import { ContactPersonModel } from "./contact-person.model";
import { ProductModel } from "./product.model";

export class Payment {
  ticket: OrderDetails;
  serviceId: string;
  pickupPointId: string;
  carrierId: string;
  carrierPrice: number;
  estimatedDeliveryDays: number;
  cost: number;
  contactPerson: ContactPersonModel;
  address: AddressModel;
  estimatedDeliveryStart: Date;
  estimatedDeliveryEnd: Date;
  products: Array<string>;
}
