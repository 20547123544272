import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShippingService {
  addressFormStatus = new BehaviorSubject<any>({});
  handleAddressFormStatus = this.addressFormStatus.asObservable();

  contactFormStatus = new BehaviorSubject<any>({});
  handleContactFormStatus = this.addressFormStatus.asObservable();

  summaryHeight = new BehaviorSubject<any>({});
  handleSummaryHeight = this.summaryHeight.asObservable();

  addressValidatorStatus = new BehaviorSubject<any>({});
  handleAddressValidatorStatus = this.addressValidatorStatus.asObservable();

  internationalShipping = new BehaviorSubject<any>({});
  handleInternationalShipping = this.internationalShipping.asObservable();

  setAddressStatus(status: boolean): void {
    this.addressFormStatus.next(status);
  }

  setContactStatus(status: boolean): void {
    this.contactFormStatus.next(status);
  }

  setSummaryHeight(status: boolean): void {
    this.summaryHeight.next(status);
  }

  setAddressValidator(status: boolean): void {
    this.addressValidatorStatus.next(status);
  }

  setInternationalShipping(status: boolean): void {
    this.internationalShipping.next(status);
  }
}
